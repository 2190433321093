import React from 'react';

function HelpCenter() {
    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full md:max-w-6xl px-4 md:px-0'>
            </div>
        </div>
    );
};

export default HelpCenter;