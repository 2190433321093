import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IoSend } from 'react-icons/io5';
import { ClipLoader } from 'react-spinners';
import { BsReplyAll } from "react-icons/bs";

import { CredentialsContext } from '../../components/CredentialsContext';

// AWS...
import { API, graphqlOperation } from 'aws-amplify';
import { listRepliesByComment } from '../../graphql/queries';
import { createReply, deleteReply } from '../../graphql/mutations';
import { onCreateReply, onDeleteReply } from '../../graphql/subscriptions';
import Reply from './components/Reply';

export default function Replies() {

    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
    const location = useLocation();
    const post = location?.state?.post;
    const comment = location?.state?.comment;
    const [replies, setReplies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [replyingTo, setReplyingTo] = useState(comment?.user);

    useEffect(() => {
        fetchReplies();
    }, []);

    const fetchReplies = async () => {
        if (!loading) {
            try {
                setLoading(true);

                const response = await API.graphql(graphqlOperation(listRepliesByComment, { commentID: comment?.id }));

                setReplies(response?.data?.listRepliesByComment?.items);
            } catch (error) {
                
            } finally {
                setLoading(false);
            };
        }
    };

    const sendReply = async () => {
        try {
            
        } catch (error) {
            alert(error?.message);  
        };
    };

    return (
        <div className='h-full flex flex-col items-center overflow-hidden'>
            <div className='flex pb-3 md:pb-10 flex-col h-full w-full max-w-6xl px-2'>
                <div className='flex items-center pb-3 border-b-2'>
                    <h1 className='text-2xl text-gray-500 md:text-3xl font-bold font-signature'>Replies</h1>
                </div>
                <div className='flex-1 overflow-y-auto flex flex-col py-2'>
                    {
                        replies?.length ?
                        <>
                            {
                                replies.map((r) => (
                                    <Reply key={r?.id} post={post} comment={comment} reply={r} setReplyingTo={setReplyingTo} />
                                ))
                            }
                        </>
                        :
                        <>
                            {
                                loading ?
                                <div className='pt-8 flex flex-col items-center text-center'>
                                    <ClipLoader color='#4299eb' />
                                </div>
                                :
                                <div className='pt-8 flex flex-col items-center text-center'>
                                    <BsReplyAll size={100} className='text-gray-300' />
                                    <h1 className='mt-2.5 text-2xl font-signature'>Replies</h1>
                                    <p className='mt-2 text-gray-500 max-w-2xl'>It seems like this comment does not have any replies yet, go ahead and be the first to reply. 😊</p>
                                </div>
                            }
                        </>
                    }
                </div>
                {
                    storedCredentials?.verified === "YES" ?
                    <div className='pt-2 border-t-2'>
                        <p className='text-xs text-gray-500'>Replying to:<span className='text-bubble_blue font-semibold'>{" @" + replyingTo?.username}</span></p>
                        <div className='mt-2 flex items-center'>
                            <input
                                placeholder='Send reply...'
                                className='flex-grow flex-shrink border-2 py-1.5 max-h-60 px-4 rounded-full mr-2.5 focus:outline-none'
                                value={message}
                                onChange={(text) => setMessage(text.target.value)}
                            />
                            {
                                message ?
                                <div onClick={() => sendReply()} className='w-10 h-10 rounded-full bg-bubble_blue flex items-center justify-center text-white cursor-pointer'>
                                    <IoSend size={20} />
                                </div>
                                :
                                <div className='w-10 h-10 rounded-full bg-bubble_blue flex items-center justify-center text-white opacity-50'>
                                    <IoSend size={20} />
                                </div>
                            }
                        </div>
                    </div>
                    :
                    <></>
                }
            </div>
        </div>
    );
};
