import React from 'react';
import { BiTrash } from 'react-icons/bi'
import { Link } from 'react-router-dom';

function Product({product, remove}) {
    return (
        <Link to={'/Product'} state={{ data: product }} className='relative flex border-2 border-gray-300 rounded-xl'>
            <img src={product?.images[0]} alt='' className='ml-2.5 my-2.5 cursor-pointer w-32 h-32 md:w-40 md:h-40 bg-gray-300 rounded-md' />
            <div className='p-3'>
                <Link to={'/Product'} state={{ data: product }} className=''>
                    <h1 className='link text-lg font-bold line-clamp-1 text-ellipsis'>{product?.name}</h1>
                </Link>
                <div className='md:mt-2'>
                    <h2 className='text-lg text-bubble_blue font-bold'>{"R " + product?.price.toLocaleString()}</h2>
                </div>
                <div className='mt-2 flex items-center'>
                    <p className='sm:hidden text-gray-400 text-xs font-semibold px-1.5 py-0.5 border-2 border-gray-300 rounded-full'>{product?.condition}</p>
                    <p className='hidden sm:inline text-gray-400 text-xs font-semibold px-1.5 py-0.5 border-2 border-gray-300 rounded-full'>{"Condition: " + product?.condition}</p>
                    <div className='flex-grow'></div>
                </div>
                <Link to={'/Shop'} state={{ data: product?.shop }} className='mt-2.5 md:mt-4 flex items-center'>
                    <img src={product?.shop?.logo} className='w-6 rounded-full bg-gray-300 border-2 border-gray-300' />
                    <h4 className='link ml-1 text-xs font-semibold whitespace-nowrap overflow-hidden text-ellipsis'>{product?.shop?.name}</h4>
                </Link>
            </div>
            <Link className='absolute bottom-3 right-3 flex flex-row-reverse gap-5 text-gray-500'>
                <BiTrash onClick={() => remove(product?.id)} size={22} className='cursor-pointer hover:text-red-500' />
            </Link>
        </Link>
    );
};

export default Product;