import React, { useState, useEffect, useContext } from 'react';
import { PiChatCenteredTextBold } from 'react-icons/pi';

import allChatRooms from '../../dummyData/chatrooms.json';
import { CredentialsContext } from '../../components/CredentialsContext';

import ChatRoom from './components/ChatRoom';
import ScrollToTop from '../../components/ScrollToTop';

export default function Chats() {

    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
    const [chatRooms, setChatRooms] = useState([]);

    useEffect(() => {
        setChatRooms([...allChatRooms]);

        return () => (
            <ScrollToTop />
        );
    }, []);

    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full max-w-6xl px-2'>
                <h1 className='mb-4 md:mb-5 text-2xl text-gray-500 md:text-3xl font-bold font-signature'>Chats</h1>
                {
                    chatRooms.length ?
                    <div className='flex flex-col items-center'>
                        {
                            chatRooms.map((chatRoom) => (
                                <ChatRoom chatRoom={chatRoom} />
                            ))
                        }
                    </div>
                    :
                    <div className='pt:5 md:pt-8 flex flex-col items-center text-center'>
                        <PiChatCenteredTextBold size={100} className='text-gray-300' />
                        <h1 className='mt-2.5 text-2xl font-signature'>Chats</h1>
                        <p className='px-2 lg:px-0 mt-2 text-gray-500 max-w-2xl'>It seems like you don’t have any messages at the moment. All your messages will appear here, to send a direct message click on the "Direct Message" below. 😊</p>
                    </div>
                }
            </div>
        </div>
    );
};
