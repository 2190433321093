import React, { useEffect, useState, useContext } from 'react';
import { RiNotification4Line } from 'react-icons/ri';

import Notification from './components/Notification';
import { CredentialsContext } from '../../components/CredentialsContext';

// AWS...
import { API, graphqlOperation } from 'aws-amplify';
import { listNotifications } from '../../graphql/queries';

export default function Notifications() {

    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        fetchNotifications()
    }, []);
    
    const fetchNotifications = async () => {
        try {
            const response = await API.graphql(graphqlOperation(listNotifications, {
                /*recipientID: storedCredentials?.id,
                sortDirection: "DESC",
                limit: 100*/
                filter: {
                    recipientID: {
                        eq: storedCredentials?.id
                    }
                }
            }));

            setNotifications(response?.data?.listNotifications?.items);
        } catch (error) {
            console.log(error?.message);  
        };
    };

    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full max-w-6xl px-2'>
                <h1 className='mb-4 md:mb-5 text-2xl text-gray-500 md:text-3xl object-cover font-bold font-signature'>Notifications</h1>
                {
                    notifications?.length ?
                    <div className='flex flex-col items-center'>
                        {
                            notifications.map((notification) => (
                                <Notification key={notification?.id} notification={notification} />
                            ))
                        }
                    </div>
                    :
                    <div className='pt:5 md:pt-8 flex flex-col items-center text-center'>
                        <RiNotification4Line size={100} className='text-gray-300' />
                        <h1 className='mt-2.5 text-2xl font-signature'>Notifications</h1>
                        <p className='px-2 md:px-0 mt-2 text-gray-500 max-w-2xl'>It seems like you don’t have any notifications at the moment. All your notifications will appear here to update you on things tou might have missed. 😊</p>
                    </div>
                }
            </div>
        </div>
    );
};
