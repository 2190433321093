import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineAccountCircle } from 'react-icons/md';

import { CredentialsContext } from '../components/CredentialsContext';

function Account() {

    const navigate = useNavigate();

    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
    const [name, setName] = useState("Mutavhatsindi Ndivhuwo");
    const [username, setUsername] = useState("ndivhuwo");
    const [email, setEmail] = useState("ndivhuwo@bubblesocialnetworking.com");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    useEffect(() => {
        
    }, []);

    const changeEmail = () => {
        navigate('/ChangeEmail');
    };

    const changePassword = () => {
        navigate('/ResetPassword', {
            state: {
                username: username
            }
        });
    };

    const addressBook = () => {
        navigate('/AddressBook');
    };

    const signOut = async () => {
        setStoredCredentials(undefined);
        navigate('/');
    };

    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full md:max-w-6xl px-4'>
                <h1 className='mb-4 md:mb-5 text-2xl md:text-3xl font-bold font-signature'>Manage Account</h1>
                {
                    storedCredentials ?
                    <div className='pt-2 md:pt-8 flex flex-col items-center'>
                        <img loading='lazy' src={'https://www.ndivhuwo.com/static/media/IMG_0722.c08b6c578d1a43803076.jpeg'} alt='' className='bg-gray-300 h-40 w-40 md:h-56 md:w-56 rounded-full border-2' />
                        <div className='mt-6 w-full sm:w-3/4 md:w-2/4'>
                            <h1 className='text-gray-500 font-semibold'>YOUR PROFILE</h1>
                        </div>
                        <div className='mt-3 w-full sm:w-3/4 md:w-2/4'>
                            <h1 className='text-gray-400 text-sm font-semibold'>Full Name</h1>
                            <input placeholder='Full name' value={name} onChange={(text) => setName(text.target.value)} className='mt-0.5 w-full border-2 rounded-full px-4 py-1.5 focus:outline-none' />
                        </div>
                        <div className='mt-2 w-full sm:w-3/4 md:w-2/4'>
                            <h1 className='text-gray-400 text-sm font-semibold'>Username</h1>
                            <input placeholder='Username' value={username} onChange={(text) => setUsername(text.target.value)} className='mt-0.5 w-full border-2 rounded-full px-4 py-1.5 focus:outline-none' />
                        </div>
                        <div className='mt-2 w-full sm:w-3/4 md:w-2/4'>
                            <h1 className='text-gray-400 text-sm font-semibold'>Email Address</h1>
                            <input placeholder='Email address' value={email} onChange={(text) => setEmail(text.target.value)} className='mt-0.5 w-full border-2 rounded-full px-4 py-1.5 focus:outline-none' />
                        </div>
                        <div className='mt-2.5 w-full sm:w-3/4 md:w-2/4 flex flex-col md:flex-row gap-3'>
                            <div onClick={() => changeEmail()} className='w-full link text-sm bg-gray-200 py-2.5 text-center hover:cursor-pointer rounded-full'>
                                <h1 className='font-semibold'>Change Email</h1>
                            </div>
                            <div onClick={() => changePassword()} className='w-full link text-sm bg-white hover:bg-gray-100 border-2 py-2 text-center hover:cursor-pointer rounded-full'>
                                <h1 className='font-semibold'>Reset Password</h1>
                            </div>
                        </div>
                        <div className='mt-8 md:mt-10 w-full sm:w-3/4 md:w-2/4'>
                            <h1 className='text-gray-500 font-semibold'>SHIPPING ADDRESS</h1>
                            <div className='flex'>
                                <h1 onClick={() => addressBook()} className='mt-2 text-blue-400 text-sm font-semibold link hover:cursor-pointer'>Edit Address Book</h1>
                                <h2 className='flex flex-grow'></h2>
                            </div>
                        </div>
                        <div className='mt-8 md:mt-10 w-full sm:w-3/4 md:w-2/4'>
                            <h1 className='text-gray-500 font-semibold'>CHANGE ACCOUNT</h1>
                        </div>
                        <button onClick={() => signOut()} className='mt-3 link bg-blue-400 text-sm text-white font-semibold  w-full sm:w-3/4 md:w-2/4 mb-3 py-2.5 rounded-full'>
                            <h1>Sign Out</h1>
                        </button>
                    </div>
                    :
                    <div className='pt-2 md:pt-8 flex flex-col items-center text-center'>
                        <MdOutlineAccountCircle size={100} className='text-gray-300' />
                        <h1 className='mt-2.5 text-2xl font-signature'>Sign In</h1>
                        <p className='mt-2 text-gray-500 max-w-2xl'>Ooops we see that you are not signed in to your Bubble account, signing in is the best and only way to manage your account.</p>
                        <Link to={'/Signin'} className='mt-4 max-w-lg text-white link border-2 border-blue-400 bg-blue-400 w-full py-2.5 rounded-full'>
                            <h1 className='font-medium font-signature'>GO TO SIGN IN</h1>
                        </Link>
                    </div>
                }
            </div>
        </div>
    );
};

export default Account;