import React from 'react';

import sellers from '../../assets/sellers.png';
import shopowner from '../../assets/shopowner.jpg';
import howtosell from '../../assets/howtosell.jpg';
import processorder from '../../assets/processorder.jpg';
import customersatisfaction from '../../assets/customersatisfaction.jpg';

export default function Sellers() {

    return (
        <div className='flex flex-col items-center pb-6'>
            <div className='w-full flex flex-col items-center md:max-w-5xl px-4 md:px-0'>
                <img src={sellers} alt='' className='w-80 pointer-events-none' />
                <div className='px-3 md:px-6'>
                    <p className='text-xl md:text-4xl text-gray-500 text-center font-signature font-semibold'>
                        With Bubble, you can sell your clothes online in a snap. No more dm for prices, just choose your own and receive your money right away.
                    </p>
                    <p className='text-base text-center mt-8 text-gray-500'>
                        Are you looking for a way to sell your clothes online and make some extra cash? Do you want to reach a large and engaged audience of fashion lovers who are eager to buy your items? Do you want to enjoy a hassle-free and secure selling experience? If you answered yes to any of these questions, then you need to check out Bubble, the fashion platform that lets you sell your clothes online.
                    </p>
                    <p className='text-base text-center mt-5 text-gray-500'>
                        This is a platform that makes online selling easy and fun. You can create your own online shop in minutes, upload photos of your clothes, set your own prices, and get paid instantly. You can also track your sales, manage your orders, and communicate with your buyers. Bubble takes care of everything for you, from payment processing to shipping and customer service. You don’t have to worry about anything, just focus on selling your clothes and making money.
                    </p>                        
                    <p className='text-base text-center mt-5 text-gray-500'>
                        Bubble is more than just a social platform, it’s a community of South Africans and fashion lovers who support each other, share their passion, style, and stories. This is the place where you can express yourself, have fun, and grow your business.
                    </p>

                    <div className='mt-14 w-full min-h-full'>
                        <div className='w-full border-t-2 border-t-gray-200'></div>
                        <div className='mt-14 md:mt-16 flex flex-col md:flex-row items-center'>
                            <img src={shopowner} alt='' className='rounded-2xl border-2 w-80 aspect-9/16 bg-gray-200 object-cover shadow-md pointer-events-none' />
                            <div className='mt-5 md:mt-0 md:ml-28 text-center md:text-left'>
                                <h1 className='text-lg md:text-3xl text-bubble_blue font-signature font-semibold'>Become a seller and reach a wider audience.</h1>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>But before you can start selling, you need to have a verified account with us. Verification is a simple and quick process that ensures the security and trust of our platform. Every user on Bubble has to be verified, whether they are buyers, sellers or content creators. Verification helps us to prevent fraud, protect your personal information, and provide you with a safe and smooth selling experience.</p>
                                {/*<p className='mt-2 text-gray-500 text-sm md:text-base'>To verify your account, you need to provide us with some basic information, such as your name, email, ID number, and address. You also need to upload a photo of your South African ID card, or driver’s license, and a selfie of yourself holding it. We will review your information and confirm your identity within 24 hours. Once you are verified, you can open your shop and start selling your clothes online.</p>*/}
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>Next, you need to open your shop and set up your profile. You can choose a name, a logo,, and a description for your shop. You can also add your location, your shipping and return policies, and your preferred payment methods. Your profile is your online storefront, so make sure it reflects your style and personality.</p>
                            </div>
                        </div>
                        <div className='mt-14 md:mt-16 flex flex-col md:flex-row-reverse items-center'>
                            <img src={howtosell} alt='' className='rounded-2xl border-2 w-80 aspect-9/16 bg-gray-200 object-cover shadow-md pointer-events-none' />
                            <div className='mt-5 md:mt-0 md:mr-28 text-center md:text-left'>
                                <h1 className='text-lg md:text-3xl text-bubble_blue font-signature font-semibold'>You can list your items in minutes and reach a large and engaged audience of fashion lovers.</h1>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>Now you need to upload photos of your clothes. You can use your phone camera or any other device to take clear and bright photos of your items, you can upload up to 3 photos per item. Try to showcase your clothes from different angles and perspectives, and include any details, flaws, or measurements that might be important for the buyers</p>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>After that, you need to add a title, a description, a category, a size, a condition, and a price for each item. The title and the description are the most important parts of your listing, as they help the buyers to find and understand your items.</p>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>Use catchy and descriptive words that highlight the features and benefits of your clothes. The category, the size, the condition, and the price are also essential for the buyers to filter and compare your items. Be honest and realistic about the quality and the value of your clothes, and don’t forget to factor in the fees.</p>
                            </div>
                        </div>
                        <div className='mt-14 md:mt-16 flex flex-col md:flex-row items-center'>
                            <img src={processorder} alt='' className='rounded-2xl border-2 w-80 aspect-9/16 bg-gray-200 object-cover shadow-md pointer-events-none' />
                            <div className='mt-5 md:mt-0 md:ml-28 text-center md:text-left'>
                                <h1 className='text-lg md:text-3xl text-bubble_blue font-signature font-semibold'>Processing orders on Bubble is easy and convenient.</h1>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>You can use the integrated payment system and shipping company to deliver your orders and receive your money.</p>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>You can see the details of each order, such as the buyer’s name, address, phone number, email, and the items they ordered. You can also see the status of each order, such as pending, paid, shipped, delivered, or completed.</p>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>Next, you need to confirm your orders and prepare your items for shipping. You can pack your items in a secure and appropriate package. You can also print the shipping label that Bubble provides you with the order details. The shipping label contains the tracking number, the shipping company name, and the shipping fee.</p>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>Then, you need to request a pickup of your package from your home or office. You and your customer can track the progress of your package on our platform. You can also communicate with the buyer and answer any questions or concerns they might have.</p>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>After that, you need to wait for the buyer to receive your package and confirm the delivery on the Bubble app. Once the buyer confirms the delivery, the order is marked as completed and the money for the order is transferred to your Bubble account. You can access your money anytime and withdraw it to your bank account.</p>
                            </div>
                        </div>
                        <div className='mt-14 md:mt-16 flex flex-col md:flex-row-reverse items-center'>
                            <img src={customersatisfaction} alt='' className='rounded-2xl border-2 w-80 aspect-9/16 bg-gray-200 object-cover shadow-md pointer-events-none' />
                            <div className='mt-5 md:mt-0 md:mr-28 text-center md:text-left'>
                                <h1 className='text-lg md:text-3xl text-bubble_blue font-signature font-semibold'>Ratings and reviews help you to build trust, reputation, and loyalty among your buyers.</h1>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>Bubble allows buyers to share their opinions and experiences with the products they buy. Buyers can only review products they have purchased, so you can be sure that the feedback is honest and reliable. This way, you can learn from your customers and improve your products and services.</p>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>Ratings of a shop will be displayed on the shop page, so potential buyers can see how satisfied your previous customers were. This helps you to attract more buyers and increase your sales. Ratings also help you to build your reputation and credibility as a seller. You can also respond to ratings and reviews and thank your customers for their support.</p>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>Bubble is a platform that values customer feedback and seller reputation. By allowing buyers to rate and review products they have purchased, Bubble creates a transparent and trustworthy environment for online shopping.</p>
                            </div>
                        </div>
                    </div>
                    <div className='my-14 md:mb-16 w-full border-t-2 border-t-gray-200'></div>
                    <p className='text-center mt-6 md:mt-10 mb-16 text-xl md:text-4xl font-semibold text-gray-500 font-signature'>Join Bubble today and start your own online shop. Sell your clothes to a community of fashion lovers and earn money while having fun.</p>
                </div>
            </div>
        </div>
    );
};
