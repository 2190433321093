import React, { useEffect, useState, useContext } from 'react';
import { MdOutlineShoppingCartCheckout } from 'react-icons/md';
import { AiOutlineShoppingCart } from 'react-icons/ai';

import { CredentialsContext } from '../../components/CredentialsContext';
import Product from './components/Product';
import { Link } from 'react-router-dom';

function Cart() {

    const [shipping, setShipping] = useState(99);
    const [products, setProducts] = useState([]);
    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);

    useEffect(() => {
        if (storedCredentials) {
            setProducts(storedCredentials?.CartItems?.items);
        };
    }, [storedCredentials]);

    const totalItems = () => {
        let total = 0;

        products?.forEach(product => {
            total = total + product?.quantity;
        });

        return total;
    };

    const subtotal = () => {
        let total = 0;

        products?.forEach(product => {
            total = total + (product?.item?.price * product?.quantity);
        });

        return total;
    };

    const removeProduct = async (id) => {
        try {
            const productIndex = storedCredentials?.CartItems?.items.findIndex(product => product?.id === id);

            if (productIndex > -1) {
                storedCredentials?.CartItems?.items.splice(productIndex, 1);
                setStoredCredentials({...storedCredentials});
            };

        } catch (error) {
            alert(error.message); 
        };
    };

    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full max-w-6xl px-2'>
                <h1 className='mb-4 md:mb-5 text-2xl text-gray-500 md:text-3xl font-bold font-signature'>{"Cart" + (totalItems() ? ": (" + totalItems() + ")" : "")}</h1>
                {
                    products?.length ?
                    <div className='flex flex-col md:flex-row gap-4 md:gap-10'>
                        <div className='flex flex-col gap-7 md:w-3/5'>
                            {
                                products?.map(product => (
                                    <Product key={product?.id} product={product} remove={removeProduct} />
                                ))
                            }
                        </div>
                        <div className='md:w-2/5'>
                            <div className='px-4 py-3 flex flex-col gap-2 border-2 border-gray-200 bg-gray-100 rounded-xl'>
                                <h1 className='text-2xl md:text-3xl font-bold font-signature'>Order Information</h1>
                                <div className='mt-2 flex items-center'>
                                    <h1 className='flex-grow text-sm text-gray-500 font-semibold'>Total Items</h1>
                                    <h2 className='text-sm text-gray-500 font-semibold'>{totalItems()}</h2>
                                </div>
                                <div className='flex items-center'>
                                    <h1 className='flex-grow text-sm text-gray-500 font-semibold'>Sub Total</h1>
                                    <h2 className='text-sm text-gray-500 font-semibold'>{"R " + subtotal().toLocaleString()}</h2>
                                </div>
                                <div className='flex items-center'>
                                    <h1 className='flex-grow text-sm text-gray-500 font-semibold'>Shipping</h1>
                                    <h2 className='text-sm text-gray-500 font-semibold'>{"R " + shipping.toLocaleString()}</h2>
                                </div>
                                <div className='flex items-center'>
                                    <h1 className='flex-grow text-lg font-extrabold'>TOTAL</h1>
                                    <h2 className='text-lg font-extrabold'>{"R " + (subtotal() + shipping).toLocaleString()}</h2>
                                </div>
                            </div>

                            <button className='mt-8 text-white link bg-bubble_blue w-full py-3 rounded-full hover:opacity-90'>
                                <div className='flex flex-col items-center w-full'>
                                    <div className='flex flex-row items-center'>
                                        <MdOutlineShoppingCartCheckout />
                                        <h1 className='ml-3 font-medium font-signature'>PROCEED TO CHECKOUT</h1>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                    :
                    <div className='pt-8 flex flex-col items-center text-center'>
                        <AiOutlineShoppingCart size={100} className='text-gray-300' />
                        <h1 className='mt-2.5 text-2xl font-signature'>Cart</h1>
                        <p className='mt-2 text-gray-500 max-w-2xl'>Don’t leave your cart lonely. Fill it with some of our awesome items and enjoy a great shopping experience.</p>
                        <Link to={'/Shopping'} className='mt-4 max-w-lg text-white link border-2 border-bubble_blue bg-bubble_blue w-full py-2.5 rounded-full'>
                            <h1 className='font-medium font-signature'>START SHOPPING</h1>
                        </Link>
                    </div>
                }
            </div>
        </div>
    );
};

export default Cart;