import React, { useState, useContext } from 'react';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { BiTrash } from 'react-icons/bi'
import { Link } from 'react-router-dom';

import { CredentialsContext } from '../../../components/CredentialsContext';

function Product({product, remove}) {

    const [quantity, setQuantity] = useState(product?.quantity);
    const [onWishlist, setOnWishlist] = useState(false);
    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);

    const changeQuantity = async (type) => {
        if (type === "ADD") {
            setQuantity(quantity + 1);
        } else {
            if (quantity > 1) {
                setQuantity(quantity - 1);
            } else {
                alert("A Minimum QUANTITY of 1 (One) is Required");
            };
        };
    };

    const updateQuantity = () => {
        try {
            const productIndex = storedCredentials?.CartItems?.items?.findIndex((p => p?.id === product?.id));

            if (productIndex > -1) {
                storedCredentials.CartItems.items[productIndex].quantity = quantity;
                setStoredCredentials({...storedCredentials});
            };
        } catch (error) {
            
        };
    };

    const addToWishlist = async () => {
        try {
            if (!onWishlist) {
                setOnWishlist(true);
                storedCredentials.WishlistItems.items.unshift(product?.item);

                const productIndex = storedCredentials?.CartItems?.items?.findIndex((p => p?.id === product?.id));

                if (productIndex > -1) {
                    storedCredentials.CartItems.items.splice(productIndex, 1);
                    setStoredCredentials({...storedCredentials});
                };

                setStoredCredentials({...storedCredentials});
            } else {
                setOnWishlist(false);
                const productIndex = storedCredentials?.WishlistItems?.items?.findIndex((p => p?.id === product?.id));

                if (productIndex > -1) {
                    storedCredentials.WishlistItems.items.splice(productIndex, 1);
                    setStoredCredentials({...storedCredentials});
                };
            };
        } catch (error) {
            alert(error?.message);
        };
    };

    return (
            <Link to={'/Product'} state={{ data: product?.item }} className='relative flex border-2 border-gray-300 rounded-xl'>
                <img src={product?.item?.images[0]} alt='' className='ml-2.5 my-2.5 cursor-pointer w-32 h-32 md:w-40 md:h-40 bg-gray-300 rounded-md' />
                <div className='p-3 w-full'>
                    <Link to={'/Product'} state={{ data: product?.item }} className=''>
                        <h1 className='link text-lg font-bold line-clamp-1 text-ellipsis'>{product?.item?.name}</h1>
                    </Link>
                    
                    <div className='flex items-center  md:mt-2'>
                        <h2 className='text-lg text-bubble_blue font-bold'>{"R " + (product?.item?.price * quantity).toLocaleString()}</h2>
                        <h3 className='text-xs text-gray-400 font-semibold ml-3'>(Size: 7 UK)</h3>
                    </div>
                    <Link className='mt-1 md:mt-2 flex items-center'>
                        <div className='flex items-center gap-2'>
                            <AiOutlineMinusCircle onClick={() => changeQuantity("MINUS")} color='red' size={20} className='cursor-pointer' />
                            <h1 className='text-sm font-semibold'>{quantity}</h1>
                            <AiOutlinePlusCircle onClick={() => changeQuantity("ADD")} size={20} className='text-bubble_blue cursor-pointer' />
                        </div>
                        <div className='flex flex-grow'></div>
                        {
                            quantity !== product?.quantity ?
                            <div onClick={() => updateQuantity()} className='ml-5 py-1 px-2 rounded-full bg-bubble_blue'>
                                <h1 className='text-xs text-white font-'>Update</h1>
                            </div>
                            :
                            <div className='ml-5 py-1 px-2 rounded-full'>
                                <h1 className='text-xs text-white font-'>a</h1>
                            </div>
                        }
                        
                    </Link>
                    <Link to={'/Shop'} state={{ data: product?.item?.shop }} className='mt-1.5 md:mt-3 flex items-center'>
                        <img src={product?.item?.shop?.logo} className='w-6 rounded-full bg-gray-300 border-2 border-gray-300' />
                        <h4 className='link ml-1 text-xs font-semibold whitespace-nowrap overflow-hidden text-ellipsis'>{product?.item?.shop?.name}</h4>
                    </Link>
                </div>
                <Link className='absolute bottom-3 right-3 flex flex-row-reverse gap-5 text-gray-500'>
                    <BiTrash onClick={() => remove(product?.id)} size={22} className='cursor-pointer hover:text-red-500' />
                    {
                        onWishlist ?
                        <AiFillHeart onClick={() => addToWishlist()} color='red' size={22} className='cursor-pointer' />
                        :
                        <AiOutlineHeart onClick={() => addToWishlist()} size={22} className='cursor-pointer hover:text-black' />
                    }
                </Link>
            </Link>
    );
};

export default Product;