/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://bhx6ceweqjewxj2yqocyu7vxsa.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-hffl5pv2evah3fp7jaew5ej6jm",
    "aws_cognito_identity_pool_id": "us-west-2:30204a97-d114-425c-80aa-6fedc8a5f029",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_qQ85HTwpP",
    "aws_user_pools_web_client_id": "5qcrg1adb6ipoi2haf2rnl0cg7",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PREFERRED_USERNAME",
        "NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "bubble145229-staging",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
