import React, { useState } from 'react';

import aboutbanner from '../../assets/aboutbanner.jpg';
import story from '../../assets/story.jpg';
import vision from '../../assets/vision.jpg';
import mission from '../../assets/mission.jpg';
import values from '../../assets/values.jpg';
import joinus from '../../assets/join.jpg';
import ceo from '../../assets/ceo.jpeg';
import cfo from '../../assets/cfo.jpeg';

export default function Aboutus() {

    const [visions, setVisions] = useState([
        "To connect and empower South Africans through a safe, verified, and exclusive social network. (Bubble Social Networking)",
        "To create a vibrant and authentic online community for South Africans, where they can share their stories, support local businesses, and celebrate their culture. (Bubble Social Networking)",
        "To be the leading social media platform for South Africans, where they can discover, shop, and collaborate with local clothing brands and designers. (Bubble Social Networking)",
        ""
    ]);

    const [missions, setMissions] = useState([
        "Bubble Social Networking is a social media platform that connects South Africans with each other and with local clothing businesses. We verify all our users to ensure a secure and trustworthy environment. We also provide tools and features that help our users create, sell, and buy unique and quality products. (Bubble Social Networking)",
        "We are Bubble Social Networking, a social media platform for South Africans only. We offer a free verification service to all our users, so they can enjoy a safe and exclusive online space. We also enable our users to explore, support, and collaborate with local clothing businesses, and to express their style and identity. (Bubble Social Networking)",
        "Our mission is to build a social network that is made by South Africans, for South Africans. We verify every user who joins our platform, so they can have a genuine and respectful online experience. We also connect our users with local clothing businesses, and help them showcase and purchase their products. (Bubble Social Networking)",
        ""
    ]);

    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full md:max-w-5xl px-4 md:px-0'>
                <img src={aboutbanner} alt='' className='rounded-lg w-full border-2 shadow-sm pointer-events-none' />
                <div className='px-3 md:px-6 text-center'>
                    <p className='text-center mt-6 md:mt-10 text-xl md:text-4xl text-gray-500 font-semibold font-signature'>Calling all <span className='text-bubble_blue'>South Africans</span>.</p>
                    <p className='text-base text-center mt-8 text-gray-500'>We are Bubble Social Networking, a social media platform that connects South Africans with each other and with local clothing businesses. We verify all our users to ensure a secure and trustworthy environment. We also provide tools and features that help our users create, sell, and buy unique and quality products.</p>
                    <div className='mt-14 w-full min-h-full'>
                        <div className='w-full border-t-2 border-t-gray-200'></div>
                        <div className='mt-14 md:mt-16 flex flex-col md:flex-row items-center'>
                            <img src={story} alt='' className='rounded-2xl border-2 w-80 aspect-square bg-gray-200 object-cover shadow-md pointer-events-none' />
                            <div className='mt-5 md:mt-0 md:ml-28 text-center md:text-left'>
                                <h1 className='text-lg md:text-3xl text-bubble_blue font-signature font-semibold'>Our Story.</h1>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>We started Bubble Social Networking in 2021, with a vision to create a vibrant and authentic online community for South Africans, where they can share their stories, support local businesses, and celebrate their culture. We noticed that there was a lack of social media platforms that catered to the specific needs and interests of South Africans, especially in the fashion industry. We wanted to fill that gap and offer a platform that was made by South Africans, for South Africans.</p>
                            </div>
                        </div>
                        <div className='mt-14 md:mt-16 flex flex-col md:flex-row-reverse items-center'>
                            <img src={vision} alt='' className='rounded-2xl border-2 w-80 aspect-square bg-gray-200 object-cover shadow-md pointer-events-none' />
                            <div className='mt-5 md:mt-0 md:mr-28 text-center md:text-left'>
                                <h1 className='text-lg md:text-3xl text-bubble_blue font-signature font-semibold'>Our Vision.</h1>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>We envision a vibrant and authentic online community for South Africans, where they can share their stories, support local businesses, and celebrate their culture. We want to create a platform that reflects the diversity and richness of South Africa, and that connects people from different backgrounds, regions, and interests. We want to enable our users to express their style and identity, and to discover and shop from local clothing brands and designers that offer unique and quality products.</p>
                            </div>
                        </div>
                        <div className='mt-14 md:mt-16 flex flex-col md:flex-row items-center'>
                            <img src={mission} alt='' className='rounded-2xl border-2 w-80 aspect-square bg-gray-200 object-cover shadow-md pointer-events-none' />
                            <div className='mt-5 md:mt-0 md:ml-28 text-center md:text-left'>
                                <h1 className='text-lg md:text-3xl text-bubble_blue font-signature font-semibold'>Our Mission.</h1>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>Our mission is to build a social network that is safe, exclusive, and empowering for South Africans. We verify every user who joins our platform, so they can have a genuine and respectful online experience. We also connect our users with local clothing businesses, and help them showcase and purchase their products. We believe that by supporting local entrepreneurs and designers, we can foster a more sustainable and diverse fashion industry in South Africa.</p>
                            </div>
                        </div>
                        <div className='mt-14 md:mt-16 flex flex-col md:flex-row-reverse items-center'>
                            <img src={values} alt='' className='rounded-2xl border-2 w-80 aspect-square bg-gray-200 object-cover shadow-md pointer-events-none' />
                            <div className='mt-5 md:mt-0 md:mr-28 text-center md:text-left'>
                                <h1 className='text-lg md:text-3xl text-bubble_blue font-signature font-semibold'>Our Values.</h1>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>We value authenticity, diversity, and creativity. We encourage our users to be themselves, express their style and identity, and respect others. We celebrate the diversity of South Africa, and welcome people from all backgrounds, cultures, and preferences. We also foster a culture of creativity, and inspire our users to explore, discover, and collaborate with local clothing brands and designers.</p>
                            </div>
                        </div>
                        <div className='mt-14 md:my-16 flex flex-col md:flex-row items-center'>
                            <img src={joinus} alt='' className='rounded-2xl border-2 w-80 aspect-square bg-gray-200 object-cover shadow-md pointer-events-none' />
                            <div className='mt-5 md:mt-0 md:ml-28 text-center md:text-left'>
                                <h1 className='text-lg md:text-3xl text-bubble_blue font-signature font-semibold'>Join Us.</h1>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>If you are a South African who loves social media and/or fashion, we invite you to join our platform and become part of our community. You can sign up for free, get verified, and start creating, selling, and buying products from local businesses. We look forward to connecting with you and sharing our passion for South Africa and fashion.</p>
                            </div>
                        </div>
                    
                        {/* Members card */}

                        <div className='my-14'>
                            <div className='mb-14 md:mb-16 w-full border-t-2 border-t-gray-200'></div>
                            <h1 className='text-xl md:text-3xl font-semibold text-center md:text-left'>Meet our Team Members.</h1>
                            <div className='mt-10 flex flex-col md:flex-row items-center md:items-start'>
                                <img src={ceo} alt='' className='bg-gray-300 h-40 w-40 md:h-56 md:w-56 rounded-full border-2 pointer-events-none' />
                                <div className='mt-4 md:mt-0 md:ml-16 text-center md:text-left'>
                                    <h1 className='text-xl font-semibold'>Mutavhatsindi Ndivhuwo</h1>
                                    <h2 className='text-base text-bubble_blue font-semibold font-signature mt-1'>(Founder, CEO and Developer)</h2>
                                    <p className='mt-1.5 text-sm text-gray-500'>Ndivhuwo is a Computer Scientist and Informatician who graduated from the University of Johannesburg in 2023 with a BSc in Information Technology in Computer Science and Informatics.</p>
                                    <h3 className='mt-3 text-sm font-semibold'>Contact Information</h3>
                                    <div className='md:flex md:items-center'>
                                        <p onClick={() => window.open("https://www.ndivhuwo.com")} className='cursor-pointer text-sm text-gray-500 hover:text-bubble_blue hover:underline'>www.ndivhuwo.com</p>
                                        <div className='hidden md:inline'></div>
                                    </div>
                                    <div className='md:flex md:items-center'>
                                        <a href='mailto:ndivhuwo@bubblesocialnetworking.com' className='cursor-pointer text-sm text-gray-500 hover:text-bubble_blue hover:underline'>ndivhuwo@bubblesocialnetworking.com</a>
                                        <div className='hidden md:inline'></div>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-10 md:mt-14 flex flex-col md:flex-row items-center md:items-start'>
                                <img src={cfo} alt='' className='bg-gray-300 h-40 w-40 md:h-56 md:w-56 rounded-full border-2 pointer-events-none' />
                                <div className='mt-4 md:mt-0 md:ml-16 text-center md:text-left'>
                                    <h1 className='text-xl font-semibold'>Mukwevho Honest</h1>
                                    <h2 className='text-base text-bubble_blue font-semibold font-signature mt-1'>(CFO and Advisor)</h2>
                                    <p className='mt-1.5 text-sm text-gray-500'>Honest is a Financial Manager and Entrepreneur who completed a National Diploma in Financial Management from the Central Johannesburg College in 2021.</p>
                                    <h3 className='mt-3 text-sm font-semibold'>Contact Information</h3>
                                    <div className='md:flex md:items-center'>
                                        <a href='mailto:honest@bubblesocialnetworking.com' className='cursor-pointer text-sm text-gray-500 hover:text-bubble_blue hover:underline'>honest@bubblesocialnetworking.com</a>
                                        <div className='hidden md:inline'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
