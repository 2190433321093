import React from 'react';

import privacy from '../../assets/privacy.png'

export default function AppPrivacyPolicy() {
    return (
        <div className='flex flex-col items-center pb-6 md:pb-16'>
            <div className='w-full md:max-w-6xl px-4 md:px-0'>
                <div className='w-full flex items-center justify-center'>
                    <img src={privacy} alt='' className='w-full max-w-sm aspect-square pointer-events-none' />
                </div>
                <h1 className='mt-10 text-3xl font-semibold font-signature'>Privacy Policy</h1>
                <p className='mt-1.5 text-gray-500 font-semibold font-signature'>Last updated November 13, 2023</p>
                <p className='mt-7 text-sm'>This privacy notice for Bubble Social Network (Pty) Ltd (Trading as Bubble) ('<span className='font-semibold'>we</span>', '<span className='font-semibold'>us</span>', or '<span className='font-semibold'>our</span>'), describes how and why we might collect, store, use, and/or share ('<span className='font-semibold'>process</span>') your information when you use our services ('<span className='font-semibold'>Services</span>'), such as when you:</p>
                <ol className='list-disc list-inside marker:text-black mt-1'>
                    <li className='ml-5 text-sm text-gray-600'>Visit our website at: <span className='text-bubble_blue'>https://www.bubblesocialnetworking.com</span>, or any website of ours that links to this privacy notice.</li>
                    <li className='ml-5 text-sm text-gray-600'>Download and use our mobile application (Bubble Social Networking), or any other application of ours that 	    links to this privacy notice.</li>
                    <li className='ml-5 text-sm text-gray-600'>Engage with us in other related ways, including any sales, marketing or events.</li>
                </ol>
                <p className='mt-2 text-sm'><span className='font-semibold'>Questions or concerns?</span> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at info@bubblesocialnetworking.com.</p>
            
                {/* SECTION ONE */}
                <h2 className='mt-7 text-lg font-semibold'>SUMMARY OF KEY POINTS</h2>
                <p className='mt-2 text-sm font-semibold'>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by scrolling down to find the section you are looking for.</p>
                <p className='mt-2 text-sm'><span className='font-semibold'>What personal information do we process?</span> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use.</p>
                <p className='mt-2 text-sm'><span className='font-semibold'>Do we process any sensitive personal information?</span> We may process sensitive personal information necessarily, with your consent or as otherwise permitted by applicable law.</p>
                <p className='mt-2 text-sm'><span className='font-semibold'>Do we receive any information from third parties?</span> We do not receive any information from third parties.</p>
                <p className='mt-2 text-sm'><span className='font-semibold'>How do we process your information?</span> We process your information to provide, improve and administer our Services, communicate with you, for security and fraud prevention and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.</p>
                <p className='mt-2 text-sm'><span className='font-semibold'>In what situations and with which parties do we share personal information?</span> We may share information in specific situations and with specific third parties.</p>
                <p className='mt-2 text-sm'><span className='font-semibold'>How do we keep your information safe?</span> We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal or modify your information.</p>
                <p className='mt-2 text-sm'><span className='font-semibold'>What are your rights?</span> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.</p>
                <p className='mt-2 text-sm'><span className='font-semibold'>How do you exercise your rights?</span> The easiest way to exercise your rights is by submitting a data subject access request or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</p>
                <p className='mt-2 text-sm'>Want to learn more about what we do with any information we collect? Review the privacy notice in full.</p>
            
                {/* SECTION TWO */}
                <h2 className='mt-7 text-lg font-semibold'>TABLE OF CONTENTS</h2>
                <ol className="list-decimal list-inside marker:text-gray-600 mt-2">
                    <li className='ml-5 text-sm text-gray-600 mt-1'>WHAT INFORMATION DO WE COLLECT?</li>
                    <li className='ml-5 text-sm text-gray-600 mt-1'>HOW DO WE PROCESS YOUR INFORMATION?</li>
                    <li className='ml-5 text-sm text-gray-600 mt-1'>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
                    <li className='ml-5 text-sm text-gray-600 mt-1'>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
                    <li className='ml-5 text-sm text-gray-600 mt-1'>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
                    <li className='ml-5 text-sm text-gray-600 mt-1'>WHAT ARE YOUR PRIVACY RIGHTS?</li>
                    <li className='ml-5 text-sm text-gray-600 mt-1'>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
                    <li className='ml-5 text-sm text-gray-600 mt-1'>DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</li>
                    <li className='ml-5 text-sm text-gray-600 mt-1'>DO WE MAKE UPDATES TO THIS NOTICE?</li>
                    <li className='ml-5 text-sm text-gray-600 mt-1'>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
                    <li className='ml-5 text-sm text-gray-600 mt-1'>HOW CAN YOU REVIEW, UPDATE,  OR DELETE THE DATA WE COLLECT FROM YOU?</li>
                </ol>

                {/* SECTION THREE */}
                <h2 className='mt-7 text-lg font-semibold'>1. WHAT INFORMATION DO WE COLLECT?</h2>
                <h3 className='mt-2 text-base font-semibold'>Personal information you disclose to us.</h3>
                <p className='mt-2 text-sm'><span className='font-semibold'>In Short:</span> We collect personal information that you provide to us.</p>
                <p className='mt-2 text-sm'>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
                <p className='mt-2 text-sm'><span className='font-semibold'>Personal Information Provided by You.</span> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make and the products and features you use. The personal information we collect may include the following:</p>
                <ol className='list-disc list-inside marker:text-black mt-1'>
                    <li className='ml-5 text-sm text-gray-600'>Names</li>
                    <li className='ml-5 text-sm text-gray-600'>Email addresses</li>
                    <li className='ml-5 text-sm text-gray-600'>Usernames</li>
                    <li className='ml-5 text-sm text-gray-600'>Passwords</li>
                    <li className='ml-5 text-sm text-gray-600'>Phone numbers</li>
                    <li className='ml-5 text-sm text-gray-600'>Debit/Credit card information</li>
                    <li className='ml-5 text-sm text-gray-600'>Home address</li>
                </ol>
                <p className='mt-2 text-sm'><span className='font-semibold'>Sensitive Information.</span> When necessary, who you consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:</p>
                <ol className='list-disc list-inside marker:text-black mt-1'>
                    <li className='ml-5 text-sm text-gray-600'>Identity information</li>
                </ol>
                <p className='mt-2 text-sm'><span className='font-semibold'>Application Data.</span> If you use our application(s), we also may  collect the following information if you choose to provide us with access or permission:</p>
                <ol className='list-disc list-inside marker:text-black mt-1'>
                    <li className='ml-5 text-sm text-gray-600'><span className='text-black font-semibold'>Mobile Device Access.</span> We may request access or permission to certain features from your mobile device, including your mobile device's camera. microphone, storage, and other features. If you wish to change our access or permissions, you may do so in your device's settings.</li>
                    <li className='ml-5 text-sm text-gray-600'><span className='text-black font-semibold'>Push Notifications.</span> We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device’s settings.</li>
                </ol>
                <p className='mt-2 text-sm'>This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</p>
                <p className='mt-2 text-sm'>All personal information that you provide to us must be true, complete,  and accurate and  you must notify us of any changes to such personal information.</p>
            
                {/* SECTION FOUR */}
                <h2 className='mt-7 text-lg font-semibold'>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
                <p className='mt-2 text-sm'><span className='text-black font-semibold'>In Short:</span> We process your information to provide, improve and administer our services, communicate with you  for security and fraud prevention, and to comply with law.  We may also process your information for other purposes with your consent.</p>
                <p className='mt-2 text-sm'><span className='text-black font-semibold'>We process your personal information for a variety of reasons, depending on how you interact with our Services,</span> including:</p>
                <ol className='list-disc list-inside marker:text-black mt-1'>
                    <li className='ml-5 text-sm text-gray-600'><span className='text-black font-semibold'>To facilitate account creation and authentication and otherwise manage user accounts.</span> We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
                    <li className='ml-5 text-sm text-gray-600'><span className='text-black font-semibold'>To deliver and facilitate delivery of services to the user.</span> We may process your information to provide you with the requested service.</li>
                    <li className='ml-5 text-sm text-gray-600'><span className='text-black font-semibold'>To protect our Services.</span> We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</li>
                    <li className='ml-5 text-sm text-gray-600'><span className='text-black font-semibold'>To comply with our legal obligations.</span> We may process your information to comply with our legal obligations, respond to legal requests and exercise establish or defend our legal rights.</li>
                </ol>

                {/* SECTION FIVE */}
                <h2 className='mt-7 text-lg font-semibold'>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
                <p className='mt-2 text-sm'><span className='text-black font-semibold'>In Short:</span> We may share information in specific situations described in this section and/or with the following third parties.</p>
                <p className='mt-2 text-sm'>We may need to share your personal information in the following situations:</p>
                <ol className='list-disc list-inside marker:text-black mt-1'>
                    <li className='ml-5 text-sm text-gray-600'><span className='text-black font-semibold'>Business Transfers.</span> We may share or transfer your information in connection with or during negotiations of any merger, sale of company assets, financing or acquisition of all or a portion of our business to another company.</li>
                </ol>

                {/* SECTION SIX */}
                <h2 className='mt-7 text-lg font-semibold'>4. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                <p className='mt-2 text-sm'><span className='text-black font-semibold'>In Short:</span> We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.</p>
                <p className='mt-2 text-sm'>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting  or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period in which users have an account with us.</p>
                <p className='mt-2 text-sm'>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible for  example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
            
                {/* SECTION SEVEN */}
                <h2 className='mt-7 text-lg font-semibold'>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
                <p className='mt-2 text-sm'><span className='text-black font-semibold'>In Short:</span> We aim to protect your personal information through a system of organisational and technical security measures.</p>
                <p className='mt-2 text-sm'>We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals  or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal  or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>
            
                {/* SECTION EIGHT */}
                <h2 className='mt-7 text-lg font-semibold'>6. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
                <p className='mt-2 text-sm'><span className='text-black font-semibold'>In Short:</span> You may review, change  or terminate your account at any time.</p>
                <p className='mt-2 text-sm'><span className='text-black font-semibold'>Withdrawing your consent:</span> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE? below.</p>
                <p className='mt-2 text-sm'>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
                <h3 className='mt-2 text-base font-semibold'>Account Information</h3>
                <p className='mt-2 text-sm'>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
                <ol className='list-disc list-inside marker:text-black mt-1'>
                    <li className='ml-5 text-sm text-gray-600'>Log in to your account settings and update your user account.</li>
                </ol>
                <p className='mt-2 text-sm'>Upon your request to terminate your account we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>
                <p className='mt-2 text-sm'>If you have any questions or concerns about your privacy rights, you may email us at: <a className='text-bubble_blue link' href='mailto:info@bubblesocialnetworking.com'>info@bubblesocialnetworking.com</a>.</p>
            
                {/* SECTION NINE */}
                <h2 className='mt-7 text-lg font-semibold'>7. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                <p className='mt-2 text-sm'>Most web browsers,  some mobile operating systems and mobile applications include a Do-Not-Track (DNT) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
            
                {/* SECTION TEN */}
                <h2 className='mt-7 text-lg font-semibold'>8. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
                <p className='mt-2 text-sm'><span className='text-black font-semibold'>In Short:</span> You may have additional rights based on the country you reside in.</p>
                <h3 className='mt-2 text-base font-semibold'>Republic of South Africa</h3>
                <p className='mt-2 text-sm'>At any time, you have the right to request access to or correction of your persona information. You can make such a request by contacting us by using the contact details provided in the section 'HOW CAN YOU REVIEW, UPDATE  OR DELETE THE DATA WE COLLECT FROM YOU?'</p>
                <p className='mt-2 text-sm'>If you are unsatisfied with the way we address any complaint regarding our processing of personal information, you can contact the office of the regulator, the details of which are:</p>
                <ol className='list-disc list-inside marker:text-black mt-1'>
                    <li className='ml-5 text-sm text-gray-600'>The Information Regulator (South Africa): <span className='cursor-pointer text-bubble_blue link' onClick={() => window.open("https://inforegulator.org.za")}>https://inforegulator.org.za</span></li>
                    <li className='ml-5 text-sm text-gray-600'>General enquiries: <a className='text-bubble_blue link' href='mailto:enquiries@inforegulator.org.za'>enquiries@inforegulator.org.za</a></li>
                    <li className='ml-5 text-sm text-gray-600'>Complaints (complete POPIA/PAIA form 5): <a className='text-bubble_blue link' href='mailto:PAIAComplaints@inforegulator.org.za'>PAlAComplaints@inforegulator.org.za</a> & <a className='text-bubble_blue link' href='mailto:POPIAComplaints@inforegulator.org.za'>POPIAComplaints@inforegulator.org.za</a></li>
                </ol>

                {/* SECTION ELEVEN */}
                <h2 className='mt-7 text-lg font-semibold'>9. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
                <p className='mt-2 text-sm'><span className='text-black font-semibold'>In Short:</span> Yes, we will update this notice as necessary to stay compliant with relevant laws and regulations.</p>
                <p className='mt-2 text-sm'>We may update this privacy notice from time to time. The updated version will be indicated  by an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>

                {/* SECTION TWELVE */}
                <h2 className='mt-7 text-lg font-semibold'>10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
                <p className='mt-2 text-sm'>If you have questions or comments about this notice, you may email us at: <a className='text-bubble_blue link' href='mailto:info@bubblesocialnetworking.com'>info@bubblesocialnetworking.com</a> or contact us by post at:</p>
                <p className='mt-2 text-sm text-gray-600'>Bubble Social Networking (Pty) Ltd</p>
                <p className='mt-2 text-sm text-gray-600'>3123 Nephalama Street</p>
                <p className='mt-2 text-sm text-gray-600'>Tshiawelo Ext 2</p>
                <p className='mt-2 text-sm text-gray-600'>Johannesburg, Gauteng 1818</p>
                <p className='mt-2 text-sm text-gray-600'>South Africa</p>
            
                {/* SECTION THIRTEEN */}
                <h2 className='mt-7 text-lg font-semibold'>11. HOW CAN YOU REVIEW, UPDATE  OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
                <p className='mt-2 text-sm'>Based on the applicable laws of your South Africa you may have the right to request access to the personal information we collect from you, change that information or delete it. To request to review update or delete your personal information, please fill out and submit a data subject access request.</p>
            </div>
        </div>
    );
};
