import React, { useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';

import { CredentialsContext } from './CredentialsContext';

export default function UserActive() {

    const navigate = useNavigate();
    const location = useLocation();
    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);

    useEffect(() => {
        if (!storedCredentials
            && location.pathname !== "/PrivacyPolicy"
            && location.pathname !== "/TermsConditions"
            && location.pathname !== "/AppTermsConditions"
            && location.pathname !== "/AppPrivacyPolicy"
            && location.pathname !== "/Signin"
            && location.pathname !== "/Signup"
            && location.pathname !== "/ResetPassword"
            && location.pathname !== "/EmailVerification"
            && location.pathname !== "/Sellers"
            && location.pathname !== "/About"
            && location.pathname !== "/") {
            navigate('/');
        };
    }, [storedCredentials, location]);

    return null
};
