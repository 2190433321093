import React from 'react';

export default function EditProfile() {
    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full max-w-6xl px-2'>
                <h1 className='mb-4 md:mb-5 text-2xl text-gray-500 md:text-3xl font-bold font-signature'>Edit Profile</h1>
            </div>
        </div>
    );
};
