import React, { useState, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import logo from '../../assets/logo.png';
import resetpassword from '../../assets/forgotpassword.png';
import { CredentialsContext } from '../../components/CredentialsContext';
import CustomInput from '../../components/CustomInput';
import CustomInputDescription from '../../CustomInputDescription';

function ResetPassword() {

    const navigate = useNavigate();
    const location = useLocation();
    const username = location.state.username;

    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const resetPassword = async () => {
        //navigate('/Signin');
    };

    const checkRequirements = () => {
        if (otp?.length && password?.length && password === confirmPassword) {
            return true;
        } else {
            return false;
        };
    };

    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full md:max-w-2xl px-2 md:px-0'>
                <div className='flex flex-col items-center'>
                    <div className='flex items-center'>
                        <img src={resetpassword} alt='' className='w-full max-w-xs aspect-square object-cover pointer-events-none' />
                    </div>
                    <div className='my-3'>
                        <h1 className='text-lg text-gray-400 font-signature font-extrabold'>Please check your email for your OPT.</h1>
                    </div>
                    <CustomInput icon={'otp'} type={'text'} placeholder={"OTP (One Time Pin)"} value={otp} setValue={setOtp} />
                    <CustomInput icon={'password'} type={'password'} placeholder={"New Password"} value={password} setValue={setPassword} />
                    <CustomInput icon={'password'} type={'password'} placeholder={"Confirm New Password"} value={confirmPassword} setValue={setConfirmPassword} />
                    {
                        password.length && confirmPassword.length && password !== confirmPassword ?
                        <div className='w-11/12 sm:w-5/6 md:w-3/4 text-center -mt-3 mb-3'>
                            <p className='text-sm text-red-500 font-normal'>Passwords do not match!</p>
                        </div>
                        :
                        <></>
                    }
                    {
                        checkRequirements() ?
                        <button onClick={() => resetPassword()} className='link bg-bubble_blue hover:opacity-90 text-sm text-white font-semibold w-11/12 sm:w-5/6 md:w-3/4 mb-3 py-2 rounded-full'>
                            <h1>Reset Password</h1>
                        </button>
                        :
                        <></>
                    }
                    <button className='link bg-gray-200 hover:opacity-90 text-sm font-semibold w-11/12 sm:w-5/6 md:w-3/4 mb-3 py-2 rounded-full text-center'>
                        <h1 className=''>Resend OTP</h1>
                    </button>
                    <Link to={'/Signin'} className='link bg-white border-2 hover:opacity-90 border-gray-100 text-sm text-gray-500 font-semibold w-11/12 sm:w-5/6 md:w-3/4 mb-3 py-2 rounded-full text-center'>
                        <h1 className=''>Cancel</h1>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
