import React, { useState, useContext, useEffect } from 'react';
import { MdVerified } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { FaCircleNotch } from 'react-icons/fa';

import { CredentialsContext } from '../../components/CredentialsContext';
import Post from '../Feed/components/Post';

// AWS...
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { listPosts } from '../../graphql/queries';

export default function MyProfile() {

    const naigate = useNavigate();
    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
    const [followers, setFollowers] = useState([]);
    const [following, setFollowing] = useState([]);
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchPosts();
    }, []);


    const fetchPosts = async () => {
        try {
            setLoading(true);
            const response = await API.graphql(graphqlOperation(listPosts, {
                filter: {
                    userID: {
                        eq: storedCredentials?.id
                    }
                }
            }));
    
            if (response) {
                setPosts([...response?.data?.listPosts?.items]);
            };
        } catch (error) {
            alert(error?.message);
        } finally {
            setLoading(false);
        };
    };

    const signOut = async () => {
        try {
            setStoredCredentials(undefined);
            await Auth.signOut();
        } catch (error) {
            console.warn(error?.message);  
        };
    };

    const editProfile = () => {
        naigate('/EditProfile');
    };

    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full md:max-w-6xl px-2'>
                <h1 className='mb-4 md:mb-5 text-2xl text-center text-gray-500 md:text-3xl font-bold font-signature'>{storedCredentials?.name}</h1>
                <div className='flex flex-col items-center'>
                    <img src={storedCredentials?.profile} alt='' className='object-cover bg-gray-200 h-40 w-40 md:h-56 md:w-56 rounded-full border-2 pointer-events-none' />
                    <div className='mt-2 md:mt-3 flex items-center'>
                        <h1 className='text-base font-bold'>{storedCredentials?.username}</h1>
                        {
                            storedCredentials?.verified === "YES" ?
                            <MdVerified size={12} className='ml-0.5 text-bubble_blue' />
                            :
                            <></>
                        }
                    </div>
                    {
                        storedCredentials?.biography?
                        <p className='mt-2 text-center text-gray-900 text-sm md:text-base'>{storedCredentials?.biography}</p>
                        :
                        <></>
                    }
                    <div className='mt-1 md:mt-2 flex flex-row items-center'>
                        <h1 className='text-xs md:text-sm font-semibold'>{followers?.length}<span className='text-gray-500 font-normal'>{" Followers"}</span></h1>
                        <h1 className='text-xs md:text-sm font-semibold mx-3.5'>{following?.length}<span className='text-gray-500 font-normal'>{" Following"}</span></h1>
                        <h1 className='text-xs md:text-sm font-semibold'>{posts?.length}<span className='text-gray-500 font-normal'>{" Posts"}</span></h1>
                    </div>
                    <div className='mt-2 md:mt-3 px-1.5 py-0.5 border-2 border-gray-300 rounded-full'>
                        <h1 className='text-xs text-gray-400 font-semibold'>{'Joined us ' + moment(storedCredentials?.createdAt).fromNow()}</h1>
                    </div>
                    <div className='mt-4 w-full flex flex-col items-center justify-center gap-3'>
                        <button onClick={() => editProfile()} className='link h-10 w-full rounded-full max-w-md bg-gray-200 border-2 border-gray-200 hover:border-black text-sm text-black font-semibold hover:opacity-90'>
                            Edit Profile
                        </button>   
                        <button onClick={() => signOut()} className='link h-10 w-full rounded-full max-w-md border-2 hover:border-red-400 bg-gray-50 text-sm text-gray-500 hover:text-red-500 font-semibold hover:opacity-90'>
                            Sign Out
                        </button>   
                    </div>
                    {
                        posts?.length ?
                        <div className='mt-4 md:mt-6 max-w-md flex flex-col items-center'>
                            {
                                posts.map(post => (
                                    <Post key={post?.id} post={post} />
                                ))
                            }
                        </div>
                        :
                        <>
                            {
                                loading ?
                                <div className='pt-8 flex flex-col items-center text-center'>
                                    <ClipLoader color='#4299eb' />
                                </div>
                                :
                                <div className='pt-8 flex flex-col items-center text-center'>
                                    <FaCircleNotch size={100} className='text-gray-300' />
                                    <h1 className='mt-2.5 text-2xl font-signature'>{storedCredentials?.name}</h1>
                                    <p className='mt-2 text-gray-500 max-w-2xl'>It seems like you have not posted anything on your profile yet. Don’t worry, you can start sharing your thoughts, ideas, and creations with the world anytime you want. 😊</p>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
};
