import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function Photo({photos}) {
    return (
        <Carousel
            autoPlay={false}
            showStatus={photos?.length > 1 ? true : false}
            showIndicators={photos?.length > 1 ? true : false}
            showThumbs={false}
            interval={5000}
            className='rounded-xl border-2 border-gray-200'
        >
            {
                photos.map((photo) => (
                    <img id={photo} src={photo} className='w-full aspect-3/4 bg-gray-200 object-cover rounded-xl pointer-events-none' />
                ))
            }
        </Carousel>
    )
}
