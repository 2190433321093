import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Banner() {

    const [banners, setBanners] = useState([
        'https://www.chekich.co.za/images/kampanya/MIRROR-resimJS-18.jpg',
        'https://www.chekich.co.za/images/kampanya/flatlne-resimJS-15.jpg',
        'https://www.chekich.co.za/images/kampanya/TSHRT-resimJS-16.jpg'
    ])

    return (
        <div className='relative w-full aspect-5/2 bg-gray-200 flex flex-col items-center mt-3 sm:mt-2'>
            <Carousel
                autoPlay
                infiniteLoop
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                interval={5000}
                className='max-w-6xl h-full aspect-5/2'
            >
                {
                    banners.map((banner) => (
                        <img key={banner} loading='lazy' src={banner} alt='' className='bg-gray-300 aspect-5/2' />
                    ))
                }
            </Carousel>
        </div>
    );
};

export default Banner;
