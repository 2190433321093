import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function User({user}) {

    const [isFollowing, setIsFollowing] = useState(false);

    const follow = async () => {
        try {
            if (isFollowing) {

            } else {

            };

            setIsFollowing(!isFollowing);
        } catch (error) {
            console.warn(error?.message);  
        };
    };

    return (
        <Link to={'/UserProfile'} state={{ data: user }} className='p-3 border-2 rounded-xl max-w-xs flex flex-col items-center text-center hover:bg-gray-100'>
            <img src={user?.profile} className=' w-10/12 aspect-square rounded-full object-cover bg-gray-200 border-2' />
            <h1 className='mt-1 font-semibold line-clamp-1'>{user?.name}</h1>
        {
            isFollowing ?
            <Link onClick={() => follow()} className='flex items-center justify-center mt-1.5 bg-gray-300 w-full h-8 rounded-full text-black text-sm font-semibold link hover:opacity-90'>
                Unfollow
            </Link>
            :
            <Link onClick={() => follow()} className='flex items-center justify-center mt-1.5 bg-bubble_blue w-full h-8 rounded-full text-white text-sm font-semibold link hover:opacity-90'>
                Follow
            </Link>
        }
        </Link>
    );
};
