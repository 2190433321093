import React, { useState, useContext, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';

import { CredentialsContext } from '../../components/CredentialsContext';
import Post from './components/Post';
import Discover from './components/Discover';

// AWS...
import { API, graphqlOperation } from 'aws-amplify';
import { listPosts } from '../../graphql/queries';

export default function Feed() {

    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchPosts();
    }, []);


    const fetchPosts = async () => {
        try {
            setLoading(true);

            const response = await API.graphql(graphqlOperation(listPosts, {
                filter: {
                    userID: {
                        ne: storedCredentials?.id
                    }
                }
            }));
    
            if (response) {
                setPosts([...response?.data?.listPosts?.items]);
            };
        } catch (error) {
            alert(error?.message);
        } finally {
            setLoading(false);
        };
    };

    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full max-w-6xl px-2'>
                <h1 className='mb-4 md:mb-5 text-2xl text-gray-500 md:text-3xl font-bold font-signature'>Home</h1>
                <div className='flex w-full justify-center'>
                    <div className='hidden w-72 max-w-xs lg:inline mr-4'>
                        <div className='w-full p-2.5 border-2 rounded-xl bg-gray-50'>
                            <h1 className='mt-1.5 text-sm text-gray-500 font-semibold'>DISCOVER PEOPLE</h1>
                            <Discover />
                            <Link to={'/Discover'} className='link mt-3 border-2 bg-white border-bubble_blue w-full rounded-full h-8 flex items-center justify-center text-bubble_blue text-xs font-semibold hover:opacity-80'>
                                View More
                            </Link>
                        </div>
                    </div>
                    <div className='w-full max-w-md'>
                        {
                            loading ?
                            <div className='w-full flex flex-col items-center'>
                                <ClipLoader color='#4299eb' />
                            </div>
                            :
                            <>
                                {
                                    posts.map((post) => (
                                        <Post post={post} />
                                    ))
                                }
                            </>
                        }
                    </div>
                    <div className='hidden w-72 max-w-xs lg:inline ml-4'>
                        <div className='w-full flex flex-col items-center text-center p-2.5 border-2 rounded-xl bg-gray-50'>
                            <img src={storedCredentials?.profile} className='bg-gray-200 w-28 h-28 object-cover rounded-full border-2 pointer-events-none' />
                            <h1 className='mt-1.5 text-base font-semibold'>{storedCredentials?.name}</h1>
                            {
                                storedCredentials?.biography ?
                                <p className='text-xs text-gray-500 line-clamp-2'>{storedCredentials?.biography}</p>
                                :
                                <></>
                            }
                            <Link to={'/MyProfile'} className='link mt-2 bg-bubble_blue w-full rounded-full h-8 flex items-center justify-center text-white text-xs font-semibold hover:opacity-90'>
                                View Profile
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};
