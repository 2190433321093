import React, { useState, useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BsCartPlus } from 'react-icons/bs';
import { AiOutlineHeart, AiOutlineShop } from 'react-icons/ai';
import { TbRulerMeasure } from 'react-icons/tb';

import Color from './components/Color';
import Size from './components/Size';
import { CredentialsContext } from '../../components/CredentialsContext';

function Product() {

    const location = useLocation();
    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
    const [data, setData] = useState(location?.state?.data);
    const [colors, setColors] = useState([
        {
            id: 0,
            color: "#bda775"
        },
        {
            id: 1,
            color: "#4a3122"
        },
        {
            id: 2,
            color: "#313233"
        },
        {
            id: 3,
            color: "#326496"
        },
        {
            id: 4,
            color: "#e0c02f"
        }
    ]);
    const [sizes, setSizes] = useState([
        {
            id: 0,
            quantity: 0,
            size: "6 UK"
        },
        {
            id: 1,
            quantity: 0,
            size: "6.5 UK"
        },
        {
            id: 2,
            quantity: 15,
            size: "7 UK"
        },
        {
            id: 3,
            quantity: 2,
            size: "8 UK"
        },
        {
            id: 4,
            quantity: 22,
            size: "9 UK"
        },
        {
            id: 5,
            quantity: 0,
            size: "10 UK"
        },
        {
            id: 6,
            quantity: 3,
            size: "11 UK"
        }
    ]);
    const [selectedSize, setSelectedSize] = useState(undefined);
    const [selectedColor, setSelectedColor] = useState(colors[0]);

    const addToCart = async () => {
        if (storedCredentials) {
            try {
                storedCredentials.CartItems.items.unshift({
                    id: Math.random().toString(),
                    quantity: 1,
                    color: selectedColor,
                    size: selectedSize,
                    item: data
                });
    
                setStoredCredentials({...storedCredentials});
                setSelectedSize(undefined);
            } catch (error) {
                alert(error?.message);
            };
        } else {
            alert('You need to be signed in to add items to your cart. Please [sign in] or [create an account] to continue shopping.');
        };
    };

    const addToWishlist = async () => {
        if (storedCredentials) {
            try {
                storedCredentials.WishlistItems.items.unshift(data);
                setStoredCredentials({...storedCredentials});  
            } catch (error) {
                alert(error?.message);
            };
        } else {
            alert('You need to be signed in to add items to your wishlist. Please [sign in] or [create an account] to continue shopping.');
        };
    };

    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='max-w-6xl'>
                <div className='md:grid gap-4 grid-flow-row-dense grid-cols-2'>
                    <Carousel>
                        {
                            data?.images.map(image => (
                                <div key={image}>
                                    <img src={image} alt='' className='w-full aspect-square object-cover' />
                                </div>
                            ))
                        }
                    </Carousel>
                    <div className='px-5 md:px-0 pt-2 md:pt-0 md:mx-9'>
                        <h1 className='text-xl sm:text-3xl font-extrabold font-signature'>{data?.name}</h1>
                        <h2 className='mt-5 text-md sm:text-lg font-semibold font-signature text-bubble_blue'>{"R " + data?.price.toLocaleString()}</h2>
                        
                        <h3 className='text-xs font-extrabold mt-5 font-signature text-gray-500'>SELECT COLOR</h3>
                        <div className='gap-2 mt-2 flex flex-wrap'>
                            {
                                colors.map(color => (
                                    <Color key={color.id} color={color} selectedColor={selectedColor} setSelectedColor={setSelectedColor} />
                                ))
                            }
                        </div>
                        
                        <h3 className='text-xs font-extrabold mt-3 font-signature text-gray-500'>SELECT SIZE</h3>
                        <div className='mt-2 flex flex-wrap gap-3'>
                            {
                                sizes.map(size => (
                                    <Size size={size} selectedSize={selectedSize} setSelectedSize={setSelectedSize} />
                                ))
                            }
                        </div>
                        {
                            selectedSize ?
                            <button onClick={() => addToCart()} className='mt-8 link text-white border-2 bg-bubble_blue border-bubble_blue w-full py-2.5 rounded-full hover:opacity-90'>
                                <div className='flex flex-col items-center w-full'>
                                    <div className='flex flex-row items-center'>
                                        <BsCartPlus />
                                        <h1 className='ml-3 font-medium font-signature'>ADD TO CART</h1>
                                    </div>
                                </div>
                            </button>
                            :
                            <div className='mt-8 border-2 border-gray-200 bg-gray-100 w-full py-2.5 rounded-full'>
                                <div className='flex flex-col items-center w-full'>
                                    <div className='text-black flex flex-row items-center'>
                                        <TbRulerMeasure />
                                        <h1 className='ml-3 font-medium font-signature'>SELECT SIZE</h1>
                                    </div>
                                </div>
                            </div>
                        }
                        <button onClick={() => addToWishlist(data)} className='mt-3 link bg-gray-300 w-full py-3 rounded-full hover:bg-black text-black hover:text-white'>
                            <div className='flex flex-col items-center w-full'>
                                <div className='flex flex-row items-center'>
                                    <AiOutlineHeart />
                                    <h1 className='ml-3 font-medium font-signature'>ADD TO WISHLIST</h1>
                                </div>
                            </div>
                        </button>

                        <h4 className='mt-8 text-lg text-gray-600 font-signature font-extrabold'>PRODUCT DETAILS</h4>
                        <div className='mt-2 flex flex-wrap gap-3'>
                            <div className='text-sm py-1.5 px-3 border-2 border-gray-300 rounded-full'>{data?.condition}</div>
                            <div className='text-sm py-1.5 px-3 border-2 border-gray-300 rounded-full'>{"Nike"}</div>
                            <div className='text-sm py-1.5 px-3 border-2 border-gray-300 rounded-full'>{"Footwear"}</div>
                        </div>
                        <div className='mt-2 flex flex-wrap gap-3'>
                        <div className='text-sm py-1.5 px-3 border-2 border-gray-300 rounded-full'>{"Male"}</div>
                            <div className='text-sm py-1.5 px-3 border-2 border-gray-300 rounded-full'>{"Adults"}</div>
                        </div>

                        <h4 className='mt-8 text-md sm:text-lg text-gray-600 font-signature font-extrabold'>PRODUCT DESCRIPTION</h4>
                        <p className='mt-2 text-sm sm:text-base text-black'>{data?.description}</p>
                        
                        <div className='flex flex-col items-center border-t-2 border-t-gray-300 mt-6 pt-6'>
                            <img src={data?.shop?.logo} alt='' className=' w-24 sm:w-32 aspect-square rounded-full bg-gray-300 border-2 border-gray-300' />
                            <h1 className='mt-1 sm:mt-2 text-base sm:text-lg font-bold'>{data?.shop?.name}</h1>
                            {
                                data?.shop?.about ?
                                <p className='mt-1 sm:mt-2 text-center text-sm sm:text-base text-gray-600'>{data?.shop?.about}</p>
                                :
                                <></>
                            }
                            <Link to={'/Shop'} state={{ data: data?.shop }} className='mt-2 link bg-gray-300 w-full py-3 rounded-full hover:bg-black text-black hover:text-white'>
                                <div className='flex flex-col items-center w-full'>
                                    <div className='flex flex-row items-center'>
                                        <AiOutlineShop />
                                        <h1 className='ml-3 font-medium font-signature'>EXPLORE SHOP</h1>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Product;