import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter, unstable_HistoryRouter, Routes, Route } from "react-router-dom";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home";
import Account from "./pages/Account";
import Orders from "./pages/Orders";
import Wishlist from "./pages/Wishlist/Wishlist";
import Cart from "./pages/Cart/Cart";
import Product from "./pages/Shopping/Product";
import ScrollToTop from "./components/ScrollToTop";
import Menu from "./components/Menu";
import HelpCenter from "./pages/HelpCenter";
import TermsConditions from "./pages/Terms&Conditions/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "./pages/ContactUs";
import ShippingDelivery from "./pages/ShippingDelivery";
import Exchanges from "./pages/Exchanges";
import BecomeAseller from "./pages/BecomeAseller";
import POPIAPolicy from "./pages/POPIAPolicy";
import Shop from "./pages/Shopping/Shop";

import { CredentialsContext } from "./components/CredentialsContext";
import Signin from "./pages/Authentication/Signin";
import Signup from "./pages/Authentication/Signup";
import ResetPassword from "./pages/Authentication/ResetPassword";
import EmailVerification from "./pages/Authentication/EmailVerification";
import AddressBook from "./pages/AddressBook";
import Feed from "./pages/Feed/Feed";
import Comments from "./pages/Comment/Comments";
import Replies from "./pages/Reply/Replies";
import Notifications from "./pages/Notifications/Notifications";
import Chats from "./pages/Chats/Chats";
import Shopping from "./pages/Shopping/Shopping";
import MyProfile from "./pages/Profile/MyProfile";
import UserProfile from "./pages/Profile/UserProfile";
import Discover from "./pages/Discover/Discover";
import ChatRoom from "./pages/ChatRoom/ChatRoom";
import UserActive from "./components/UserActive";
import Landing from "./pages/Landing/Landing";
import Sellers from "./pages/Shopping/Sellers";
import EditProfile from "./pages/EditProfile/EditProfile";
import Aboutus from "./pages/AboutUs/Aboutus";
import AppPrivacyPolicy from "./pages/PrivacyPolicy/AppPrivacyPolicy";
import AppTermsConditions from "./pages/Terms&Conditions/AppTermsConditions";

// AWS...
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import { getUser } from './graphql/queries';
import awsExports from './aws-exports';
import Admin from "./pages/Admin/Admin";
import VerificationRequests from "./pages/Verification/VerificationRequests";
import UserVerification from "./pages/Verification/UserVerification";

Amplify.configure(awsExports);

function App() {

    const myDiv = useRef(null);
    const [menu, setMenu] = useState(false);
    const [page, setPage] = useState("Cart");
    const [storedCredentials, setStoredCredentials] = useState(undefined);

    /*const checkUser = async () => {
        try {
            const authUser = await Auth.currentAuthenticatedUser({bypassCache: true});

            const userData = await API.graphql(graphqlOperation(getUser, {id: authUser?.attributes?.sub}));

            if (userData) {
                setStoredCredentials(userData?.data?.getUser);
            };
        } catch (error) {
            console.warn(error.message);  
        };
    };

    useEffect(() => {
        checkUser();
    }, []);*/

    return (
        <CredentialsContext.Provider value={{storedCredentials, setStoredCredentials}} >
            <CredentialsContext.Consumer>
                {() => (
                    <BrowserRouter>
                        <div className="h-screen flex flex-col">
                            <UserActive />
                            <ScrollToTop element={myDiv.current} />
                            <Header />
                            <div ref={myDiv} className="flex-1">
                                <Routes>
                                    {
                                        storedCredentials ?
                                        <>
                                            <Route path="/" element={<Feed />} />
                                            <Route path="/Comments" element={<Comments />} />
                                            <Route path="/Replies" element={<Replies />} />
                                            <Route path="/Shopping" element={<Shopping />} />
                                            <Route path="/Notifications" element={<Notifications />} />
                                            <Route path="/Chats" element={<Chats />} />
                                            <Route path="/MyProfile" element={<MyProfile />} />
                                            <Route path="/UserProfile" element={<UserProfile />} />
                                            <Route path="/Discover" element={<Discover />} />
                                            <Route path="/ChatRoom" element={<ChatRoom />} />
                                            <Route path="/Account" element={<Account />}  />
                                            <Route path="/Orders" element={<Orders />}  />
                                            <Route path="/Wishlist" element={<Wishlist />}  />
                                            <Route path="/Cart" element={<Cart />}  />
                                            <Route path="/Product" element={<Product />}  />
                                            <Route path="/Shop" element={<Shop />} />
                                            <Route path="/AddressBook" element={<AddressBook />} />
                                            <Route path="/ContactUs" element={<ContactUs />} />
                                            <Route path="/EditProfile" element={<EditProfile />} />
                                            <Route path="/Admin" element={<Admin />} />
                                            <Route path="/VerificationRequests" element={<VerificationRequests />} />
                                            <Route path="/UserVerification" element={<UserVerification />} />
                                        </>
                                        :
                                        <>
                                            <Route path="/" element={<Landing />} />
                                            <Route path="/Signin" element={<Signin />} />
                                            <Route path="/Signup" element={<Signup />} />
                                            <Route path="/ResetPassword" element={<ResetPassword />} />
                                            <Route path="/EmailVerification" element={<EmailVerification />} />
                                        </>
                                    }
                                    <Route path="/Sellers" element={<Sellers />} />
                                    <Route path="/About" element={<Aboutus />}/>
                                    <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                                    <Route path="/TermsConditions" element={<TermsConditions />} />
                                    <Route path="/AppPrivacyPolicy" element={<AppPrivacyPolicy />} />
                                    <Route path="/AppTermsConditions" element={<AppTermsConditions />} />
                                </Routes>
                                <Footer />
                            </div>
                        </div>
                    </BrowserRouter>
                )}
            </CredentialsContext.Consumer>
        </CredentialsContext.Provider>
    );
};

export default App;
