import React, { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom';

import { CredentialsContext } from '../../components/CredentialsContext';

export default function UserVerification() {

    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
    const location = useLocation();
    const request = location?.state?.data;

    const [name, setName] = useState(request?.user?.name);
    const [idNumber, setIdNumber] = useState(request?.idNumber);
    const [message, setMessage] = useState('');

    const approve = async () => {
        try {
            // Approve User Veridication...
        } catch (error) {
            alert(error?.message);  
        };
    };

    const decline = async () => {
        try {
            // Decline User Veridication...
        } catch (error) {
            alert(error?.message);  
        };
    };

  return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full max-w-6xl px-2'>
                <h1 className='mb-4 md:mb-5 text-2xl text-gray-500 md:text-3xl font-bold font-signature'>User Verification</h1>
                <div className='flex flex-col items-center'>
                    <img src={request?.proofOfID} alt='' className='w-full max-w-xl border-2 rounded-xl' />
                    <div className='w-full max-w-xl mt-5'>
                        <h1 className='text-gray-500'>Fullname</h1>
                        <input
                            placeholder='Fullname'
                            value={name}
                            onChange={(text) => setName(text.target.value)}
                            className='w-full border-2 rounded-full h-11 px-4 focus:outline-none mt-1'
                        />
                        <h2 className='text-gray-500 mt-2.5'>Username</h2>
                        <input
                            placeholder='Username'
                            value={request?.user?.username}
                            readOnly
                            className='w-full border-2 rounded-full h-11 px-4 focus:outline-none mt-1'
                        />
                        <h3 className='text-gray-500 mt-2.5'>ID Number</h3>
                        <input
                            placeholder='ID Number'
                            value={idNumber}
                            onChange={(text) => setIdNumber(text.target.value)}
                            className='w-full border-2 rounded-full h-11 px-4 focus:outline-none mt-1'
                        />

                        <div onClick={() => approve()} className='w-full rounded-full h-11 px-4 mt-5 bg-bubble_blue flex items-center justify-center text-white text-lg font-signature link hover:opacity-90 cursor-pointer'>
                            <h1>Approve</h1>
                        </div>
                        <input
                            placeholder='Reason For Declining Verification'
                            value={message}
                            onChange={(text) => setMessage(text.target.value)}
                            className='w-full border-2 rounded-full h-11 px-4 focus:outline-none mt-4'
                        />
                        {
                            message ?
                            <div onClick={() => decline()} className='w-full rounded-full h-11 px-4 mt-3 bg-gray-200 flex items-center justify-center text-lg font-signature link hover:opacity-90 cursor-pointer'>
                                <h1>Decline</h1>
                            </div>
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
