import React from 'react';
import { useNavigate } from 'react-router-dom';

import landing from '../../assets/landing.jpg';
import feed from '../../assets/feed.png';
import message from '../../assets/message.png';
import status from '../../assets/status.png';
import verification from '../../assets/verification.png';
import item from '../../assets/item.png';
import connected from '../../assets/connected.png'
import story from '../../assets/story.jpg';
import vision from '../../assets/vision.jpg';
import mission from '../../assets/mission.jpg';
import values from '../../assets/values.jpg';
import joinus from '../../assets/join.jpg';
import ceo from '../../assets/ceo.jpeg';
import cfo from '../../assets/cfo.jpeg';
import preview1 from '../../assets/preview1.png';
import preview2 from '../../assets/preview2.png';
import preview3 from '../../assets/preview3.png';
import preview4 from '../../assets/preview4.png';
import preview5 from '../../assets/preview5.png';

export default function Landing() {

    const navigate = useNavigate();
    const previews = [preview1, preview2, preview3, preview4, preview5];

    return (
        <div className='flex flex-col items-center pb-6'>
            <div className='w-full md:max-w-5xl px-4 md:px-0'>
                <div className='pt-8 md:pt-14 flex flex-col justify-center items-center text-center'>
                    <img src={connected} alt='' className='w-80 aspect-square object-cover pointer-events-none' />
                    <div className='my-8 md:my-12 text-center'>
                        <h1 className='text-2xl md:text-5xl text-bubble_blue font-extrabold font-signature'>Connect with fellow South Africans.</h1>
                        <p className='md:mb-4 text-base mt-8 text-gray-500'>
                            We are Bubble Social Networking, a social media platform that connects South Africans with each other and with local clothing businesses. We verify all our users to ensure a secure and trustworthy environment. We also provide tools and features that help our users create, sell, and buy unique and quality products.
                        </p>
                    </div>
                    <div className='w-full border-t-2 border-t-gray-200 mb-10'></div>
                    <div className='mb-14 md:mb-16 flex flex-col items-center'>
                        <h1 className='text-lg md:text-3xl text-gray-500 font-signature font-semibold mb-4 md:mb-8'>App Screenshots</h1>
                        <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4'>
                            {
                                previews.map((preview) => (
                                    <img key={preview} src={preview} className='rounded-xl' style={{ borderWidth: 1.5, borderColor: "#ededed" }} />
                                ))
                            }
                        </div>
                    </div>
                    <div className='w-full border-t-2 border-t-gray-200'></div>
                    <div className='mt-14 md:mt-16 flex flex-col md:flex-row items-center'>
                        <img src={story} alt='' className='rounded-2xl border-2 w-80 aspect-square bg-gray-200 object-cover shadow-md pointer-events-none' />
                        <div className='mt-5 md:mt-0 md:ml-28 text-center md:text-left'>
                            <h1 className='text-lg md:text-3xl text-bubble_blue font-signature font-semibold'>Our Story.</h1>
                            <p className='mt-2 text-gray-500 text-sm md:text-base'>We started Bubble Social Networking in 2021, with a vision to create a vibrant and authentic online community for South Africans, where they can share their stories, support local businesses, and celebrate their culture. We noticed that there was a lack of social media platforms that catered to the specific needs and interests of South Africans, especially in the fashion industry. We wanted to fill that gap and offer a platform that was made by South Africans, for South Africans.</p>
                        </div>
                    </div>
                    <div className='mt-14 md:mt-16 flex flex-col md:flex-row-reverse items-center'>
                        <img src={vision} alt='' className='rounded-2xl border-2 w-80 aspect-square bg-gray-200 object-cover shadow-md pointer-events-none' />
                        <div className='mt-5 md:mt-0 md:mr-28 text-center md:text-left'>
                            <h1 className='text-lg md:text-3xl text-bubble_blue font-signature font-semibold'>Our Vision.</h1>
                            <p className='mt-2 text-gray-500 text-sm md:text-base'>We envision a vibrant and authentic online community for South Africans, where they can share their stories, support local businesses, and celebrate their culture. We want to create a platform that reflects the diversity and richness of South Africa, and that connects people from different backgrounds, regions, and interests. We want to enable our users to express their style and identity, and to discover and shop from local clothing brands and designers that offer unique and quality products.</p>
                        </div>
                    </div>
                    <div className='mt-14 md:mt-16 flex flex-col md:flex-row items-center'>
                            <img src={mission} alt='' className='rounded-2xl border-2 w-80 aspect-square bg-gray-200 object-cover shadow-md pointer-events-none' />
                            <div className='mt-5 md:mt-0 md:ml-28 text-center md:text-left'>
                                <h1 className='text-lg md:text-3xl text-bubble_blue font-signature font-semibold'>Our Mission.</h1>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>Our mission is to build a social network that is safe, exclusive, and empowering for South Africans. We verify every user who joins our platform, so they can have a genuine and respectful online experience. We also connect our users with local clothing businesses, and help them showcase and purchase their products. We believe that by supporting local entrepreneurs and designers, we can foster a more sustainable and diverse fashion industry in South Africa.</p>
                            </div>
                        </div>
                        <div className='mt-14 md:mt-16 flex flex-col md:flex-row-reverse items-center'>
                            <img src={values} alt='' className='rounded-2xl border-2 w-80 aspect-square bg-gray-200 object-cover shadow-md pointer-events-none' />
                            <div className='mt-5 md:mt-0 md:mr-28 text-center md:text-left'>
                                <h1 className='text-lg md:text-3xl text-bubble_blue font-signature font-semibold'>Our Values.</h1>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>We value authenticity, diversity, and creativity. We encourage our users to be themselves, express their style and identity, and respect others. We celebrate the diversity of South Africa, and welcome people from all backgrounds, cultures, and preferences. We also foster a culture of creativity, and inspire our users to explore, discover, and collaborate with local clothing brands and designers.</p>
                            </div>
                        </div>
                        <div className='mt-14 md:my-16 flex flex-col md:flex-row items-center'>
                            <img src={joinus} alt='' className='rounded-2xl border-2 w-80 aspect-square bg-gray-200 object-cover shadow-md pointer-events-none' />
                            <div className='mt-5 md:mt-0 md:ml-28 text-center md:text-left'>
                                <h1 className='text-lg md:text-3xl text-bubble_blue font-signature font-semibold'>Join Us.</h1>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>If you are a South African who loves social media and/or fashion, we invite you to join our platform and become part of our community. You can sign up for free, get verified, and start creating, selling, and buying products from local businesses. We look forward to connecting with you and sharing our passion for South Africa and fashion.</p>
                            </div>
                        </div>

                        {/* Members card */}

                        <div className='my-14'>
                            <div className='mb-14 md:mb-16 w-full border-t-2 border-t-gray-200'></div>
                            <h1 className='text-xl md:text-3xl font-semibold text-center md:text-left'>Meet our Team Members.</h1>
                            <div className='mt-10 flex flex-col md:flex-row items-center md:items-start'>
                                <img src={ceo} alt='' className='bg-gray-300 h-40 w-40 md:h-56 md:w-56 rounded-full border-2 pointer-events-none' />
                                <div className='mt-4 md:mt-0 md:ml-16 text-center md:text-left'>
                                    <h1 className='text-xl font-semibold'>Mutavhatsindi Ndivhuwo</h1>
                                    <h2 className='text-base text-bubble_blue font-semibold font-signature mt-1'>(Founder, CEO and Developer)</h2>
                                    <p className='mt-1.5 text-sm text-gray-500'>Ndivhuwo is a Computer Scientist and Informatician who graduated from the University of Johannesburg in 2023 with a BSc in Information Technology in Computer Science and Informatics.</p>
                                    <h3 className='mt-3 text-sm font-semibold'>Contact Information</h3>
                                    <div className='md:flex md:items-center'>
                                        <p onClick={() => window.open("https://www.ndivhuwo.com")} className='cursor-pointer text-sm text-gray-500 hover:text-bubble_blue hover:underline'>www.ndivhuwo.com</p>
                                        <div className='hidden md:inline'></div>
                                    </div>
                                    <div className='md:flex md:items-center'>
                                        <a href='mailto:ndivhuwo@bubblesocialnetworking.com' className='cursor-pointer text-sm text-gray-500 hover:text-bubble_blue hover:underline'>ndivhuwo@bubblesocialnetworking.com</a>
                                        <div className='hidden md:inline'></div>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-10 md:mt-14 flex flex-col md:flex-row items-center md:items-start'>
                                <img src={cfo} alt='' className='bg-gray-300 h-40 w-40 md:h-56 md:w-56 rounded-full border-2 pointer-events-none' />
                                <div className='mt-4 md:mt-0 md:ml-16 text-center md:text-left'>
                                    <h1 className='text-xl font-semibold'>Mukwevho Honest</h1>
                                    <h2 className='text-base text-bubble_blue font-semibold font-signature mt-1'>(CFO and Advisor)</h2>
                                    <p className='mt-1.5 text-sm text-gray-500'>Honest is a Financial Manager and Entrepreneur who completed a National Diploma in Financial Management from the Central Johannesburg College in 2021.</p>
                                    <h3 className='mt-3 text-sm font-semibold'>Contact Information</h3>
                                    <div className='md:flex md:items-center'>
                                        <a href='mailto:honest@bubblesocialnetworking.com' className='cursor-pointer text-sm text-gray-500 hover:text-bubble_blue hover:underline'>honest@bubblesocialnetworking.com</a>
                                        <div className='hidden md:inline'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                </div>
                {/*<div className='px-2'>
                    <div className='w-full border-t-2 border-t-gray-200'></div>
                    <div className='mt-14 w-full min-h-full'>
                        <div className='mt-14 md:mt-16 flex flex-col md:flex-row items-center'>
                            <img src={feed} alt='' className='rounded-2xl border-2 w-60 pointer-events-none' />
                            <div className='mt-5 md:mt-0 md:ml-28 text-center md:text-left'>
                                <h1 className='text-lg md:text-3xl font-semibold'>Discover local news, events, stories, tips, and more.</h1>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>One of the most exciting features of Bubble is the ability to share posts with up to five photos and videos with a maximum length of five minutes. A post can be written as an article with a title and story you want to share.</p>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>Posts are a great way to showcase your local content and reach a wider audience. You can use photos and videos to capture the beauty, diversity, and culture of your area. You can also use articles to tell stories, share opinions, or give advice.</p>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>Whether you want to share your latest adventure, promote your business, or raise awareness about an issue, posts are the perfect way to do it. You can also interact with other users who comment on your posts and give you feedback.</p>
                            </div>
                        </div>
                        <div className='mt-14 md:mt-16 flex flex-col md:flex-row-reverse items-center'>
                            <img src={message} alt='' className='rounded-2xl border-2 w-60 pointer-events-none' />
                            <div className='mt-5 md:mt-0 md:mr-28 text-center md:text-left'>
                                <h1 className='text-lg md:text-3xl font-semibold'>Chat with your friends and stay connected with your loved ones.</h1>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>In your chat rooms, you can not only communicate, but also share content. You can send and receive photos, user posts, and stories that you find on Bubble. You can also view and comment on the content that others share. This way, you can discover new things, learn from others, and express yourself.</p>
                            </div>
                        </div>
                        <div className='mt-14 md:mt-16 flex flex-col md:flex-row items-center'>
                            <img src={status} alt='' className='rounded-2xl border-2 w-60 pointer-events-none' />
                            <div className='mt-5 md:mt-0 md:ml-28 text-center md:text-left'>
                                <h1 className='text-lg md:text-3xl font-semibold'>Feeling the need to share your moments or feelings temporarily?</h1>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>Share your mood, thoughts, and activities with your community through statuses. Statuses are short and temporary updates that you can post on your profile. You can share a video, a photo, a message, or other users’ posts as your status.</p>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>Statuses are visible to your followers and anyone who visits your profile for up to 24 hours. After that, they disappear automatically. However, you have the option to extend the lifespan of your statuses by adding them to monthly dumps. Monthly dumps are collections of your statuses that you can save on your profile. You can create a monthly dump for each month and add any status that you want to keep. Monthly dumps are visible to anyone who visits your profile.</p>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>Statuses are a great way to share your local experiences and connect with your community. You can see other users’ statuses on your feed and react to them with likes and comments. You can also view the monthly dumps of other users and see their highlights of each month.</p>
                            </div>
                        </div>
                        <div className='mt-14 md:mt-16 flex flex-col md:flex-row-reverse items-center'>
                            <img src={verification} alt='' className='rounded-2xl border-2 w-60 pointer-events-none' />
                            <div className='mt-5 md:mt-0 md:mr-28 text-center md:text-left'>
                                <h1 className='text-lg md:text-3xl font-semibold'>Get verified and become a member of our authentic community.</h1>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>We care about your safety and satisfaction on our platform, that’s why we will need you to verify your identity with your South African ID and ID Number. This is a free and easy process that will only take a few minutes of your time. You will need to upload a clear photo of your South African ID book or card and enter your ID Number in the verification form.</p>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>All unverified accounts, you will not be able to access all the features of our platform, such as liking, commenting, following, messaging other users, shopping or selling through our platform. Also all unverified accounts will be automatically deleted within 30 days. Verification will help us protect you from being targeted by scammers, hackers, or trolls who might try to use their name or image for malicious purposes.</p>
                            </div>
                        </div>
                        <div className='my-14 md:mt-16 flex flex-col md:flex-row items-center'>
                            <img src={item} alt='' className='rounded-2xl border-2 w-60 pointer-events-none' />
                            <div className='mt-5 md:mt-0 md:ml-28 text-center md:text-left'>
                                <h1 className='text-lg md:text-3xl font-semibold'>Are you a Fashion Re/Seller, Thrift Shopper or Closet Curator?</h1>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>We are excited to announce that in phase 2 of our platform, we will introduce a new feature that will revolutionize the way you shop for clothes online. You will be able to sell and buy clothes directly from other users, with a secure and convenient payment gateway and delivery system integrated into our platform.</p>
                                <p className='mt-2 text-gray-500 text-sm md:text-base'>No more hassle of sending messages for prices and delivery details, just browse, select, and order with a few clicks. Whether you want to sell your old clothes or buy some new ones, our platform will make it easy and fun for you. Stay tuned we will be launching soon!</p>
                            </div>
                        </div>
                    </div>
                </div>*/}
            </div>
        </div>
    );
};
