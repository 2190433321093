import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdVerified } from 'react-icons/md';
import { GoDotFill } from 'react-icons/go';
import moment from 'moment';

import { CredentialsContext } from '../../../components/CredentialsContext';

export default function ChatRoom({chatRoom}) {

    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
    const [user, setUser] = useState(undefined);

    useEffect(() => {
        const check = chatRoom?.users?.items?.find(u => u?.user?.id !== storedCredentials?.id);

        if (check) {
            setUser(check?.user);
        }
    }, []);

    return (
        <Link to={'/ChatRoom'} state={{ data: chatRoom }} className='flex items-center w-full max-w-2xl p-2.5 md:border-2 rounded-xl mb-2 hover:bg-gray-100'>
            {
                chatRoom?.Messages?.items[0]?.userID !== storedCredentials?.id && !chatRoom?.Messages?.items[0]?.seen ?
                <div className='w-8 mr-2.5 flex items-center justify-center'>
                    <GoDotFill size={30} className='text-bubble_blue' />
                </div>
                :
                <></>
            }
            <Link to={'/UserProfile'} state={{ data: user }}>
                <img src={user?.profile} className='w-10 h-10 rounded-full object-cover border-2 bg-gray-200 pointer-events-auto' />
            </Link>
            <div className='ml-2 w-full'>
                <div className='flex items-center'>
                    <h1 className='text-base font-semibold line-clamp-1'>{user?.name}</h1>
                    <MdVerified size={11} className='ml-0.5 text-bubble_blue' />
                    <div className='flex-grow'></div>
                    <h2 className='ml-1.5 text-gray-500 text-xs'>{moment(chatRoom?.Messages?.items[0]?.updatedAt).fromNow()}</h2>
                </div>
                <div className='flex items-center'>
                    {
                        chatRoom?.Messages?.items[0]?.userID !== storedCredentials?.id && !chatRoom?.Messages?.items[0]?.seen ?
                        <p className='text-sm text-black flex-grow line-clamp-2'>{chatRoom?.Messages?.items[0]?.message}</p>
                        :
                        <p className='text-sm text-gray-500 flex-grow line-clamp-2'>{chatRoom?.Messages?.items[0]?.message}</p>
                    }
                    {
                        chatRoom?.Messages?.items[0]?.userID === storedCredentials?.id && chatRoom?.Messages?.items[0]?.seen ?
                        <h2 className='ml-1.5 text-bubble_blue text-xs font-semibold'>seen</h2>
                        :
                        <></>
                    }
                    {
                        chatRoom?.Messages?.items[0]?.userID === storedCredentials?.id && !chatRoom?.Messages?.items[0]?.seen ?
                        <h2 className='ml-1.5 text-gray-500 text-xs'>sent</h2>
                        :
                        <></>
                    }
                </div>  
            </div>
        </Link>
    )
}
