import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoSend } from 'react-icons/io5';
import { AiOutlinePlus } from 'react-icons/ai';

import { CredentialsContext } from '../../components/CredentialsContext';
import Message from './components/Message';

export default function ChatRoom() {

    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
    const location = useLocation();
    const [chatRoom, setChatRoom] = useState(location?.state?.data);
    const [user, setUser] = useState(undefined);
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const check = chatRoom?.users?.items?.find(u => u?.user?.id !== storedCredentials?.id);

        if (check) {
            setUser(check?.user);
        };

        setMessages([...chatRoom?.Messages?.items]);
    }, []);

    const sendMessage = async () => {
        try {
            const newMessage = {
                id: Math.random().toString(),
                userID: storedCredentials?.id,
                message: message
            };

            setMessage('');

            messages.unshift(newMessage);

            setMessages([...messages]);
        } catch (error) {
            console.warn(error?.message);
        };
    };

    return (
        <div className='h-full flex flex-col items-center overflow-hidden'>
            <div className='flex pb-3 md:pb-10 flex-col h-full w-full max-w-6xl px-2'>
                <div className='flex items-center pb-3 border-b-2'>
                    <h1 className='text-2xl text-gray-500 md:text-3xl font-bold font-signature'>{user?.name}</h1>
                    <div className='flex-grow'></div>
                    <Link to={'/UserProfile'} state={{ data: user }}>
                        <img src={user?.profile} className='w-9 h-9 rounded-full bg-gray-200 border-2 object-cover pointer-events-none' />
                    </Link>
                </div>
                <div className='flex-1 overflow-y-auto flex flex-col-reverse py-2'>
                    {
                        messages.map((m) => (
                            <Message key={m?.id} message={m} />
                        ))
                    }
                </div>
                <div className='pt-3 border-t-2 rounded-lg flex items-center'>
                    <div className='w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center text-black cursor-pointer'>
                        <AiOutlinePlus size={21} />
                    </div>
                    <input
                        placeholder='Send message...'
                        className='flex-grow flex-shrink border-2 py-1.5 max-h-60 px-4 rounded-full mx-2.5 focus:outline-none'
                        value={message}
                        onChange={(text) => setMessage(text.target.value)}
                        onSubmit={sendMessage}
                    />
                    {
                        message ?
                        <div onClick={() => sendMessage()} className='w-10 h-10 rounded-full bg-bubble_blue flex items-center justify-center text-white cursor-pointer'>
                            <IoSend size={20} />
                        </div>
                        :
                        <div className='w-10 h-10 rounded-full bg-bubble_blue flex items-center justify-center text-white opacity-50'>
                            <IoSend size={20} />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};
