import React, { useEffect, useState, useContext } from 'react';
import { ClipLoader } from 'react-spinners';

import User from './User';
import { CredentialsContext } from '../../../components/CredentialsContext';

// AWS...
import { API, graphqlOperation } from 'aws-amplify';
import { listUsers } from '../../../graphql/queries';

export default function Discover() {

    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchAllUsers();
    }, []);

    const fetchAllUsers = async () => {
        try {
            setLoading(true);
            const response = await API.graphql(graphqlOperation(listUsers, {
                filter: {
                    id: {
                        ne: storedCredentials?.id
                    },
                    verified: {
                        eq: 'YES'
                    }
                }
            }));

            if (response) {
                setUsers([...response?.data?.listUsers?.items]);
            };
        } catch (error) {
            alert(error.message);
        } finally {
            setLoading(false);
        };
    };

    return (
        <div className='mt-3'>
            {
                loading ?
                <div className='flex flex-col items-center text-center'>
                    <ClipLoader color='#4299eb' size={30} />
                </div>
                :
                <>
                    {
                        users.map((user) => (
                            <User user={user} />
                        ))
                    }
                </>
            }
        </div>
    );
};
