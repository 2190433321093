import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import { CredentialsContext } from '../../components/CredentialsContext';

export default function Admin() {

    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);

    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full md:max-w-6xl px-2'>
                <h1 className='mb-4 md:mb-5 text-2xl text-gray-500 md:text-3xl font-bold font-signature'>Admin Dashboard: {storedCredentials?.name}</h1>
                <div className='grid grid-cols-3 gap-4 grid-flow-row-dense'>
                    <div className='border-2 rounded-xl py-3 px-5'>
                        <h1 className='text-3xl text-gray-600 font-signature'>Users:</h1>
                        <h2 className='my-5 text-6xl text-bubble_blue font-semibold font-signature'>{(27350).toLocaleString()}</h2>
                        <div className='border-t-2 font-signature'>
                            <div to={'/VerificationRequests'} className='flex items-center pt-1.5'>
                                <h1 className='flex-grow text-gray-500'>Verified</h1>
                                <h2>{(15781).toLocaleString()}</h2>
                            </div>
                            <div className='flex items-center pt-1.5'>
                                <h1 className='flex-grow text-gray-500'>Unverified</h1>
                                <h2>{(11569).toLocaleString()}</h2>
                            </div>
                            <div className='flex items-center pt-1.5'>
                                <h1 className='flex-grow text-gray-500'>Shop Owners</h1>
                                <h2>{(506).toLocaleString()}</h2>
                            </div>
                            <div className='flex items-center pt-1.5'>
                                <h1 className='flex-grow text-gray-500'>Banned</h1>
                                <h2>{(37).toLocaleString()}</h2>
                            </div>
                        </div>
                    </div>
                    <div className='border-2 rounded-xl py-3 px-5'>
                        <h1 className='text-3xl text-gray-600 font-signature'>Shops:</h1>
                        <h2 className='my-5 text-6xl text-bubble_blue font-semibold font-signature'>{(506).toLocaleString()}</h2>
                        <div className='border-t-2 font-signature'>
                            <div className='flex items-center pt-1.5'>
                                <h1 className='flex-grow text-gray-500'>Items</h1>
                                <h2>{(1178632).toLocaleString()}</h2>
                            </div>
                            <div className='flex items-center pt-1.5'>
                                <h1 className='flex-grow text-gray-500'>Completed Orders</h1>
                                <h2>{(135647).toLocaleString()}</h2>
                            </div>
                            <div className='flex items-center pt-1.5'>
                                <h1 className='flex-grow text-gray-500'>Pending Orders</h1>
                                <h2>{(600).toLocaleString()}</h2>
                            </div>
                            <div className='flex items-center pt-1.5'>
                                <h1 className='flex-grow text-gray-500'>Total Revenue</h1>
                                <h2>{"R " + (2500000).toLocaleString()}</h2>
                            </div>
                        </div>
                    </div>
                    <div className='border-2 rounded-xl py-3 px-5'>
                        <h1 className='text-3xl text-gray-600 font-signature'>Verifications:</h1>
                        <h2 className='my-5 text-6xl text-bubble_blue font-semibold font-signature'>{(23094).toLocaleString()}</h2>
                        <div className='border-t-2 font-signature'>
                            <div className='flex items-center pt-1.5'>
                                <Link to={'/VerificationRequests'} className='link text-gray-500 hover:text-bubble_blue'>
                                    <h1>Requests</h1>
                                </Link>
                                <div className='flex-grow'></div>
                                <h2>{(2500).toLocaleString()}</h2>
                            </div>
                            <div className='flex items-center pt-1.5'>
                                <h1 className='flex-grow text-gray-500'>Approved</h1>
                                <h2>{(15781).toLocaleString()}</h2>
                            </div>
                            <div className='flex items-center pt-1.5'>
                                <h1 className='flex-grow text-gray-500'>Declined</h1>
                                <h2>{(782).toLocaleString()}</h2>
                            </div>
                        </div>
                    </div>
                    <div className='border-2 rounded-xl py-3 px-5'>
                        <h1 className='text-3xl text-gray-600 font-signature'>Reports:</h1>
                        <h2 className='my-5 text-6xl text-bubble_blue font-semibold font-signature'>{(1668).toLocaleString()}</h2>
                        <div className='border-t-2 font-signature'>
                            <div className='flex items-center pt-1.5'>
                                <h1 className='flex-grow text-gray-500'>Posts</h1>
                                <h2>{(998).toLocaleString()}</h2>
                            </div>
                            <div className='flex items-center pt-1.5'>
                                <h1 className='flex-grow text-gray-500'>Statuses</h1>
                                <h2>{(127).toLocaleString()}</h2>
                            </div>
                            <div className='flex items-center pt-1.5'>
                                <h1 className='flex-grow text-gray-500'>Users</h1>
                                <h2>{(487).toLocaleString()}</h2>
                            </div>
                            <div className='flex items-center pt-1.5'>
                                <h1 className='flex-grow text-gray-500'>Messages</h1>
                                <h2>{(56).toLocaleString()}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
