import React from 'react'
import { Link } from 'react-router-dom';

export default function Request({request}) {
    return (
        <div className='w-full max-w-2xl border-2 py-2 px-3 rounded-lg flex gap-2.5 items-center mb-3'>
            <img src={request?.user?.profile} alt='' className='w-11 h-11 rounded-full border-2 bg-gray-200' />
            <div className='w-full'>
                <h1 className='font-semibold'>{request?.name}</h1>
                <h2 className='text-sm text-gray-600 font-semibold'>{request?.user?.username}</h2>
                <h3 className='text-xs text-gray-500 font-semibold'>ID: {request?.idNumber}</h3>
            </div>
            <Link to={'/UserVerification'} state={{ data: request }} className='link bg-bubble_blue h-9 px-6 rounded-full items-center justify-center flex text-white font-signature hover:opacity-90'>Review</Link>
        </div>
    );
};
