import React, { useState } from 'react';

function ContactUs() {

    const [firstName, setFirstName] = useState("");

    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full md:max-w-6xl px-4 md:px-0'>
                <h1 className='text-2xl font-bold font-signature text-gray-500'>Get In Touch</h1>
                <div className='md:flex md:gap-4'>
                    <div className='flex flex-col'>
                        <label className='text-sm text-gray-500 font-semibold'>FIRST NAME</label>
                        <input type='text' placeholder='Enter your first name' className='border-b-2 text-gray-400' />
                    </div>
                    <div className='flex flex-col'>
                        <label className='text-sm text-gray-500 font-semibold'>LAST NAME</label>
                        <input type='text' placeholder='Enter your last name' className='border-b-2' />
                    </div>
                </div>
                <div className='md:flex md:gap-4'>
                    <div className='flex flex-col'>
                        <label className='text-sm text-gray-500 font-semibold'>EMAIL</label>
                        <input type='email' placeholder='Enter your email' className='border-b-2' />
                    </div>
                    <div className='flex flex-col'>
                        <label className='text-sm text-gray-500 font-semibold'>PHONE NUMBER</label>
                        <input type='number' placeholder='Enter your phone number' className='border-b-2' />
                    </div>
                </div>
                <button>SUBMIT</button>
            </div>
        </div>
    );
};

export default ContactUs;