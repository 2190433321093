import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineAccountCircle } from 'react-icons/md';

import { CredentialsContext } from '../components/CredentialsContext';

function Orders() {

    const navigate = useNavigate();

    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);

    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full md:max-w-6xl px-4'>
                <h1 className='mb-4 md:mb-5 text-2xl md:text-3xl font-bold font-signature'>Orders & Returns</h1>
                {
                    storedCredentials ?
                    <div className='pt-2 md:pt-8 flex flex-col items-center'>

                    </div>
                    :
                    <div className='pt-2 md:pt-8 flex flex-col items-center text-center'>
                        <MdOutlineAccountCircle size={100} className='text-gray-300' />
                        <h1 className='mt-2.5 text-2xl font-signature'>Sign In</h1>
                        <p className='mt-2 text-gray-500 max-w-2xl'>Ooops we see that you are not signed in to your Bubble account, to be able to see and manage your orders and returns you must be signed in.</p>
                        <Link to={'/Signin'} className='mt-4 max-w-lg text-white link border-2 border-blue-400 bg-blue-400 w-full py-2.5 rounded-full'>
                            <h1 className='font-medium font-signature'>GO TO SIGN IN</h1>
                        </Link>
                    </div>
                }
            </div>
        </div>
    );
};

export default Orders;