import React, { useState } from 'react';
import { MdVerified } from 'react-icons/md';
import { Link } from 'react-router-dom';

export default function User({user}) {

    const [isFollowing, setIsFollowing] = useState(false);

    const follow = async () => {
        try {
            if (isFollowing) {

            } else {

            };

            setIsFollowing(!isFollowing);
        } catch (error) {
            console.warn(error?.message);  
        };
    };

    return (
        <div className='py-1.5'>
            <div className='flex'>
                <Link to={'/UserProfile'} state={{data: user}}>
                    <img loading='lazy' src={user?.profile} className='w-10 aspect-square border-2 object-cover bg-gray-200 rounded-full pointer-events-none' />
                </Link>
                <div className='ml-1.5 flex-grow'>
                    <Link to={'/UserProfile'} state={{data: user}}>
                        <h1 className='link text-sm font-semibold line-clamp-1'>{user?.name}</h1>
                    </Link>
                    <div className='-mt-0.5 flex items-center'>
                        <h2 className='text-xs text-gray-500 line-clamp-1'>{user?.username}</h2>
                        {
                            user?.verified === "YES" ?
                            <MdVerified size={10} className='ml-0.5 text-bubble_blue' />
                            :
                            <></>
                        }
                    </div>
                </div>
                {
                    isFollowing ?
                    <button onClick={() => follow()} className='ml-2 link rounded-full h-7 px-3 text-black border-2 border-gray-300 bg-white text-xs font-semibold hover:opacity-90'>
                        Unfollow
                    </button>
                    :
                    <button onClick={() => follow()} className='ml-2 link rounded-full h-7 px-3 text-white border-2 border-bubble_blue bg-bubble_blue text-xs font-semibold hover:opacity-90'>
                        Follow
                    </button>
                }
            </div>
        </div>
    );
};
