import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import logo from '../../assets/logo.png';
import signin from '../../assets/signin.png';
import { CredentialsContext } from '../../components/CredentialsContext';
import CustomInput from '../../components/CustomInput';

// AWS...
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { getUser } from '../../graphql/queries';

function Signin() {

    const navigate = useNavigate();

    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const signIn = async () => {
        if (!loading) {
            try {
                setLoading(true);
                const authUser = await Auth.signIn(username, password);
    
                const userData = await API.graphql(graphqlOperation(getUser, {id: authUser?.attributes?.sub}));
    
                if (userData?.data?.getUser?.isAdmin) {
                    setStoredCredentials(userData?.data?.getUser);
                    navigate('/');
                } else {
                    alert('Sorry we are still working on the website, it will be available for use soon.\n\nThank you for your patience.');
                };
            } catch (error) {
                alert(error.message);
            } finally {
                setLoading(false);
            };
        };
    };

    const checkRequirements = () => {
        if (username?.length && password?.length) {
            return true;
        } else {
            return false;
        };
    };

    const forgotPassword = () => {
        if (!loading) {
            if (username?.length) {
                navigate('/ResetPassword', {
                    state: {
                        username: username
                    }
                });
            } else {
                alert('Please enter a valid username.')
            };
        };
    };

    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full md:max-w-2xl px-2 md:px-0'>
                <div className='flex flex-col items-center'>
                    <div className='flex items-center'>
                        <img src={signin} alt='' className='w-full max-w-xs aspect-square object-cover pointer-events-none' />
                    </div>
                    <div className='my-3'>
                        <h1 className='text-lg text-gray-400 font-signature font-extrabold'>Please enter your credentials.</h1>
                    </div>
                    <CustomInput icon={'user'} type={'text'} placeholder={"Username"} value={username} setValue={setUsername} />
                    <CustomInput icon={'password'} type={'password'} placeholder={"Password"} value={password} setValue={setPassword} />
                    {
                        checkRequirements() ?
                        <button onClick={() => signIn()} style={{ opacity: loading ? 0.7 : 1, flexDirection: 'row' }} className=' flex items-center justify-center link h-10 bg-bubble_blue hover:opacity-90 text-sm text-white font-semibold w-11/12 sm:w-5/6 md:w-3/4 mb-3 py-2 rounded-full'>
                            <h1>{loading ? 'Signing In' : 'Sign In'}</h1>
                            {
                                loading ?
                                <ClipLoader  color="white" size={15} className='ml-1.5' />
                                :
                                <></>
                            }
                        </button>
                        :
                        <></>
                    }
                    <button onClick={() => forgotPassword()} className='link h-10 bg-gray-200 hover:opacity-90 text-sm font-semibold w-11/12 sm:w-5/6 md:w-3/4 mb-3 py-2 rounded-full'>
                        <h1 className=''>Forgot Password?</h1>
                    </button>
                    <Link to={!loading ? '/Signup' : undefined} className='link h-10 bg-white border-2 hover:opacity-90 border-gray-100 text-sm text-gray-500 font-semibold w-11/12 sm:w-5/6 md:w-3/4 mb-3 py-2 rounded-full text-center'>
                        <h1 className=''>Don't have an account? <span className={'text-bubble_blue font-semibold'}>Sign Up</span></h1>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Signin;
