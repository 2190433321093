import React from 'react';
import { BsFillCircleFill } from 'react-icons/bs';

function Color({color, selectedColor, setSelectedColor}) {

    const chooseColor = () => {
        if (color?.id === selectedColor?.id) {
            setSelectedColor(undefined);
        } else {
            setSelectedColor({...color})
        };
    };

    return (
        <>
            {
                color?.id === selectedColor?.id ?
                <div className='border-bubble_blue border-4 rounded-full'>
                    <BsFillCircleFill color={color?.color} size={30} />
                </div>
                :
                <div onClick={() => chooseColor()} className='cursor-pointer border-gray-300 hover:opacity-70 border-4 rounded-full'>
                    <BsFillCircleFill color={color?.color} size={30} />
                </div>
            }    
        </>
    );
};

export default Color;
