import React from 'react';

function AddressBook() {
    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full md:max-w-6xl px-4'>
                <h1 className='mb-4 md:mb-5 text-2xl md:text-3xl font-bold font-signature'>Address Book</h1>
            </div>
        </div>
    );
};

export default AddressBook;
