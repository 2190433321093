import React, { useContext } from 'react';

import { CredentialsContext } from '../../../components/CredentialsContext';
import moment from 'moment';

export default function Message({message}) {

    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);

    return (
        <>
            {
                message?.userID === storedCredentials?.id ?
                <div style={{ maxWidth: "90%", alignSelf: 'flex-end' }}>
                    <div className='relative bg-bubble_blue px-4 py-2.5 border-2 border-bubble_blue rounded-2xl my-2 mr-2'>
                        <p className='text-white'>{message?.message}</p>
                        <h1 className='text-xs text-gray-500 absolute -top-3 -right-2 py-0.5 px-1.5 border-2 bg-white rounded-full'>{moment(message?.updatedAt).fromNow()}</h1>
                    </div>
                </div>
                :
                <div className='flex ml-2 relative' style={{ maxWidth: "90%", alignSelf: 'flex-start' }}>
                    <div className='relative bg-gray-200 px-4 py-2.5 border-2 border-gray-200 rounded-2xl my-2'>
                        <p>{message?.message}</p>
                        <h1 className='text-xs text-gray-500 absolute -top-3 -right-2 py-0.5 px-1.5 border-2 bg-white rounded-full'>{moment(message?.updatedAt).fromNow()}</h1>
                    </div>
                    <img src={message?.user?.profile} className='w-6 h-6 rounded-full border-2 bg-gray-200 absolute -left-1 -bottom-0.5' />
                </div>
            }
        </>
    );
};
