import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/logo.png'
import { AiOutlineHeart, AiFillHeart, AiOutlineShoppingCart, AiFillShopping, AiFillShop, AiOutlineShop } from 'react-icons/ai';
import { BiSearch } from 'react-icons/bi';
import { FiMenu } from 'react-icons/fi';
import { HiOutlineShoppingBag, HiShoppingBag } from 'react-icons/hi';
import { RiNotification4Line, RiNotification2Fill, RiAdminLine, RiAdminFill } from 'react-icons/ri';
import { PiChatCenteredTextBold, PiChatCenteredTextFill } from 'react-icons/pi';
import { MdOutlineAccountCircle, MdAccountCircle, MdOutlineShoppingCart, MdShoppingCart } from 'react-icons/md';
import { IoInformationCircleOutline, IoInformationCircle } from "react-icons/io5";

import { CredentialsContext } from '../CredentialsContext';

function Header({menu, setMenu}) {

    const location = useLocation();
    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
    const [cartItems, setCartItems] = useState([]);
    const [wishlistItems, setWishlistItems] = useState([]);

    useEffect(() => {
        setWishlistItems(storedCredentials?.WishlistItems?.items);
        setCartItems(storedCredentials?.CartItems?.items);
    }, [storedCredentials?.WishlistItems, storedCredentials?.CartItems]);


    const calculateCartItems = () => {

        let totalItems = 0;

        cartItems.forEach((item) => {
            totalItems = totalItems + item?.quantity;
        });

        return totalItems;
    };

    return (
        <>
            {
                location.pathname !== "/AppPrivacyPolicy" && location.pathname !== "/AppTermsConditions" ?
                <header className='flex items-center w-full px-5 md:px-10 py-6'>
                    <Link to="/" className='flex items-center cursor-pointer'>
                        <img src={logo} loading='lazy' alt='' className='h-8'  />
                        <h1 className='ml-1 text-gray-600 text-lg font-bold hidden md:inline'>Bubble Social Networking</h1>
                    </Link>
                    <div className='flex-grow'></div>
                    <div className='flex gap-10 text-gray-500'>
                        <Link to='/Sellers' className='link hover:text-bubble_blue'>
                            <h2 className='text-sm font-bold'>Sellers</h2>
                        </Link>
                        <Link onClick={() => alert('Important Notice for Our Valued Users:\n\nWe would like to inform you that we are in the process of enhancing the full functionality of our website. In the meantime, we encourage you to download our app from the App Store or Play Store to enjoy the features currently available.\n\nWe appreciate your understanding and patience as we work tirelessly to deliver a complete and robust experience.\n\nThank you for being a part of our journey!\n\nWarm regards, The Bubble Social Networking Team')} className='link hover:text-bubble_blue hidden md:inline'>
                            <h2 className='text-sm font-bold'>Create Account</h2>
                        </Link>
                        <Link onClick={() => alert('Important Notice for Our Valued Users:\n\nWe would like to inform you that we are in the process of enhancing the full functionality of our website. In the meantime, we encourage you to download our app from the App Store or Play Store to enjoy the features currently available.\n\nWe appreciate your understanding and patience as we work tirelessly to deliver a complete and robust experience.\n\nThank you for being a part of our journey!\n\nWarm regards, The Bubble Social Networking Team')} className='link hover:text-bubble_blue'>
                            <h2 className='text-sm font-bold'>Sign In</h2>
                        </Link>
                    </div>
                </header>
                :
                <></>
            /*
                location.pathname !== "/AppPrivacyPolicy" && location.pathname !== "/AppTermsConditions" ?
                <header className='z-10 bg-white border-b-2 pb-2 md:pb-0 pt-1 md:pt-0.5 md:px-4 w-full'>
                    <div className='flex items-center px-4 py-3 flex-grow gap-3'>
                        <div className='sm:hidden flex items-center text-xs text-gray-500 space-x-4 sm:space-x-6 whitespace-nowrap'>
                            <div className='sm:hidden relative link flex items-center'>
                                <FiMenu onClick={() => setMenu(!menu)} size={24} className='sm:hidden' />
                            </div>
                        </div>
                        <Link to="/" className='flex flex-col sm:flex-row items-center cursor-pointer'>
                            <img src={logo} alt='' className='h-7 aspect-square rounded-full object-cover border-2 border-gray-200 sm:mr-2 pointer-events-auto' />
                            <h1 className='hidden sm:inline text-gray-500 font-semibold font-signature'>Bubble Social Networking</h1>
                        </Link>
                        <div className='sm:hidden flex-grow'></div>
                        <div className='hidden sm:flex items-center h-10 rounded-full flex-grow cursor-pointer bg-gray-200 hover:bg-gray-300 border-2 border-gray-200 mx-6'>
                            <input placeholder='Search' type='text' className='text-sm p-2 h-full w-6 flex-grow flex-shrink rounded-l-full focus:outline-none active:outline-none px-4' />
                            <BiSearch size={20} className='mx-3' />
                        </div>
                        <div className='flex items-center text-xs text-gray-500 space-x-4 sm:space-x-6 whitespace-nowrap'>
                            {
                                storedCredentials ?
                                <>
                                    {
                                        location.pathname === '/Shopping' ?
                                        <Link className='text-bubble_blue relative link flex items-center'>
                                            <HiShoppingBag size={30} className='hidden sm:inline'/>
                                            <HiShoppingBag size={27} className='sm:hidden'/>
                                            <p className='hidden md:inline font-extrabold md:text-sm mt-2 ml-1'>Shopping</p>
                                        </Link>
                                        :
                                        <Link to={'/Shopping'} className='hover:text-bubble_blue relative link flex items-center'>
                                            <HiOutlineShoppingBag size={30} className='hidden sm:inline'/>
                                            <HiOutlineShoppingBag size={27} className='sm:hidden'/>
                                            <p className='hidden md:inline font-extrabold md:text-sm mt-2 ml-1'>Shopping</p>
                                        </Link>
                                    }
                                    {
                                        location.pathname === '/Wishlist' ?
                                        <Link to="/Wishlist" className='text-bubble_blue relative link flex items-center'>
                                            <AiFillHeart size={25} className='sm:hidden' />
                                            <AiFillHeart size={30} className='hidden sm:inline' />
                                            <p className='hidden md:inline font-extrabold md:text-sm mt-2 ml-1'>List</p>
                                            {
                                                wishlistItems?.length ?
                                                <span className='absolute text-white text-xs -top-1.5 md:-top-1.5 -right-3 md:right-5 px-1.5 py-0.5 bg-red-500 text-center rounded-full'>{wishlistItems?.length}</span>
                                                :
                                                <></>
                                            }
                                        </Link>
                                        :
                                        <Link to="/Wishlist" className='hover:text-bubble_blue relative link flex items-center'>
                                            <AiOutlineHeart size={25} className='sm:hidden' />
                                            <AiOutlineHeart size={30} className='hidden sm:inline' />
                                            <p className='hidden md:inline font-extrabold md:text-sm mt-2 ml-1'>List</p>
                                            {
                                                wishlistItems?.length ?
                                                <span className='absolute text-white text-xs -top-1.5 md:-top-1.5 -right-3 md:right-5 px-1.5 py-0.5 bg-red-500 text-center rounded-full'>{wishlistItems?.length}</span>
                                                :
                                                <></>
                                            }
                                        </Link>
                                    }
                                    {
                                        location.pathname === '/Cart' ?
                                        <Link className='text-bubble_blue relative link flex items-center'>
                                            <MdShoppingCart size={23} className='sm:hidden' />
                                            <MdShoppingCart size={26} className='hidden sm:inline' />
                                            <p className='hidden md:inline font-extrabold md:text-sm mt-2 ml-1'>Cart</p>
                                            {
                                                cartItems?.length ?
                                                <span className='absolute text-white text-xs -top-1.5 md:-top-1.5 -right-2 md:right-6 px-1.5 py-0.5 bg-red-500 text-center rounded-full'>{calculateCartItems()}</span>
                                                :
                                                <></>
                                            }
                                        </Link>
                                        :
                                        <Link to="/Cart" className='hover:text-bubble_blue relative link flex items-center'>
                                            <MdOutlineShoppingCart size={23} className='sm:hidden' />
                                            <MdOutlineShoppingCart size={26} className='hidden sm:inline' />
                                            <p className='hidden md:inline font-extrabold md:text-sm mt-2 ml-1'>Cart</p>
                                            {
                                                cartItems?.length ?
                                                <span className='absolute text-white text-xs -top-1.5 md:-top-1.5 -right-2 md:right-6 px-1.5 py-0.5 bg-red-500 text-center rounded-full'>{calculateCartItems()}</span>
                                                :
                                                <></>
                                            }
                                        </Link>
                                    }
                                </>
                                :
                                <></>
                            }
                            {
                                storedCredentials ?
                                <>
                                    {
                                        location.pathname === '/Notifications' ?
                                        <Link className='text-bubble_blue relative link flex items-center'>
                                            <RiNotification2Fill size={26} className='hidden sm:inline' />
                                            <RiNotification2Fill size={24} className='sm:hidden' />
                                            <p className='hidden md:inline font-extrabold md:text-sm mt-2 ml-1'>Notifications</p>
                                        </Link>
                                        :
                                        <Link to={'/Notifications'} className='hover:text-bubble_blue relative link flex items-center'>
                                            <RiNotification4Line size={26} className='hidden sm:inline' />
                                            <RiNotification4Line size={24} className='sm:hidden'/>
                                            <p className='hidden md:inline font-extrabold md:text-sm mt-2 ml-1'>Notifications</p>
                                        </Link>
                                    }
                                </>
                                :
                                <>
                                    {
                                        location.pathname === '/Sellers' ?
                                        <Link className='text-bubble_blue relative link flex items-center'>
                                            <AiFillShop size={27} className='hidden sm:inline' />
                                            <AiFillShop size={24} className='sm:hidden' />
                                            <p className='hidden md:inline font-extrabold md:text-sm mt-2 ml-1'>Sellers</p>
                                        </Link>
                                        :
                                        <Link to={'/Sellers'} className='hover:text-bubble_blue relative link flex items-center'>
                                            <AiOutlineShop size={27} className='hidden sm:inline' />
                                            <AiOutlineShop size={24} className='sm:hidden' />
                                            <p className='hidden md:inline font-extrabold md:text-sm mt-2 ml-1'>Sellers</p>
                                        </Link>
                                    }
                                </>
                            }
                            {
                                storedCredentials ?
                                <>
                                    {
                                        location.pathname === '/Chats' ?
                                        <Link className='text-bubble_blue relative link flex items-center'>
                                            <PiChatCenteredTextFill size={26} className='hidden sm:inline' />
                                            <PiChatCenteredTextFill size={24} className='sm:hidden' />
                                            <p className='hidden md:inline font-extrabold md:text-sm mt-2 ml-1'>Chats</p>
                                        </Link>
                                        :
                                        <Link to={'/Chats'} className='hover:text-bubble_blue relative link flex items-center'>
                                            <PiChatCenteredTextBold size={26} className='hidden sm:inline' />
                                            <PiChatCenteredTextBold size={24} className='sm:hidden' />
                                            <p className='hidden md:inline font-extrabold md:text-sm mt-2 ml-1'>Chats</p>
                                        </Link>
                                    }
                                </>
                                :
                                <>
                                    {
                                        location.pathname === '/About' ?
                                        <Link className='text-bubble_blue relative link flex items-center'>
                                            <IoInformationCircle size={25} className='hidden sm:inline' />
                                            <IoInformationCircle size={23} className='sm:hidden' />
                                            <p className='hidden md:inline font-extrabold md:text-sm mt-2 ml-1'>About Us</p>
                                        </Link>
                                        :
                                        <Link to={'/About'} className='hover:text-bubble_blue relative link flex items-center'>
                                            <IoInformationCircleOutline size={25} className='hidden sm:inline' />
                                            <IoInformationCircleOutline size={23} className='sm:hidden' />
                                            <p className='hidden md:inline font-extrabold md:text-sm mt-2 ml-1'>About Us</p>
                                        </Link>
                                    }
                                </>
                            }
                            {
                                storedCredentials?.verified === "YES" ?
                                <>
                                    {
                                        location.pathname === '/Admin' ?
                                        <Link className='text-bubble_blue relative link hidden lg:flex items-center'>
                                            <RiAdminFill size={26} className='hidden sm:inline'/>
                                            <RiAdminFill size={23} className='sm:hidden'/>
                                            <p className='hidden md:inline font-extrabold md:text-sm mt-2 ml-1'>Admin</p>
                                        </Link>
                                        :
                                        <Link to={'/Admin'} className='hover:text-bubble_blue relative link hidden lg:flex items-center'>
                                            <RiAdminLine size={26} className='hidden sm:inline'/>
                                            <RiAdminLine size={23} className='sm:hidden'/>
                                            <p className='hidden md:inline font-extrabold md:text-sm mt-2 ml-1'>Admin</p>
                                        </Link>
                                    }
                                </>
                                :
                                <></>
                            }
                            {
                                storedCredentials ?
                                <Link to={'/MyProfile'}>
                                    <img loading='lazy' src={storedCredentials?.profile} className='w-8 h-8 rounded-full border-2 object-cover pointer-events-none' alt='' />
                                </Link>
                                :
                                <>
                                    {
                                        location.pathname === '/Signin' || location.pathname === '/Signup' || location.pathname === '/ResetPassword' ?
                                        <Link className='text-bubble_blue relative link flex items-center'>
                                            <MdAccountCircle  size={27} className='hidden sm:inline' />
                                            <MdAccountCircle  size={24} className='sm:hidden' />
                                            <p className='hidden md:inline font-extrabold md:text-sm mt-2 ml-1'>Sign(In/Up)</p>
                                        </Link>
                                        :
                                        <Link to={'/Signin'} className='hover:text-bubble_blue relative link flex items-center'>
                                            <MdOutlineAccountCircle size={27} className='hidden sm:inline' />
                                            <MdOutlineAccountCircle size={24} className='sm:hidden' />
                                            <p className='hidden md:inline font-extrabold md:text-sm mt-2 ml-1'>Sign(In/Up)</p>
                                        </Link>
                                    }
                                </>
                            }
                        </div>
                    </div>
        
                    <div className='flex sm:hidden items-center h-9 rounded-full flex-grow cursor-pointer bg-gray-200 hover:bg-gray-300 border-2 border-gray-200 mx-3'>
                        <input placeholder='Search' type='text' className='text-sm p-2 h-full w-6 flex-grow flex-shrink rounded-l-full focus:outline-none active:outline-none px-3' />
                        <BiSearch size={18} className='mx-2.5' />
                    </div>
                </header>
                :
                <></>
            */}
        </>
    );
};

export default Header;
