import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import logo from '../../assets/logo.png';
import signup from '../../assets/signup.png';
import { CredentialsContext } from '../../components/CredentialsContext';
import CustomInput from '../../components/CustomInput';
import CustomInputDescription from '../../CustomInputDescription';

function Signup() {
    
    const navigate = useNavigate();

    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const signUp = async () => {
        /*const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");

        if (emailRegex.test(email)) {
            navigate('/EmailVerification', {
                state: {
                    username: username
                },
            });
        } else {
            alert('Incorrect Email Format!!');
        };*/
    };

    const checkRequirements = () => {
        if (name?.length && username?.length && email?.length && password?.length && password === confirmPassword) {
            return true;
        } else {
            return false;
        };
    };

    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full md:max-w-2xl px-2 md:px-0'>
                <div className='flex flex-col items-center'>
                <div className='flex items-center'>
                        <img src={signup} alt='' className='w-full max-w-xs aspect-square object-cover pointer-events-none' />
                    </div>
                    <div className='my-3'>
                        <h1 className='text-lg text-gray-400 font-signature font-extrabold'>Please complete the form below.</h1>
                    </div>
                    <CustomInput icon={'account'} type={'text'} placeholder={"Full Name"} value={name} setValue={setName} />
                    <CustomInput icon={'user'} type={'text'} placeholder={"Username"} value={username} setValue={setUsername} />
                    <CustomInput icon={'email'} type={'email'} placeholder={"Email Address"} value={email} setValue={setEmail} />
                    <CustomInput icon={'password'} type={'password'} placeholder={"Password"} value={password} setValue={setPassword} />
                    <CustomInput icon={'password'} type={'password'} placeholder={"Confirm Password"} value={confirmPassword} setValue={setConfirmPassword} />
                    {
                        password.length && confirmPassword.length && password !== confirmPassword ?
                        <div className='w-11/12 sm:w-5/6 md:w-3/4 text-center -mt-3 mb-3'>
                            <p className='text-sm text-red-500 font-normal'>Passwords do not match!</p>
                        </div>
                        :
                        <></>
                    }
                    {
                        checkRequirements() ?
                        <button onClick={() => signUp()} className='link bg-bubble_blue hover:opacity-90 text-sm text-white font-semibold w-11/12 sm:w-5/6 md:w-3/4 mb-3 py-2 rounded-full'>
                            <h1>Sign Up</h1>
                        </button>
                        :
                        <></>   
                    }
                    <Link to={'/Signin'} className='link bg-gray-200 hover:opacity-90 text-sm font-semibold w-11/12 sm:w-5/6 md:w-3/4 mb-3 py-2 rounded-full text-center'>
                        <h1 className=''>Cancel</h1>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Signup;
