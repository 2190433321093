import React, { useEffect, useState, useContext } from 'react';
import { AiOutlineHeart } from 'react-icons/ai';

import { CredentialsContext } from '../../components/CredentialsContext';
import Product from './components/Product';
import { Link } from 'react-router-dom';

function Wishlist() {

    const [products, setProducts] = useState([]);
    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);

    useEffect(() => {
        if (storedCredentials) {
            setProducts(storedCredentials?.WishlistItems?.items);
        };
    }, [storedCredentials]);

    const removeProduct = async (id) => {
        try {
            const productIndex = storedCredentials?.WishlistItems?.items.findIndex(product => product?.id === id);

            if (productIndex > -1) {
                storedCredentials?.WishlistItems?.items.splice(productIndex, 1);
                setStoredCredentials({...storedCredentials});
            };

        } catch (error) {
            alert(error.message); 
        };
    };

    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full max-w-6xl px-2'>
                <h1 className='mb-4 md:mb-5 text-2xl text-gray-500 md:text-3xl font-bold font-signature'>{"Wishlist" + (storedCredentials?.WishlistItems?.items?.length ? ": (" + storedCredentials?.WishlistItems?.items?.length + ")" : "")}</h1>
                {
                    products?.length ?
                    <div className='flex flex-col gap-7'>
                        {
                            products.map(product => (
                                <Product key={product?.id} product={product} remove={removeProduct} />
                            ))
                        }
                    </div>
                    :
                    <div className='pt-8 flex flex-col items-center text-center'>
                        <AiOutlineHeart size={100} className='text-gray-300' />
                        <h1 className='mt-2.5 text-2xl font-signature'>Wishlist</h1>
                        <p className='mt-2 text-gray-500 max-w-2xl'>There’s nothing in your wish list right now, but don’t worry. You have plenty of time to browse our products and save the ones you like.</p>
                        <Link to={'/Shopping'} className='mt-4 max-w-lg text-white link border-2 border-bubble_blue bg-bubble_blue w-full py-2.5 rounded-full'>
                            <h1 className='font-medium font-signature'>START SHOPPING</h1>
                        </Link>
                    </div>
                }
            </div>
        </div>
    );
};

export default Wishlist;