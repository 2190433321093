import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaCircleNotch } from 'react-icons/fa';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { MdVerified } from 'react-icons/md';

import Post from '../Feed/components/Post';

// AWS...
import { API, graphqlOperation } from 'aws-amplify';
import { listPosts } from '../../graphql/queries';

export default function UserProfile() {

    const location = useLocation();
    const [user, setUser] = useState(location?.state?.data);
    const [posts, setPosts] = useState([]);
    const [followers, setFollowers] = useState([]);
    const [following, setFollowing] = useState([]);
    const [isFollowing, setIsFollowing] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchPosts();
    }, [user?.id]);


    const fetchPosts = async () => {
        try {
            setLoading(true);
            const response = await API.graphql(graphqlOperation(listPosts, {
                filter: {
                    userID: {
                        eq: user?.id
                    }
                }
            }));
    
            if (response) {
                setPosts([...response?.data?.listPosts?.items]);
            };
        } catch (error) {
            alert(error?.message);
        } finally {
            setLoading(false);
        };
    };

    const follow = async () => {
        try {
            if (isFollowing) {
                const index = followers?.findIndex(u => u?.id === "ME");

                if (index > -1) {
                    followers.splice(index, 1);
                };
            } else {
                followers.unshift({id: "ME"});
            };

            setIsFollowing(!isFollowing);
            setFollowers([...followers]);
        } catch (error) {
            console.warn(error?.message);  
        };
    };

    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full md:max-w-6xl px-2'>
                <h1 className='mb-4 md:mb-5 text-2xl text-center text-gray-500 md:text-3xl font-bold font-signature'>{user?.name}</h1>
                <div className='flex flex-col items-center'>
                    <img src={user?.profile} alt='' className='object-cover bg-gray-200 h-40 w-40 md:h-56 md:w-56 rounded-full border-2 pointer-events-none' />
                    <div className='mt-2 md:mt-3 flex items-center'>
                        <h1 className='text-base font-bold'>{user?.username}</h1>
                        {
                            user?.verified === "YES" ?
                            <MdVerified size={12} className='ml-0.5 text-bubble_blue' />
                            :
                            <></>
                        }
                    </div>
                    {
                        user?.biography ?
                        <p className='mt-2 text-center text-gray-900 text-sm md:text-base'>{user?.biography}</p>
                        :
                        <></>
                    }
                    <div className='mt-1 md:mt-2 flex flex-row items-center'>
                        <h1 className='text-xs md:text-sm font-semibold'>{followers?.length}<span className='text-gray-500 font-normal'>{" Followers"}</span></h1>
                        <h1 className='text-xs md:text-sm font-semibold mx-3.5'>{following?.length}<span className='text-gray-500 font-normal'>{" Following"}</span></h1>
                        <h1 className='text-xs md:text-sm font-semibold'>{posts?.length}<span className='text-gray-500 font-normal'>{" Posts"}</span></h1>
                    </div>
                    <div className='mt-2 md:mt-3 px-1.5 py-0.5 border-2 border-gray-300 rounded-full'>
                        <h1 className='text-xs text-gray-400 font-semibold'>{'Joined us ' + moment(user?.createdAt).fromNow()}</h1>
                    </div>
                    <div className='mt-4 w-full flex items-center justify-center gap-4'>
                        {
                            isFollowing ?
                            <button onClick={() => follow()} className='link h-10 w-full rounded-full max-w-md bg-gray-200 text-sm text-black font-semibold hover:opacity-90'>
                                Unfollow
                            </button>
                            :
                            <button onClick={() => follow()} className='link h-10 w-full rounded-full max-w-md bg-bubble_blue border-2 border-bubble_blue text-sm text-white font-semibold hover:opacity-90'>
                                Follow
                            </button>   
                        }
                    </div>
                    {
                        posts?.length ?
                        <div className='mt-4 md:mt-6 max-w-md flex flex-col items-center'>
                            {
                                posts.map(post => (
                                    <Post key={post?.id} post={post} />
                                ))
                            }
                        </div>
                        :
                        <>
                            {
                                loading ?
                                <div className='pt-8 flex flex-col items-center text-center'>
                                    <ClipLoader color='#4299eb' />
                                </div>
                                :
                                <div className='pt-8 flex flex-col items-center text-center'>
                                    <FaCircleNotch size={100} className='text-gray-300' />
                                    <h1 className='mt-2.5 text-2xl font-signature'>{user?.name.charAt(0).toUpperCase() + user?.name.slice(1)}</h1>
                                    <p className='mt-2 text-gray-500 max-w-2xl'>It seems like <span className='font-semibold'>{user?.username}</span> has no posts to show. Maybe they are new to the platform, or they prefer to browse rather than share. Either way, you can still follow them and see if they post something in the future. 😊</p>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
};
