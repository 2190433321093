import React, { useEffect, useState, useContext } from 'react';
import { ClipLoader } from 'react-spinners';

import User from './component/User';
import { CredentialsContext } from '../../components/CredentialsContext';

// AWS...
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { listUsers } from '../../graphql/queries';

export default function Discover() {

    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchAllUsers();
    }, []);

    const fetchAllUsers = async () => {
        try {
            setLoading(true);
            const response = await API.graphql(graphqlOperation(listUsers, {
                filter: {
                    id: {
                        ne: storedCredentials?.id
                    },
                    verified: {
                        eq: 'YES'
                    }
                }
            }));

            if (response) {
                setUsers([...response?.data?.listUsers?.items]);
            };
        } catch (error) {
            alert(error.message);
        } finally {
            setLoading(false);
        };
    };

    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full max-w-6xl px-2'>
                <h1 className='mb-4 md:mb-5 text-2xl text-gray-500 md:text-3xl font-bold font-signature'>Discover</h1>
                {
                    loading ?
                    <div className='w-full flex flex-col items-center'>
                        <ClipLoader color='#4299eb' />
                    </div>
                    :
                    <div className='max-w-6xl grid gap-2 md:gap-4 grid-flow-row-dense grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5'>
                        {
                            users?.map((user) => (
                                <User user={user} />
                            ))
                        }
                    </div>
                }
            </div>
        </div>
    );
};
