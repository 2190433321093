import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';

import { CredentialsContext } from '../CredentialsContext';

function Product({product}) {

    const [onWishlist, setOnWishlist] = useState(false);
    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);

    const addToWishlist = async () => {
        if (storedCredentials) {
            try {
                if (!onWishlist) {
                    setOnWishlist(true);
                    storedCredentials.WishlistItems.items.unshift(product);
                    setStoredCredentials({...storedCredentials});
                } else {
                    setOnWishlist(false);
                    const productIndex = storedCredentials?.WishlistItems?.items?.findIndex((p => p?.id === product?.id));
    
                    if (productIndex > -1) {
                        storedCredentials.WishlistItems.items.splice(productIndex, 1);
                        setStoredCredentials({...storedCredentials});
                    };
                };
            } catch (error) {
                alert(error?.message);
            };
        } else {
            alert('You need to be signed in to add items to your wishlist. Please [sign in] or [create an account] to continue shopping.');
        };
    };

    return (
        <Link to={'/Product'} state={{ data: product }} className='relative flex flex-col border-2 border-gray-200 rounded-xl md:rounded-md cursor-pointer shadow-xs hover:shadow-xl hover:bg-gray-100'>
            <img src={product?.images[0]} alt='' className='max-w-full aspect-square object-cover rounded-tl-xl md:rounded-tl-md rounded-tr-xl md:rounded-tr-md bg-gray-200' />
            <div className='p-3'>
                <h1 className='text-sm whitespace-nowrap overflow-hidden text-ellipsis'>{product?.name}</h1>
                <h2 className='mt-1 text-lg font-bold'>{"R " + product?.price.toLocaleString()}</h2>
                <div className='mt-2 flex items-center'>
                    <p className='sm:hidden text-gray-400 text-xs font-semibold px-1.5 py-0.5 border-2 border-gray-300 rounded-full'>{product?.condition}</p>
                    <p className='hidden sm:inline text-gray-400 text-xs font-semibold px-1.5 py-0.5 border-2 border-gray-300 rounded-full'>{"Condition: " + product?.condition}</p>
                    <div className='flex-grow'></div>
                </div>
                <Link to={'/Shop'} state={{ data: product?.shop }} className='mt-2.5 flex items-center'>
                    <img src={product?.shop?.logo} className='w-6 rounded-full bg-gray-300 border-2 border-gray-300' />
                    <h4 className='link ml-1 text-xs font-semibold whitespace-nowrap overflow-hidden text-ellipsis'>{product?.shop?.name}</h4>
                </Link>
            </div>
            {
                onWishlist ?
                <Link onClick={() => addToWishlist('REMOVE')} className='absolute flex items-center justify-center top-2 right-2 h-7 w-7 bg-black opacity-80 hover:opacity-70 cursor-pointer rounded-full'>
                    <AiFillHeart size={18} color='red' />
                </Link>
                :
                <Link onClick={() => addToWishlist('ADD')} className='absolute flex items-center justify-center top-2 right-2 h-7 w-7 bg-black opacity-80 hover:opacity-70 cursor-pointer rounded-full'>
                    <AiOutlineHeart size={18} color='white' />
                </Link>
            }
        </Link>
    );
};

export default Product;