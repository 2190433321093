import React, { useRef, useState, useEffect } from 'react'
import ReactPlayer from 'react-player'

export default function Video({post}) {

    const useOnScreen = (options) => {
        const ref = useRef();
        const [visible, setVisible] = useState(false);
      
        useEffect(() => {
          const observer = new IntersectionObserver(([entry]) => {
            setVisible(entry.isIntersecting)
          }, options)
      
          if (ref.current) {
            observer.observe(ref.current)
          }
      
          return () => {
            if (ref.current) {
              observer.unobserve(ref.current)
            }
          }
        }, [ref, options])
      
        return [ref, visible]
    };

    const [ref, visible] = useOnScreen({ threshold: 0.8 });

    useEffect(() => {
        if (visible) {
            ref.current.play();
        } else {
            ref.current.pause();
        };
    }, [visible]);

    return (
        <div className='aspect-3/4 w-full'>
            <video ref={ref} muted={post?.muted} loop controls={false} src={post?.images[0]} className='w-full h-full object-cover rounded-xl bg-gray-200 border-2 pointer-events-none' />
        </div>
    );
};
