import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IoSend } from 'react-icons/io5';
import { ClipLoader } from 'react-spinners';
import { FaRegComment } from 'react-icons/fa';

import Comment from './components/Comment';
import { CredentialsContext } from '../../components/CredentialsContext';

// AWS...
import { API, graphqlOperation } from 'aws-amplify';
import { listCommentsByPost } from '../../graphql/queries';
import { createComment, deleteComment } from '../../graphql/mutations';
import { onCreateComment, onDeleteComment } from '../../graphql/subscriptions';

export default function Comments() {

    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
    const location = useLocation();
    const post = location?.state?.data;
    const [message, setMessage] = useState('');
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchComments();

        const createSubscription = '';
        const deleteSubscription = '';

        /*return () => {
            createSubscription.unsubscribe();
            deleteSubscription.unsubscribe();
        };*/
    }, [post]);

    const fetchComments = async () => {
        try {
            setLoading(true);
            const response = await API.graphql(graphqlOperation(listCommentsByPost, {postID: post?.id}));

            setComments(response?.data?.listCommentsByPost?.items);
        } catch (error) {
            alert(error?.message);  
        } finally {
            setLoading(false);
        };
    };

    const sendComment = async () => {
        try {
            const newComment = {
                postID: post?.id,
                userID: storedCredentials?.id,
                message: message
            };

            await API.graphql(graphqlOperation(createComment, {input: newComment}));
        } catch (error) {
            alert(error?.message);
        };
    };

    return (
        <div className='h-full flex flex-col items-center overflow-hidden'>
            <div className='flex pb-3 md:pb-10 flex-col h-full w-full max-w-6xl px-2'>
                <div className='flex items-center pb-3 border-b-2'>
                    <h1 className='text-2xl text-gray-500 md:text-3xl font-bold font-signature'>Comments</h1>
                </div>
                <div className='flex-1 overflow-y-auto flex flex-col py-2'>
                    {
                        comments?.length ?
                        <>
                            {
                                comments.map((c) => (
                                    <Comment key={c?.id} post={post} comment={c} />
                                ))
                            }
                        </>
                        :
                        <>
                            {
                                loading ?
                                <div className='pt-8 flex flex-col items-center text-center'>
                                    <ClipLoader color='#4299eb' />
                                </div>
                                :
                                <div className='pt-8 flex flex-col items-center text-center'>
                                    <FaRegComment size={100} className='text-gray-300' />
                                    <h1 className='mt-2.5 text-2xl font-signature'>Comments</h1>
                                    <p className='mt-2 text-gray-500 max-w-2xl'>It seems like this post does not have any comments yet, go ahead and be the first to comment. 😊</p>
                                </div>
                            }
                        </>
                    }
                </div>
                {
                    storedCredentials?.verified === "YES" ?
                    <div className='pt-3 border-t-2 flex items-center'>
                        <input
                            placeholder='Send comment...'
                            className='flex-grow flex-shrink border-2 py-1.5 max-h-60 px-4 rounded-full mr-2.5 focus:outline-none'
                            value={message}
                            onChange={(text) => setMessage(text.target.value)}
                        />
                        {
                            message ?
                            <div onClick={() => sendComment()} className='w-10 h-10 rounded-full bg-bubble_blue flex items-center justify-center text-white cursor-pointer'>
                                <IoSend size={20} />
                            </div>
                            :
                            <div className='w-10 h-10 rounded-full bg-bubble_blue flex items-center justify-center text-white opacity-50'>
                                <IoSend size={20} />
                            </div>
                        }
                    </div>
                    :
                    <></>
                }
            </div>
        </div>
    );
};
