import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import logo from '../../assets/logo.png';
import verifyemail from '../../assets/verifyemail.png';
import { CredentialsContext } from '../../components/CredentialsContext';
import CustomInput from '../../components/CustomInput';
import CustomInputDescription from '../../CustomInputDescription';

function EmailVerification() {

    const navigate = useNavigate();
    const location = useLocation();
    const username = location.state.username;

    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);

    const verifyEmail = async () => {
        //navigate('/Signin');
    };

    const checkRequirements = () => {
        if (otp?.length) {
            return true;
        } else {
            return false;
        };
    };

    return (
        <div className='min-h-full flex flex-col items-center pb-6'>
            <div className='w-full md:max-w-2xl px-2 md:px-0'>
                <div className='flex flex-col items-center'>
                    <div className='flex items-center'>
                        <img src={verifyemail} alt='' className='w-full max-w-xs aspect-square object-cover pointer-events-none' />
                    </div>
                    <div className='my-3'>
                        <h1 className='text-lg text-gray-400 font-signature font-extrabold'>Please check your email for your OPT.</h1>
                    </div>
                    <CustomInput icon={'otp'} type={'text'} placeholder={"OTP (One Time Pin)"} value={otp} setValue={setOtp} />
                    {
                        checkRequirements() ?
                        <button onClick={() => verifyEmail()} className='link bg-bubble_blue hover:opacity-90 text-sm text-white font-semibold w-11/12 sm:w-5/6 md:w-3/4 mb-3 py-2 rounded-full'>
                            <h1>Verify Email</h1>
                        </button>
                        :
                        <></>
                    }
                    <button className='link bg-gray-200 hover:opacity-90 text-sm font-semibold w-11/12 sm:w-5/6 md:w-3/4 mb-3 py-2 rounded-full text-center'>
                        <h1 className=''>Resend OTP</h1>
                    </button>
                    <Link to={'/Signin'} className='link bg-white border-2 hover:opacity-90 border-gray-100 text-sm text-gray-500 font-semibold w-11/12 sm:w-5/6 md:w-3/4 mb-3 py-2 rounded-full text-center'>
                        <h1 className=''>Cancel</h1>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default EmailVerification;
