import React from 'react';

function Size({size, selectedSize, setSelectedSize}) {

    const chooseSize = () => {
        if (size?.id === selectedSize?.id) {
            setSelectedSize(undefined);
        } else {
            setSelectedSize({...size})
        };
    };

    return (
        <>
            {
                size?.id === selectedSize?.id ?
                <div onClick={() => chooseSize()} className='cursor-pointer border-2 hover:border-gray-300 border-blue-400 bg-bubble_blue py-1.5 w-16 text-center rounded-full'>
                    <h1 className='text-xs text-white font-extrabold font-signature'>{size?.size}</h1>
                </div>
                :
                <div onClick={() => chooseSize()} className='cursor-pointer border-2 border-gray-300 hover:border-bubble_blue py-1.5 w-16 text-center rounded-full'>
                    <h1 className='text-xs font-extrabold font-signature'>{size?.size}</h1>
                </div>
            }
        </>
    );
};

export default Size;
