import React, { useState, useContext, useEffect } from 'react';
import { MdVerified } from 'react-icons/md';
import { IoEllipsisHorizontalSharp } from 'react-icons/io5';
import { FaRegComment } from 'react-icons/fa';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import Photo from './Photo';
import Video from './Video';
import { CredentialsContext } from '../../../components/CredentialsContext';

// AWS...
import { API, graphqlOperation } from 'aws-amplify';
import { listLikes } from '../../../graphql/queries';
import { createLike, deleteLike } from '../../../graphql/mutations';

export default function Post({post}) {

    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
    const [like, setLike] = useState(undefined);
    const [liked, setLiked] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        checkLike();
    }, [post?.id]);

    const checkLike = async () => {
        try {
            setLoading(true);
            const response = await API.graphql(graphqlOperation(listLikes, {
                filter: {
                    userID: {
                        eq: storedCredentials?.id
                    },
                    postID: {
                        eq: post?.id
                    }
                }
            }));

            if (response?.data?.listLikes?.items[0]) {
                setLiked(true);
            } else {
                setLiked(false);
            }
        } catch (error) {
            alert(error?.message);
        } finally {
            setLoading(false);
        };
    };

    const likePost = async () => {
        if (!loading ) {
            if (storedCredentials?.verified === "YES") {
                try {
                    setLoading(true);
                    setLiked(!liked);
    
                    let myLike = await API.graphql(graphqlOperation(listLikes, {
                        filter: {
                            userID: {
                                eq: storedCredentials?.id
                            },
                            postID: {
                                eq: post?.id
                            }
                        }
                    }));
    
                    if (myLike?.data?.listLikes?.items[0]) {
                        await API.graphql(graphqlOperation(deleteLike, {input: {id: myLike?.data?.listLikes?.items[0]?.id, _version: myLike?.data?.listLikes?.items[0]?._version}}));
                    } else {
                        await API.graphql(graphqlOperation(createLike, {input: {userID: storedCredentials?.id, postID: post?.id}}));
                    };
                } catch (error) {
                    alert(error?.message);  
                } finally {
                    setLoading(false);
                };
            } else {
                alert('', '');
            };
        };
    };

    return (
        <div className='mb-4 md:mb-6 px-2 w-full'>
            {
                post?.type === "CONTENT" ?
                <>
                    {
                        post?.isVideo ?
                        <Video post={post} />
                        :
                        <Photo photos={post?.images} />
                    }
                </>
                :
                <></>
            }
            {
                post?.type === "CONTENT" ?
                <>
                    {
                        post?.title && post?.caption ?
                        <h1 className='ml-1 mr-1 mt-2 -mb-1 text-lg font-semibold'>{post?.title}</h1>
                        :
                        <></>
                    }
                    {
                        post?.caption ?
                        <p className='ml-1 mr-1 mt-2 text-sm'>{post?.caption}</p>
                        :
                        <></>
                    }
                </>
                :
                <div className='bg-gray-100 border-2 border-gray-200 py-2.5 px-3 rounded-xl'>
                    {
                        post?.title && post?.caption ?
                        <h1 className='ml-1 mr-1 mb-1.5 text-lg font-semibold'>{post?.title}</h1>
                        :
                        <></>
                    }
                    {
                        post?.caption ?
                        <p className='ml-1 mr-1 text-sm'>{post?.caption}</p>
                        :
                        <></>
                    }
                </div>
            }
            <div className='flex items-center pt-2'>
                <Link to={'/UserProfile'} state={{ data: post?.user }}>
                    <img src={post?.user?.profile} className='w-9 h-9 rounded-full object-cover bg-gray-200 border-2 pointer-events-none' />
                </Link>
                <div className='ml-1.5 flex-grow'>
                    <div className='flex items-center'>
                        <Link to={'/UserProfile'} state={{ data: post?.user }}>
                            <h1 className='link text-sm font-extrabold'>{post?.user?.username}</h1>
                        </Link>
                        {
                            post?.user?.verified === "YES" ?
                            <MdVerified size={12} className='ml-0.5 text-bubble_blue' />
                            :
                            <></>
                        }
                    </div>
                    <h2 className='text-xs text-gray-400 -mt-1'>{"a day ago"}</h2>
                </div>
                <div className='flex items-center gap-2'>
                    <button onClick={() => likePost()} className='h-8 w-8 bg-gray-200 rounded-full flex items-center justify-center hover:opacity-75'>
                        {
                            liked ?
                            <AiFillHeart size={16} color={'red'} />
                            :
                            <AiOutlineHeart size={16} />
                        }
                    </button>
                    <Link to={'/Comments'} state={{ data: post }} className='h-8 w-8 bg-gray-200 rounded-full flex items-center justify-center hover:opacity-75'>
                        <FaRegComment size={14} />
                    </Link>
                    <button className='h-8 w-8 bg-gray-200 rounded-full flex items-center justify-center hover:opacity-75'>
                        <IoEllipsisHorizontalSharp size={15} />
                    </button>
                </div>
            </div>
        </div>
    );
};
