import React, { useContext } from 'react';
import appstoresvg from '../../assets/appstoresvg.svg';
import playstore from '../../assets/playstoresvg.svg';
import { Link, useLocation } from 'react-router-dom';
import { CredentialsContext } from '../CredentialsContext';

function Footer() {

    const location = useLocation();
    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);

    return (
        <>
            {
                location.pathname !== '/ChatRoom' && location.pathname !== '/Comments' && location.pathname !== '/Replies' && location.pathname !== "/AppPrivacyPolicy" && location.pathname !== "/AppTermsConditions" ?
                <footer className='flex flex-col items-center py-6 px-10 bg-bubble_blue text-white'>
                    <div className='max-w-6xl flex flex-col gap-5 items-center'>
                        <div className='flex flex-col md:flex-row gap-10 md:gap-24'>
                            <div className='flex flex-col items-center md:items-start gap-1'>
                                <h1 className='font-semibold mb-1.5'>Help</h1>
                                <Link className='link hover:text-white text-sm text-gray-200'>Contact Us</Link>
                                <Link className='link hover:text-white text-sm text-gray-200'>Help Center</Link>
                                <Link className='link hover:text-white text-sm text-gray-200'>Shipping & Delivery</Link>
                                <Link className='link hover:text-white text-sm text-gray-200'>Exchanges</Link>
                            </div>
                            <div className='flex flex-col items-center md:items-start gap-1'>
                                <h1 className='font-semibold mb-1.5'>Account</h1>
                                <Link className='link hover:text-white text-sm text-gray-200'>My Account</Link>
                                <Link className='link hover:text-white text-sm text-gray-200'>Track Order</Link>
                                <Link className='link hover:text-white text-sm text-gray-200'>Returns</Link>
                            </div>
                            <div className='flex flex-col items-center md:items-start gap-1'>
                                <h1 className='font-semibold mb-1.5'>Company</h1>
                                <Link to={'/Sellers'} className='link hover:text-white text-sm text-gray-200'>Sellers</Link>
                                <Link to={'/PrivacyPolicy'} className='link hover:text-white text-sm text-gray-200'>Privacy Policy</Link>
                                <Link to={'/TermsConditions'} className='link hover:text-white text-sm text-gray-200'>Terms & Conditions</Link>
                                <Link  onClick={() => window.open("https://popia.co.za/")} className='link hover:text-white text-sm text-gray-200'>POPIA Act</Link>
                            </div>
                            <div className='hidden md:inline'>
                                <h1 className='font-semibold mb-2.5'>Download Our Apps</h1>
                                <div className='flex flex-col gap-2 items-start'>
                                    <img src={appstoresvg} alt='' onClick={() => window.open('https://apps.apple.com/za/app/bubble-social-networking/id6474073256')} className='cursor-pointer h-10 hover:opacity-80' />
                                    <img src={playstore} alt='' onClick={() => window.open('https://play.google.com/store/apps/details?id=com.bubble.socialnetworking&pli=1')} className='cursor-pointer h-10 hover:opacity-80' />
                                </div>
                            </div>
                        </div>
                        <div className='md:hidden items-center flex flex-col'>
                            <h1 className='mb-2.5 text-md font-semibold'>Download Our Apps</h1>
                            <div className='flex gap-3'>
                                <img src={appstoresvg} alt='' onClick={() => window.open('https://apps.apple.com/za/app/bubble-social-networking/id6474073256')} className='cursor-pointer h-10 hover:opacity-80' />
                                <img src={playstore} alt='' onClick={() => window.open('https://play.google.com/store/apps/details?id=com.bubble.socialnetworking&pli=1')} className='cursor-pointer h-10 hover:opacity-80' />
                            </div>
                        </div>
                        <p className='text-extralight text-xs opacity-80 mt-2'>&copy; Bubble Social Networking (Pty) Ltd.</p>
                    </div>
                </footer>
                :
                <></>
            }
        </>
    );
};

export default Footer;