import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MdVerified } from 'react-icons/md';

import { CredentialsContext } from '../../../components/CredentialsContext';

export default function Reply({post, comment, reply, setReplyingTo}) {

    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);

    return (
        <div className='flex mb-4'>
            <Link to={storedCredentials?.id === reply?.userID ? '/MyProfile' : '/UserProfile'} state={{ data: reply?.user }}>
                <img src={reply?.user?.profile} className='w-8 h-8 rounded-full border-2 bg-gray-200' />
            </Link>
            <div className='ml-2'>
                <div className='flex items-center'>
                    <h1 className='text-sm font-semibold'>{reply?.user?.username}</h1>
                    <MdVerified size={11} className='ml-0.5 text-bubble_blue' />
                </div>
                <p className='text-sm'>{reply?.message}</p>
                <div className='flex items-center mt-1.5 gap-3'>
                    <div onClick={() => setReplyingTo(reply?.user)} className='link text-bubble_blue text-xs font-semibold cursor-pointer'>
                        Reply
                    </div>
                </div>
            </div>
        </div>
    );
};
