/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      id
      type
      company
      street
      suburb
      city
      province
      country
      lat
      lng
      name
      mobile
      email
      userID
      shopID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAddresses = /* GraphQL */ `
  query ListAddresses(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        company
        street
        suburb
        city
        province
        country
        lat
        lng
        name
        mobile
        email
        userID
        shopID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAddresses = /* GraphQL */ `
  query SyncAddresses(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAddresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        company
        street
        suburb
        city
        province
        country
        lat
        lng
        name
        mobile
        email
        userID
        shopID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const addressesByUserID = /* GraphQL */ `
  query AddressesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    addressesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        company
        street
        suburb
        city
        province
        country
        lat
        lng
        name
        mobile
        email
        userID
        shopID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const addressesByShopID = /* GraphQL */ `
  query AddressesByShopID(
    $shopID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    addressesByShopID(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        company
        street
        suburb
        city
        province
        country
        lat
        lng
        name
        mobile
        email
        userID
        shopID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderItem = /* GraphQL */ `
  query GetOrderItem($id: ID!) {
    getOrderItem(id: $id) {
      id
      amount
      quantity
      size
      color
      orderID
      order {
        id
        payed
        status
        deliveryFee
        delivered
        delieveredDate
        handedToCourier
        handedToCourierDate
        expectedDeliveryDate
        recipientName
        recipientPhoneNumber
        recipientAddress
        createdAt
        orderItems {
          items {
            id
            amount
            quantity
            size
            color
            orderID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderItemItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        address {
          id
          type
          company
          street
          suburb
          city
          province
          country
          lat
          lng
          name
          mobile
          email
          userID
          shopID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderAddressId
        __typename
      }
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderItemItemId
      __typename
    }
  }
`;
export const listOrderItems = /* GraphQL */ `
  query ListOrderItems(
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amount
        quantity
        size
        color
        orderID
        order {
          id
          payed
          status
          deliveryFee
          delivered
          delieveredDate
          handedToCourier
          handedToCourierDate
          expectedDeliveryDate
          recipientName
          recipientPhoneNumber
          recipientAddress
          createdAt
          orderItems {
            nextToken
            startedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          address {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderAddressId
          __typename
        }
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderItemItemId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrderItems = /* GraphQL */ `
  query SyncOrderItems(
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        amount
        quantity
        size
        color
        orderID
        order {
          id
          payed
          status
          deliveryFee
          delivered
          delieveredDate
          handedToCourier
          handedToCourierDate
          expectedDeliveryDate
          recipientName
          recipientPhoneNumber
          recipientAddress
          createdAt
          orderItems {
            nextToken
            startedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          address {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderAddressId
          __typename
        }
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderItemItemId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const orderItemsByOrderID = /* GraphQL */ `
  query OrderItemsByOrderID(
    $orderID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderItemsByOrderID(
      orderID: $orderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        quantity
        size
        color
        orderID
        order {
          id
          payed
          status
          deliveryFee
          delivered
          delieveredDate
          handedToCourier
          handedToCourierDate
          expectedDeliveryDate
          recipientName
          recipientPhoneNumber
          recipientAddress
          createdAt
          orderItems {
            nextToken
            startedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          address {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderAddressId
          __typename
        }
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderItemItemId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      payed
      status
      deliveryFee
      delivered
      delieveredDate
      handedToCourier
      handedToCourierDate
      expectedDeliveryDate
      recipientName
      recipientPhoneNumber
      recipientAddress
      createdAt
      orderItems {
        items {
          id
          amount
          quantity
          size
          color
          orderID
          order {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderItemItemId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      shopID
      shop {
        id
        name
        logo
        about
        emailAddress
        phoneNumber
        province
        address
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        items {
          items {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      address {
        id
        type
        company
        street
        suburb
        city
        province
        country
        lat
        lng
        name
        mobile
        email
        userID
        shopID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderAddressId
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        payed
        status
        deliveryFee
        delivered
        delieveredDate
        handedToCourier
        handedToCourierDate
        expectedDeliveryDate
        recipientName
        recipientPhoneNumber
        recipientAddress
        createdAt
        orderItems {
          items {
            id
            amount
            quantity
            size
            color
            orderID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderItemItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        address {
          id
          type
          company
          street
          suburb
          city
          province
          country
          lat
          lng
          name
          mobile
          email
          userID
          shopID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderAddressId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrders = /* GraphQL */ `
  query SyncOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        payed
        status
        deliveryFee
        delivered
        delieveredDate
        handedToCourier
        handedToCourierDate
        expectedDeliveryDate
        recipientName
        recipientPhoneNumber
        recipientAddress
        createdAt
        orderItems {
          items {
            id
            amount
            quantity
            size
            color
            orderID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderItemItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        address {
          id
          type
          company
          street
          suburb
          city
          province
          country
          lat
          lng
          name
          mobile
          email
          userID
          shopID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderAddressId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ordersByUserID = /* GraphQL */ `
  query OrdersByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        payed
        status
        deliveryFee
        delivered
        delieveredDate
        handedToCourier
        handedToCourierDate
        expectedDeliveryDate
        recipientName
        recipientPhoneNumber
        recipientAddress
        createdAt
        orderItems {
          items {
            id
            amount
            quantity
            size
            color
            orderID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderItemItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        address {
          id
          type
          company
          street
          suburb
          city
          province
          country
          lat
          lng
          name
          mobile
          email
          userID
          shopID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderAddressId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listOrdersByShop = /* GraphQL */ `
  query ListOrdersByShop(
    $shopID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdersByShop(
      shopID: $shopID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        payed
        status
        deliveryFee
        delivered
        delieveredDate
        handedToCourier
        handedToCourierDate
        expectedDeliveryDate
        recipientName
        recipientPhoneNumber
        recipientAddress
        createdAt
        orderItems {
          items {
            id
            amount
            quantity
            size
            color
            orderID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderItemItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        address {
          id
          type
          company
          street
          suburb
          city
          province
          country
          lat
          lng
          name
          mobile
          email
          userID
          shopID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderAddressId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getWishListItem = /* GraphQL */ `
  query GetWishListItem($id: ID!) {
    getWishListItem(id: $id) {
      id
      color
      itemID
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listWishListItems = /* GraphQL */ `
  query ListWishListItems(
    $filter: ModelWishListItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWishListItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        color
        itemID
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncWishListItems = /* GraphQL */ `
  query SyncWishListItems(
    $filter: ModelWishListItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWishListItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        color
        itemID
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const wishListItemsByItemID = /* GraphQL */ `
  query WishListItemsByItemID(
    $itemID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWishListItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    wishListItemsByItemID(
      itemID: $itemID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        color
        itemID
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const wishListItemsByUserID = /* GraphQL */ `
  query WishListItemsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWishListItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    wishListItemsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        color
        itemID
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCartItem = /* GraphQL */ `
  query GetCartItem($id: ID!) {
    getCartItem(id: $id) {
      id
      quantity
      size
      color
      itemID
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCartItems = /* GraphQL */ `
  query ListCartItems(
    $filter: ModelCartItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCartItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quantity
        size
        color
        itemID
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCartItems = /* GraphQL */ `
  query SyncCartItems(
    $filter: ModelCartItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCartItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        quantity
        size
        color
        itemID
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const cartItemsByItemID = /* GraphQL */ `
  query CartItemsByItemID(
    $itemID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCartItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cartItemsByItemID(
      itemID: $itemID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quantity
        size
        color
        itemID
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const cartItemsByUserID = /* GraphQL */ `
  query CartItemsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCartItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cartItemsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quantity
        size
        color
        itemID
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSize = /* GraphQL */ `
  query GetSize($id: ID!) {
    getSize(id: $id) {
      id
      index
      size
      quantity
      colorID
      color {
        id
        color
        images
        itemID
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sizes {
          items {
            id
            index
            size
            quantity
            colorID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSizes = /* GraphQL */ `
  query ListSizes(
    $filter: ModelSizeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSizes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        index
        size
        quantity
        colorID
        color {
          id
          color
          images
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          sizes {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSizes = /* GraphQL */ `
  query SyncSizes(
    $filter: ModelSizeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSizes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        index
        size
        quantity
        colorID
        color {
          id
          color
          images
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          sizes {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const sizesByColorID = /* GraphQL */ `
  query SizesByColorID(
    $colorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSizeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sizesByColorID(
      colorID: $colorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        index
        size
        quantity
        colorID
        color {
          id
          color
          images
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          sizes {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getColor = /* GraphQL */ `
  query GetColor($id: ID!) {
    getColor(id: $id) {
      id
      color
      images
      itemID
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      sizes {
        items {
          id
          index
          size
          quantity
          colorID
          color {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listColors = /* GraphQL */ `
  query ListColors(
    $filter: ModelColorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listColors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        color
        images
        itemID
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sizes {
          items {
            id
            index
            size
            quantity
            colorID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncColors = /* GraphQL */ `
  query SyncColors(
    $filter: ModelColorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncColors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        color
        images
        itemID
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sizes {
          items {
            id
            index
            size
            quantity
            colorID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const colorsByItemID = /* GraphQL */ `
  query ColorsByItemID(
    $itemID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelColorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    colorsByItemID(
      itemID: $itemID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        color
        images
        itemID
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sizes {
          items {
            id
            index
            size
            quantity
            colorID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      name
      description
      brand
      category
      gender
      age
      condition
      price
      wasPrice
      colors {
        items {
          id
          color
          images
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          sizes {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cartItems {
        items {
          id
          quantity
          size
          color
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      wishListItems {
        items {
          id
          color
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      shopID
      shop {
        id
        name
        logo
        about
        emailAddress
        phoneNumber
        province
        address
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        items {
          items {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncItems = /* GraphQL */ `
  query SyncItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const itemsByShopID = /* GraphQL */ `
  query ItemsByShopID(
    $shopID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByShopID(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getShop = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      id
      name
      logo
      about
      emailAddress
      phoneNumber
      province
      address
      userID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      items {
        items {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          payed
          status
          deliveryFee
          delivered
          delieveredDate
          handedToCourier
          handedToCourierDate
          expectedDeliveryDate
          recipientName
          recipientPhoneNumber
          recipientAddress
          createdAt
          orderItems {
            nextToken
            startedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          address {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderAddressId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      addresses {
        items {
          id
          type
          company
          street
          suburb
          city
          province
          country
          lat
          lng
          name
          mobile
          email
          userID
          shopID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listShops = /* GraphQL */ `
  query ListShops(
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo
        about
        emailAddress
        phoneNumber
        province
        address
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        items {
          items {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncShops = /* GraphQL */ `
  query SyncShops(
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncShops(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        logo
        about
        emailAddress
        phoneNumber
        province
        address
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        items {
          items {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const shopsByUserID = /* GraphQL */ `
  query ShopsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shopsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        logo
        about
        emailAddress
        phoneNumber
        province
        address
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        items {
          items {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getView = /* GraphQL */ `
  query GetView($id: ID!) {
    getView(id: $id) {
      id
      userID
      statusID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listViews = /* GraphQL */ `
  query ListViews(
    $filter: ModelViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listViews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        statusID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncViews = /* GraphQL */ `
  query SyncViews(
    $filter: ModelViewFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncViews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        statusID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const viewsByUserID = /* GraphQL */ `
  query ViewsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    viewsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        statusID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const viewsByStatusID = /* GraphQL */ `
  query ViewsByStatusID(
    $statusID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    viewsByStatusID(
      statusID: $statusID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        statusID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDeletedChatRoom = /* GraphQL */ `
  query GetDeletedChatRoom($id: ID!) {
    getDeletedChatRoom(id: $id) {
      id
      chatRoomID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDeletedChatRooms = /* GraphQL */ `
  query ListDeletedChatRooms(
    $filter: ModelDeletedChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeletedChatRooms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatRoomID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDeletedChatRooms = /* GraphQL */ `
  query SyncDeletedChatRooms(
    $filter: ModelDeletedChatRoomFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeletedChatRooms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        chatRoomID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const deletedChatRoomsByUserID = /* GraphQL */ `
  query DeletedChatRoomsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDeletedChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deletedChatRoomsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatRoomID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      type
      message
      image
      seen
      userID
      createdAt
      post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      chatroomID
      status {
        id
        type
        expiryTime
        isDump
        url
        caption
        fontFamily
        alignCenter
        height
        width
        isMuted
        resizeMode
        color
        duration
        createdAt
        statusroomID
        StatusRoom {
          id
          month
          noStatuses
          createdAt
          updatedAt
          Statuses {
            nextToken
            startedAt
            __typename
          }
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        statusUserId
        statusPostId
        statusItemId
        __typename
      }
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      messagePostId
      messageStatusId
      messageItemId
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        message
        image
        seen
        userID
        createdAt
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        chatroomID
        status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messagePostId
        messageStatusId
        messageItemId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMessages = /* GraphQL */ `
  query SyncMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        message
        image
        seen
        userID
        createdAt
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        chatroomID
        status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messagePostId
        messageStatusId
        messageItemId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const messagesByUserID = /* GraphQL */ `
  query MessagesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        message
        image
        seen
        userID
        createdAt
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        chatroomID
        status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messagePostId
        messageStatusId
        messageItemId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMessagesByChatRoom = /* GraphQL */ `
  query ListMessagesByChatRoom(
    $chatroomID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessagesByChatRoom(
      chatroomID: $chatroomID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        message
        image
        seen
        userID
        createdAt
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        chatroomID
        status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messagePostId
        messageStatusId
        messageItemId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChatRoom = /* GraphQL */ `
  query GetChatRoom($id: ID!) {
    getChatRoom(id: $id) {
      id
      Messages {
        items {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      LastMessage {
        id
        type
        message
        image
        seen
        userID
        createdAt
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        chatroomID
        status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messagePostId
        messageStatusId
        messageItemId
        __typename
      }
      users {
        items {
          id
          chatRoomId
          userId
          chatRoom {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatRoomLastMessageId
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatRoomLastMessageId
      __typename
    }
  }
`;
export const listChatRooms = /* GraphQL */ `
  query ListChatRooms(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        LastMessage {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        users {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatRoomLastMessageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChatRooms = /* GraphQL */ `
  query SyncChatRooms(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatRooms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        LastMessage {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        users {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatRoomLastMessageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBlockedBy = /* GraphQL */ `
  query GetBlockedBy($id: ID!) {
    getBlockedBy(id: $id) {
      id
      blockedbyusersID
      userID
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBlockedBies = /* GraphQL */ `
  query ListBlockedBies(
    $filter: ModelBlockedByFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlockedBies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        blockedbyusersID
        userID
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBlockedBies = /* GraphQL */ `
  query SyncBlockedBies(
    $filter: ModelBlockedByFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBlockedBies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        blockedbyusersID
        userID
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const blockedBiesByBlockedbyusersID = /* GraphQL */ `
  query BlockedBiesByBlockedbyusersID(
    $blockedbyusersID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBlockedByFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blockedBiesByBlockedbyusersID(
      blockedbyusersID: $blockedbyusersID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        blockedbyusersID
        userID
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const blockedBiesByUserID = /* GraphQL */ `
  query BlockedBiesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBlockedByFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blockedBiesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        blockedbyusersID
        userID
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBlocked = /* GraphQL */ `
  query GetBlocked($id: ID!) {
    getBlocked(id: $id) {
      id
      blockedByID
      blockedusersID
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBlockeds = /* GraphQL */ `
  query ListBlockeds(
    $filter: ModelBlockedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlockeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        blockedByID
        blockedusersID
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBlockeds = /* GraphQL */ `
  query SyncBlockeds(
    $filter: ModelBlockedFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBlockeds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        blockedByID
        blockedusersID
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const blockedsByBlockedusersID = /* GraphQL */ `
  query BlockedsByBlockedusersID(
    $blockedusersID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBlockedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blockedsByBlockedusersID(
      blockedusersID: $blockedusersID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        blockedByID
        blockedusersID
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const blockedsByUserID = /* GraphQL */ `
  query BlockedsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBlockedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blockedsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        blockedByID
        blockedusersID
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBlockedByUsers = /* GraphQL */ `
  query GetBlockedByUsers($id: ID!) {
    getBlockedByUsers(id: $id) {
      id
      Users {
        items {
          id
          blockedbyusersID
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBlockedByUsers = /* GraphQL */ `
  query ListBlockedByUsers(
    $filter: ModelBlockedByUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlockedByUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Users {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBlockedByUsers = /* GraphQL */ `
  query SyncBlockedByUsers(
    $filter: ModelBlockedByUsersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBlockedByUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Users {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBlockedUsers = /* GraphQL */ `
  query GetBlockedUsers($id: ID!) {
    getBlockedUsers(id: $id) {
      id
      Users {
        items {
          id
          blockedByID
          blockedusersID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBlockedUsers = /* GraphQL */ `
  query ListBlockedUsers(
    $filter: ModelBlockedUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlockedUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Users {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBlockedUsers = /* GraphQL */ `
  query SyncBlockedUsers(
    $filter: ModelBlockedUsersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBlockedUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Users {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      message
      seen
      response
      senderID
      Status {
        id
        type
        expiryTime
        isDump
        url
        caption
        fontFamily
        alignCenter
        height
        width
        isMuted
        resizeMode
        color
        duration
        createdAt
        statusroomID
        StatusRoom {
          id
          month
          noStatuses
          createdAt
          updatedAt
          Statuses {
            nextToken
            startedAt
            __typename
          }
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        statusUserId
        statusPostId
        statusItemId
        __typename
      }
      Reply {
        id
        message
        replyingToUserID
        replyingToUsername
        createdAt
        comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        commentID
        userID
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comment {
        id
        message
        createdAt
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      Message {
        id
        type
        message
        image
        seen
        userID
        createdAt
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        chatroomID
        status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messagePostId
        messageStatusId
        messageItemId
        __typename
      }
      Item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      statusID
      userID
      postID
      replyID
      commentID
      messageID
      itemID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        seen
        response
        senderID
        Status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        Reply {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Message {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        statusID
        userID
        postID
        replyID
        commentID
        messageID
        itemID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncReports = /* GraphQL */ `
  query SyncReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        message
        seen
        response
        senderID
        Status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        Reply {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Message {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        statusID
        userID
        postID
        replyID
        commentID
        messageID
        itemID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reportsByStatusID = /* GraphQL */ `
  query ReportsByStatusID(
    $statusID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByStatusID(
      statusID: $statusID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        seen
        response
        senderID
        Status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        Reply {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Message {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        statusID
        userID
        postID
        replyID
        commentID
        messageID
        itemID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reportsByUserID = /* GraphQL */ `
  query ReportsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        seen
        response
        senderID
        Status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        Reply {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Message {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        statusID
        userID
        postID
        replyID
        commentID
        messageID
        itemID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reportsByPostID = /* GraphQL */ `
  query ReportsByPostID(
    $postID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByPostID(
      postID: $postID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        seen
        response
        senderID
        Status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        Reply {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Message {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        statusID
        userID
        postID
        replyID
        commentID
        messageID
        itemID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reportsByReplyID = /* GraphQL */ `
  query ReportsByReplyID(
    $replyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByReplyID(
      replyID: $replyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        seen
        response
        senderID
        Status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        Reply {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Message {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        statusID
        userID
        postID
        replyID
        commentID
        messageID
        itemID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reportsByCommentID = /* GraphQL */ `
  query ReportsByCommentID(
    $commentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByCommentID(
      commentID: $commentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        seen
        response
        senderID
        Status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        Reply {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Message {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        statusID
        userID
        postID
        replyID
        commentID
        messageID
        itemID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reportsByMessageID = /* GraphQL */ `
  query ReportsByMessageID(
    $messageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByMessageID(
      messageID: $messageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        seen
        response
        senderID
        Status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        Reply {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Message {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        statusID
        userID
        postID
        replyID
        commentID
        messageID
        itemID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reportsByItemID = /* GraphQL */ `
  query ReportsByItemID(
    $itemID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByItemID(
      itemID: $itemID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        seen
        response
        senderID
        Status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        Reply {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Message {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        statusID
        userID
        postID
        replyID
        commentID
        messageID
        itemID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVerification = /* GraphQL */ `
  query GetVerification($id: ID!) {
    getVerification(id: $id) {
      id
      idNumber
      proofOfId
      verified
      reason
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listVerifications = /* GraphQL */ `
  query ListVerifications(
    $filter: ModelVerificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVerifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        idNumber
        proofOfId
        verified
        reason
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVerifications = /* GraphQL */ `
  query SyncVerifications(
    $filter: ModelVerificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVerifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        idNumber
        proofOfId
        verified
        reason
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSettings = /* GraphQL */ `
  query GetSettings($id: ID!) {
    getSettings(id: $id) {
      id
      pushNotifications
      likesNotifications
      commentsNotifications
      followsNotifications
      allowMessagesFromAll
      allowCommentsFromAll
      allowMultipleSignIns
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pushNotifications
        likesNotifications
        commentsNotifications
        followsNotifications
        allowMessagesFromAll
        allowCommentsFromAll
        allowMultipleSignIns
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSettings = /* GraphQL */ `
  query SyncSettings(
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        pushNotifications
        likesNotifications
        commentsNotifications
        followsNotifications
        allowMessagesFromAll
        allowCommentsFromAll
        allowMultipleSignIns
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFollowing = /* GraphQL */ `
  query GetFollowing($id: ID!) {
    getFollowing(id: $id) {
      id
      userID
      userfollowingsID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFollowings = /* GraphQL */ `
  query ListFollowings(
    $filter: ModelFollowingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollowings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        userfollowingsID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFollowings = /* GraphQL */ `
  query SyncFollowings(
    $filter: ModelFollowingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFollowings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        userfollowingsID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const followingsByUserID = /* GraphQL */ `
  query FollowingsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFollowingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    followingsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        userfollowingsID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const followingsByUserfollowingsID = /* GraphQL */ `
  query FollowingsByUserfollowingsID(
    $userfollowingsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFollowingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    followingsByUserfollowingsID(
      userfollowingsID: $userfollowingsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        userfollowingsID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFollower = /* GraphQL */ `
  query GetFollower($id: ID!) {
    getFollower(id: $id) {
      id
      userID
      userfollowersID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFollowers = /* GraphQL */ `
  query ListFollowers(
    $filter: ModelFollowerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollowers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        userfollowersID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFollowers = /* GraphQL */ `
  query SyncFollowers(
    $filter: ModelFollowerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFollowers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        userfollowersID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const followersByUserID = /* GraphQL */ `
  query FollowersByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFollowerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    followersByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        userfollowersID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const followersByUserfollowersID = /* GraphQL */ `
  query FollowersByUserfollowersID(
    $userfollowersID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFollowerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    followersByUserfollowersID(
      userfollowersID: $userfollowersID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        userfollowersID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserFollowings = /* GraphQL */ `
  query GetUserFollowings($id: ID!) {
    getUserFollowings(id: $id) {
      id
      Followings {
        items {
          id
          userID
          userfollowingsID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUserFollowings = /* GraphQL */ `
  query ListUserFollowings(
    $filter: ModelUserFollowingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFollowings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserFollowings = /* GraphQL */ `
  query SyncUserFollowings(
    $filter: ModelUserFollowingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserFollowings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserFollowers = /* GraphQL */ `
  query GetUserFollowers($id: ID!) {
    getUserFollowers(id: $id) {
      id
      Followers {
        items {
          id
          userID
          userfollowersID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUserFollowers = /* GraphQL */ `
  query ListUserFollowers(
    $filter: ModelUserFollowersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFollowers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserFollowers = /* GraphQL */ `
  query SyncUserFollowers(
    $filter: ModelUserFollowersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserFollowers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      type
      message
      recipientID
      seen
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      Like {
        id
        userID
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        statusID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comment {
        id
        message
        createdAt
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Reply {
        id
        message
        replyingToUserID
        replyingToUsername
        createdAt
        comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        commentID
        userID
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      notificationLikeId
      notificationCommentId
      notificationReplyId
      notificationPostId
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        message
        recipientID
        seen
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Like {
          id
          userID
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reply {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        notificationLikeId
        notificationCommentId
        notificationReplyId
        notificationPostId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        message
        recipientID
        seen
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Like {
          id
          userID
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reply {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        notificationLikeId
        notificationCommentId
        notificationReplyId
        notificationPostId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const notificationsByUserID = /* GraphQL */ `
  query NotificationsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        message
        recipientID
        seen
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Like {
          id
          userID
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reply {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        notificationLikeId
        notificationCommentId
        notificationReplyId
        notificationPostId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getStatusRoom = /* GraphQL */ `
  query GetStatusRoom($id: ID!) {
    getStatusRoom(id: $id) {
      id
      month
      noStatuses
      createdAt
      updatedAt
      Statuses {
        items {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listStatusRooms = /* GraphQL */ `
  query ListStatusRooms(
    $filter: ModelStatusRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatusRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        month
        noStatuses
        createdAt
        updatedAt
        Statuses {
          items {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncStatusRooms = /* GraphQL */ `
  query SyncStatusRooms(
    $filter: ModelStatusRoomFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStatusRooms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        month
        noStatuses
        createdAt
        updatedAt
        Statuses {
          items {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listAllStatusRooms = /* GraphQL */ `
  query ListAllStatusRooms(
    $month: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStatusRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllStatusRooms(
      month: $month
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        month
        noStatuses
        createdAt
        updatedAt
        Statuses {
          items {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listStatusRoomsByUser = /* GraphQL */ `
  query ListStatusRoomsByUser(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStatusRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatusRoomsByUser(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        month
        noStatuses
        createdAt
        updatedAt
        Statuses {
          items {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getStatus = /* GraphQL */ `
  query GetStatus($id: ID!) {
    getStatus(id: $id) {
      id
      type
      expiryTime
      isDump
      url
      caption
      fontFamily
      alignCenter
      height
      width
      isMuted
      resizeMode
      color
      duration
      createdAt
      statusroomID
      StatusRoom {
        id
        month
        noStatuses
        createdAt
        updatedAt
        Statuses {
          items {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Likes {
        items {
          id
          userID
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Views {
        items {
          id
          userID
          statusID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      Post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      statusUserId
      statusPostId
      statusItemId
      __typename
    }
  }
`;
export const listStatuses = /* GraphQL */ `
  query ListStatuses(
    $filter: ModelStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        expiryTime
        isDump
        url
        caption
        fontFamily
        alignCenter
        height
        width
        isMuted
        resizeMode
        color
        duration
        createdAt
        statusroomID
        StatusRoom {
          id
          month
          noStatuses
          createdAt
          updatedAt
          Statuses {
            nextToken
            startedAt
            __typename
          }
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        statusUserId
        statusPostId
        statusItemId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncStatuses = /* GraphQL */ `
  query SyncStatuses(
    $filter: ModelStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        expiryTime
        isDump
        url
        caption
        fontFamily
        alignCenter
        height
        width
        isMuted
        resizeMode
        color
        duration
        createdAt
        statusroomID
        StatusRoom {
          id
          month
          noStatuses
          createdAt
          updatedAt
          Statuses {
            nextToken
            startedAt
            __typename
          }
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        statusUserId
        statusPostId
        statusItemId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listStatusesByStatusRoom = /* GraphQL */ `
  query ListStatusesByStatusRoom(
    $statusroomID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatusesByStatusRoom(
      statusroomID: $statusroomID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        expiryTime
        isDump
        url
        caption
        fontFamily
        alignCenter
        height
        width
        isMuted
        resizeMode
        color
        duration
        createdAt
        statusroomID
        StatusRoom {
          id
          month
          noStatuses
          createdAt
          updatedAt
          Statuses {
            nextToken
            startedAt
            __typename
          }
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        statusUserId
        statusPostId
        statusItemId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getReply = /* GraphQL */ `
  query GetReply($id: ID!) {
    getReply(id: $id) {
      id
      message
      replyingToUserID
      replyingToUsername
      createdAt
      comment {
        id
        message
        createdAt
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      commentID
      userID
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listReplies = /* GraphQL */ `
  query ListReplies(
    $filter: ModelReplyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReplies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        replyingToUserID
        replyingToUsername
        createdAt
        comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        commentID
        userID
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncReplies = /* GraphQL */ `
  query SyncReplies(
    $filter: ModelReplyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReplies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        message
        replyingToUserID
        replyingToUsername
        createdAt
        comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        commentID
        userID
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listRepliesByComment = /* GraphQL */ `
  query ListRepliesByComment(
    $commentID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReplyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRepliesByComment(
      commentID: $commentID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        replyingToUserID
        replyingToUsername
        createdAt
        comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        commentID
        userID
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const repliesByUserID = /* GraphQL */ `
  query RepliesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReplyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    repliesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        replyingToUserID
        replyingToUsername
        createdAt
        comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        commentID
        userID
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      message
      createdAt
      postID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      Replies {
        items {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        createdAt
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncComments = /* GraphQL */ `
  query SyncComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        message
        createdAt
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listCommentsByPost = /* GraphQL */ `
  query ListCommentsByPost(
    $postID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentsByPost(
      postID: $postID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        createdAt
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const commentsByUserID = /* GraphQL */ `
  query CommentsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        createdAt
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLike = /* GraphQL */ `
  query GetLike($id: ID!) {
    getLike(id: $id) {
      id
      userID
      postID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      statusID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLikes = /* GraphQL */ `
  query ListLikes(
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        statusID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLikes = /* GraphQL */ `
  query SyncLikes(
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLikes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        statusID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const likesByUserID = /* GraphQL */ `
  query LikesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        statusID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const likesByPostID = /* GraphQL */ `
  query LikesByPostID(
    $postID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByPostID(
      postID: $postID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        statusID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const likesByStatusID = /* GraphQL */ `
  query LikesByStatusID(
    $statusID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByStatusID(
      statusID: $statusID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        statusID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      category
      type
      title
      caption
      images
      muted
      isVideo
      Likes {
        items {
          id
          userID
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Comments {
        items {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPosts = /* GraphQL */ `
  query SyncPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPosts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const postsByUserID = /* GraphQL */ `
  query PostsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      username
      biography
      profile
      verified
      email
      deviceToken
      preferredContent
      recentSearches
      Posts {
        items {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Likes {
        items {
          id
          userID
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Comments {
        items {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Replies {
        items {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      StatusRooms {
        items {
          id
          month
          noStatuses
          createdAt
          updatedAt
          Statuses {
            nextToken
            startedAt
            __typename
          }
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Notifications {
        items {
          id
          type
          message
          recipientID
          seen
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Like {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          notificationLikeId
          notificationCommentId
          notificationReplyId
          notificationPostId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserFollowers {
        id
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      UserFollowings {
        id
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Followers {
        items {
          id
          userID
          userfollowersID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Followings {
        items {
          id
          userID
          userfollowingsID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Settings {
        id
        pushNotifications
        likesNotifications
        commentsNotifications
        followsNotifications
        allowMessagesFromAll
        allowCommentsFromAll
        allowMultipleSignIns
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Verification {
        id
        idNumber
        proofOfId
        verified
        reason
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BlockedUsers {
        id
        Users {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      BlockedByUsers {
        id
        Users {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Blockeds {
        items {
          id
          blockedByID
          blockedusersID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BlockedBies {
        items {
          id
          blockedbyusersID
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Messages {
        items {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ChatRooms {
        items {
          id
          chatRoomId
          userId
          chatRoom {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatRoomLastMessageId
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DeletedChatRooms {
        items {
          id
          chatRoomID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Views {
        items {
          id
          userID
          statusID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Shops {
        items {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cartItems {
        items {
          id
          quantity
          size
          color
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      wishListItems {
        items {
          id
          color
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          payed
          status
          deliveryFee
          delivered
          delieveredDate
          handedToCourier
          handedToCourierDate
          expectedDeliveryDate
          recipientName
          recipientPhoneNumber
          recipientAddress
          createdAt
          orderItems {
            nextToken
            startedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          address {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderAddressId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      addresses {
        items {
          id
          type
          company
          street
          suburb
          city
          province
          country
          lat
          lng
          name
          mobile
          email
          userID
          shopID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserFollowersId
      userUserFollowingsId
      userSettingsId
      userVerificationId
      userBlockedUsersId
      userBlockedByUsersId
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserChatRoom = /* GraphQL */ `
  query GetUserChatRoom($id: ID!) {
    getUserChatRoom(id: $id) {
      id
      chatRoomId
      userId
      chatRoom {
        id
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        LastMessage {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        users {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatRoomLastMessageId
        __typename
      }
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUserChatRooms = /* GraphQL */ `
  query ListUserChatRooms(
    $filter: ModelUserChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserChatRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        chatRoomId
        userId
        chatRoom {
          id
          Messages {
            nextToken
            startedAt
            __typename
          }
          LastMessage {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatRoomLastMessageId
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserChatRooms = /* GraphQL */ `
  query SyncUserChatRooms(
    $filter: ModelUserChatRoomFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserChatRooms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        chatRoomId
        userId
        chatRoom {
          id
          Messages {
            nextToken
            startedAt
            __typename
          }
          LastMessage {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatRoomLastMessageId
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userChatRoomsByChatRoomId = /* GraphQL */ `
  query UserChatRoomsByChatRoomId(
    $chatRoomId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userChatRoomsByChatRoomId(
      chatRoomId: $chatRoomId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatRoomId
        userId
        chatRoom {
          id
          Messages {
            nextToken
            startedAt
            __typename
          }
          LastMessage {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatRoomLastMessageId
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userChatRoomsByUserId = /* GraphQL */ `
  query UserChatRoomsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userChatRoomsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatRoomId
        userId
        chatRoom {
          id
          Messages {
            nextToken
            startedAt
            __typename
          }
          LastMessage {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          chatRoomLastMessageId
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
