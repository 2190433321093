import React from 'react';
import { MdOutlineAccountCircle, MdAlternateEmail, MdPassword } from 'react-icons/md';
import { BiUser, BiLockAlt } from 'react-icons/bi';

function CustomInput({icon, type, placeholder, value, setValue}) {

    const showIcon = () => {
        if (icon === 'account') {
            return <MdOutlineAccountCircle size={20} color='gray' />;
        } else if (icon === 'user') {
            return <BiUser size={18} color='gray' />;
        } else if (icon === 'email') {
            return <MdAlternateEmail size={20} color='gray' />
        } else if (icon === 'password') {
            return <BiLockAlt size={20} color='gray' />
        } else if (icon === 'otp') {
            return <MdPassword size={20} color='gray' />
        };
    };

    return (
        <div className='flex items-center border-2 border-gray-300 rounded-full mb-4 w-11/12 sm:w-5/6 md:w-3/4'>
            <div className='h-8 w-9 flex items-center justify-center border-r-2 border-r-gray-300'>
                {showIcon()}
            </div>
            <input type={type} placeholder={placeholder} value={value} onChange={(text) => setValue(text.target.value)} className='text-sm flex-grow h-8 px-1.5 rounded-r-full focus:outline-none' />
        </div>
    )
}

export default CustomInput
