import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MdVerified } from 'react-icons/md';

import { CredentialsContext } from '../../../components/CredentialsContext';

export default function Comment({post, comment}) {

    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);

    return (
        <div className='flex mb-4'>
            <Link to={storedCredentials?.id === comment?.userID ? '/MyProfile' : '/UserProfile'} state={{ data: comment?.user }}>
                <img src={comment?.user?.profile} className='w-8 h-8 rounded-full border-2 bg-gray-200' />
            </Link>
            <div className='ml-2'>
                <div className='flex items-center'>
                    <h1 className='text-sm font-semibold'>{comment?.user?.username}</h1>
                    <MdVerified size={11} className='ml-0.5 text-bubble_blue' />
                </div>
                <p className='text-sm'>{comment?.message}</p>
                <div className='flex items-center mt-1.5 gap-3'>
                    <Link to={'/Replies'} state={{ post: post, comment: comment }} className='link text-bubble_blue text-xs font-semibold'>
                        Reply
                    </Link>
                    {
                        comment?.Replies?.items?.length ?
                        <Link to={'/Replies'} state={{ post: post, comment: comment }} className='link text-gray-500 text-xs font-semibold'>
                            {comment?.Replies?.items?.length + (comment?.Replies?.items?.length > 1 ? " Replies" : " Reply")}
                        </Link>
                        :
                        <></>
                    }
                </div>
            </div>
        </div>
    );
};
