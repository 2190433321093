import React, { useState } from 'react';
import { MdVerified } from 'react-icons/md';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';

export default function Notification({notification}) {

    const [isFollowing, setIsFollowing] = useState(false);

    const message = () => {
        if (notification?.type === "FOLLOW") {
            return "Started following you.";
        }else if (notification?.type === "UNFOLLOW") {
            return "Unfollowed you.";
        } else if (notification?.type === "POSTLIKE") {
            return "Liked your post";
        } else if (notification?.type === "POSTCOMMENT") {
            return "Commented on your post";
        } else if (notification?.type === "COMMENTREPLY") {
            return "Mentioned you in their reply";
        } else if (notification?.type === "POSTSHARE") {
            return "Shared your post on their status";
        };
    };

    const notificationMessage = () => {
        if (notification?.type === "POSTLIKE") {
            if (notification?.post?.caption) {
                return ": " + notification?.post?.caption;
            } else {
                return "";
            }
        } else if (notification?.type === "POSTCOMMENT") {
            return ": " + notification?.message;
        } else if (notification?.type === "COMMENTREPLY") {
            return ": " + notification?.message;
        } else if (notification?.type === "POSTSHARE") {
            if (notification?.post?.caption) {
                return ": " + notification?.post?.caption;
            } else {
                return "";
            }
        } else {
            return "";
        };
    };

    const follow = async () => {
        try {
            if (isFollowing) {

            } else {

            };

            setIsFollowing(!isFollowing);
        } catch (error) {
            console.warn(error?.message);
        };
    };

    return (
        <div className='flex w-full max-w-2xl p-2.5 md:border-2 rounded-xl mb-2'>
            <Link to={'/UserProfile'} state={{ data: notification?.user }}>
                <img src={notification?.user?.profile} className='w-10 h-10 rounded-full object-cover border-2 bg-gray-200 pointer-events-none' />
            </Link>
            <div className='ml-2 w-full'>
                <div className='flex items-center'>
                    <Link to={'/UserProfile'} state={{ data: notification?.user }} className='link'>
                        <h1 className='text-base font-semibold'>{notification?.user?.username}</h1>
                    </Link>
                    <MdVerified size={11} className='ml-0.5 text-bubble_blue' />
                    <h2 className='ml-1.5 text-gray-500 text-xs'>{moment(notification?.createdAt).fromNow()}</h2>
                </div>
                <p className='text-black text-sm line-clamp-2'>{message()}<span className='text-gray-500'>{notificationMessage()}</span></p>
            </div>
            {
                notification?.post?.type === "CONTENT" ?
                <div className='ml-2'>
                    {
                        notification?.post?.isVideo ?
                        <Link to={'/Post'} state={{ data: notification?.post }}>
                            <video src={notification?.post?.images[0]} className='w-12 h-12 aspect-square rounded-md object-cover border-2 bg-gray-200 pointer-events-none' />
                        </Link>
                        :
                        <Link to={'/Post'} state={{ data: notification?.post }}>
                            <img src={notification?.post?.images[0]} className='w-12 h-12 aspect-square rounded-md object-cover border-2 bg-gray-200 pointer-events-none' />
                        </Link>
                    }
                </div>
                :
                <></>
            }
            {
                notification?.type === "FOLLOW" && !isFollowing ?
                <button onClick={() => follow()} className='ml-2 bg-bubble_blue h-8 px-3 text-sm text-white font-semibold rounded-full hover:opacity-90'>Follow</button>
                :
                <></>
            }
                        {
                notification?.type === "UNFOLLOW" && isFollowing ?
                <button onClick={() => follow()} className='ml-2 bg-gray-300 h-8 px-3 text-sm text-black font-semibold rounded-full hover:opacity-90'>Unfollow</button>
                :
                <></>
            }
        </div>
    );
};
