/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAddress = /* GraphQL */ `
  mutation CreateAddress(
    $input: CreateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    createAddress(input: $input, condition: $condition) {
      id
      type
      company
      street
      suburb
      city
      province
      country
      lat
      lng
      name
      mobile
      email
      userID
      shopID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress(
    $input: UpdateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    updateAddress(input: $input, condition: $condition) {
      id
      type
      company
      street
      suburb
      city
      province
      country
      lat
      lng
      name
      mobile
      email
      userID
      shopID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress(
    $input: DeleteAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    deleteAddress(input: $input, condition: $condition) {
      id
      type
      company
      street
      suburb
      city
      province
      country
      lat
      lng
      name
      mobile
      email
      userID
      shopID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createOrderItem = /* GraphQL */ `
  mutation CreateOrderItem(
    $input: CreateOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    createOrderItem(input: $input, condition: $condition) {
      id
      amount
      quantity
      size
      color
      orderID
      order {
        id
        payed
        status
        deliveryFee
        delivered
        delieveredDate
        handedToCourier
        handedToCourierDate
        expectedDeliveryDate
        recipientName
        recipientPhoneNumber
        recipientAddress
        createdAt
        orderItems {
          items {
            id
            amount
            quantity
            size
            color
            orderID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderItemItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        address {
          id
          type
          company
          street
          suburb
          city
          province
          country
          lat
          lng
          name
          mobile
          email
          userID
          shopID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderAddressId
        __typename
      }
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderItemItemId
      __typename
    }
  }
`;
export const updateOrderItem = /* GraphQL */ `
  mutation UpdateOrderItem(
    $input: UpdateOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    updateOrderItem(input: $input, condition: $condition) {
      id
      amount
      quantity
      size
      color
      orderID
      order {
        id
        payed
        status
        deliveryFee
        delivered
        delieveredDate
        handedToCourier
        handedToCourierDate
        expectedDeliveryDate
        recipientName
        recipientPhoneNumber
        recipientAddress
        createdAt
        orderItems {
          items {
            id
            amount
            quantity
            size
            color
            orderID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderItemItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        address {
          id
          type
          company
          street
          suburb
          city
          province
          country
          lat
          lng
          name
          mobile
          email
          userID
          shopID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderAddressId
        __typename
      }
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderItemItemId
      __typename
    }
  }
`;
export const deleteOrderItem = /* GraphQL */ `
  mutation DeleteOrderItem(
    $input: DeleteOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    deleteOrderItem(input: $input, condition: $condition) {
      id
      amount
      quantity
      size
      color
      orderID
      order {
        id
        payed
        status
        deliveryFee
        delivered
        delieveredDate
        handedToCourier
        handedToCourierDate
        expectedDeliveryDate
        recipientName
        recipientPhoneNumber
        recipientAddress
        createdAt
        orderItems {
          items {
            id
            amount
            quantity
            size
            color
            orderID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderItemItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        address {
          id
          type
          company
          street
          suburb
          city
          province
          country
          lat
          lng
          name
          mobile
          email
          userID
          shopID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderAddressId
        __typename
      }
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderItemItemId
      __typename
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      payed
      status
      deliveryFee
      delivered
      delieveredDate
      handedToCourier
      handedToCourierDate
      expectedDeliveryDate
      recipientName
      recipientPhoneNumber
      recipientAddress
      createdAt
      orderItems {
        items {
          id
          amount
          quantity
          size
          color
          orderID
          order {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderItemItemId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      shopID
      shop {
        id
        name
        logo
        about
        emailAddress
        phoneNumber
        province
        address
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        items {
          items {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      address {
        id
        type
        company
        street
        suburb
        city
        province
        country
        lat
        lng
        name
        mobile
        email
        userID
        shopID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderAddressId
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      payed
      status
      deliveryFee
      delivered
      delieveredDate
      handedToCourier
      handedToCourierDate
      expectedDeliveryDate
      recipientName
      recipientPhoneNumber
      recipientAddress
      createdAt
      orderItems {
        items {
          id
          amount
          quantity
          size
          color
          orderID
          order {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderItemItemId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      shopID
      shop {
        id
        name
        logo
        about
        emailAddress
        phoneNumber
        province
        address
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        items {
          items {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      address {
        id
        type
        company
        street
        suburb
        city
        province
        country
        lat
        lng
        name
        mobile
        email
        userID
        shopID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderAddressId
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      payed
      status
      deliveryFee
      delivered
      delieveredDate
      handedToCourier
      handedToCourierDate
      expectedDeliveryDate
      recipientName
      recipientPhoneNumber
      recipientAddress
      createdAt
      orderItems {
        items {
          id
          amount
          quantity
          size
          color
          orderID
          order {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderItemItemId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      shopID
      shop {
        id
        name
        logo
        about
        emailAddress
        phoneNumber
        province
        address
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        items {
          items {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      address {
        id
        type
        company
        street
        suburb
        city
        province
        country
        lat
        lng
        name
        mobile
        email
        userID
        shopID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderAddressId
      __typename
    }
  }
`;
export const createWishListItem = /* GraphQL */ `
  mutation CreateWishListItem(
    $input: CreateWishListItemInput!
    $condition: ModelWishListItemConditionInput
  ) {
    createWishListItem(input: $input, condition: $condition) {
      id
      color
      itemID
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateWishListItem = /* GraphQL */ `
  mutation UpdateWishListItem(
    $input: UpdateWishListItemInput!
    $condition: ModelWishListItemConditionInput
  ) {
    updateWishListItem(input: $input, condition: $condition) {
      id
      color
      itemID
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteWishListItem = /* GraphQL */ `
  mutation DeleteWishListItem(
    $input: DeleteWishListItemInput!
    $condition: ModelWishListItemConditionInput
  ) {
    deleteWishListItem(input: $input, condition: $condition) {
      id
      color
      itemID
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCartItem = /* GraphQL */ `
  mutation CreateCartItem(
    $input: CreateCartItemInput!
    $condition: ModelCartItemConditionInput
  ) {
    createCartItem(input: $input, condition: $condition) {
      id
      quantity
      size
      color
      itemID
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCartItem = /* GraphQL */ `
  mutation UpdateCartItem(
    $input: UpdateCartItemInput!
    $condition: ModelCartItemConditionInput
  ) {
    updateCartItem(input: $input, condition: $condition) {
      id
      quantity
      size
      color
      itemID
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCartItem = /* GraphQL */ `
  mutation DeleteCartItem(
    $input: DeleteCartItemInput!
    $condition: ModelCartItemConditionInput
  ) {
    deleteCartItem(input: $input, condition: $condition) {
      id
      quantity
      size
      color
      itemID
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSize = /* GraphQL */ `
  mutation CreateSize(
    $input: CreateSizeInput!
    $condition: ModelSizeConditionInput
  ) {
    createSize(input: $input, condition: $condition) {
      id
      index
      size
      quantity
      colorID
      color {
        id
        color
        images
        itemID
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sizes {
          items {
            id
            index
            size
            quantity
            colorID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSize = /* GraphQL */ `
  mutation UpdateSize(
    $input: UpdateSizeInput!
    $condition: ModelSizeConditionInput
  ) {
    updateSize(input: $input, condition: $condition) {
      id
      index
      size
      quantity
      colorID
      color {
        id
        color
        images
        itemID
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sizes {
          items {
            id
            index
            size
            quantity
            colorID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSize = /* GraphQL */ `
  mutation DeleteSize(
    $input: DeleteSizeInput!
    $condition: ModelSizeConditionInput
  ) {
    deleteSize(input: $input, condition: $condition) {
      id
      index
      size
      quantity
      colorID
      color {
        id
        color
        images
        itemID
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sizes {
          items {
            id
            index
            size
            quantity
            colorID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createColor = /* GraphQL */ `
  mutation CreateColor(
    $input: CreateColorInput!
    $condition: ModelColorConditionInput
  ) {
    createColor(input: $input, condition: $condition) {
      id
      color
      images
      itemID
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      sizes {
        items {
          id
          index
          size
          quantity
          colorID
          color {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateColor = /* GraphQL */ `
  mutation UpdateColor(
    $input: UpdateColorInput!
    $condition: ModelColorConditionInput
  ) {
    updateColor(input: $input, condition: $condition) {
      id
      color
      images
      itemID
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      sizes {
        items {
          id
          index
          size
          quantity
          colorID
          color {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteColor = /* GraphQL */ `
  mutation DeleteColor(
    $input: DeleteColorInput!
    $condition: ModelColorConditionInput
  ) {
    deleteColor(input: $input, condition: $condition) {
      id
      color
      images
      itemID
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      sizes {
        items {
          id
          index
          size
          quantity
          colorID
          color {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      name
      description
      brand
      category
      gender
      age
      condition
      price
      wasPrice
      colors {
        items {
          id
          color
          images
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          sizes {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cartItems {
        items {
          id
          quantity
          size
          color
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      wishListItems {
        items {
          id
          color
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      shopID
      shop {
        id
        name
        logo
        about
        emailAddress
        phoneNumber
        province
        address
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        items {
          items {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      name
      description
      brand
      category
      gender
      age
      condition
      price
      wasPrice
      colors {
        items {
          id
          color
          images
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          sizes {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cartItems {
        items {
          id
          quantity
          size
          color
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      wishListItems {
        items {
          id
          color
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      shopID
      shop {
        id
        name
        logo
        about
        emailAddress
        phoneNumber
        province
        address
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        items {
          items {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      name
      description
      brand
      category
      gender
      age
      condition
      price
      wasPrice
      colors {
        items {
          id
          color
          images
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          sizes {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cartItems {
        items {
          id
          quantity
          size
          color
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      wishListItems {
        items {
          id
          color
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      shopID
      shop {
        id
        name
        logo
        about
        emailAddress
        phoneNumber
        province
        address
        userID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        items {
          items {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createShop = /* GraphQL */ `
  mutation CreateShop(
    $input: CreateShopInput!
    $condition: ModelShopConditionInput
  ) {
    createShop(input: $input, condition: $condition) {
      id
      name
      logo
      about
      emailAddress
      phoneNumber
      province
      address
      userID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      items {
        items {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          payed
          status
          deliveryFee
          delivered
          delieveredDate
          handedToCourier
          handedToCourierDate
          expectedDeliveryDate
          recipientName
          recipientPhoneNumber
          recipientAddress
          createdAt
          orderItems {
            nextToken
            startedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          address {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderAddressId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      addresses {
        items {
          id
          type
          company
          street
          suburb
          city
          province
          country
          lat
          lng
          name
          mobile
          email
          userID
          shopID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateShop = /* GraphQL */ `
  mutation UpdateShop(
    $input: UpdateShopInput!
    $condition: ModelShopConditionInput
  ) {
    updateShop(input: $input, condition: $condition) {
      id
      name
      logo
      about
      emailAddress
      phoneNumber
      province
      address
      userID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      items {
        items {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          payed
          status
          deliveryFee
          delivered
          delieveredDate
          handedToCourier
          handedToCourierDate
          expectedDeliveryDate
          recipientName
          recipientPhoneNumber
          recipientAddress
          createdAt
          orderItems {
            nextToken
            startedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          address {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderAddressId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      addresses {
        items {
          id
          type
          company
          street
          suburb
          city
          province
          country
          lat
          lng
          name
          mobile
          email
          userID
          shopID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteShop = /* GraphQL */ `
  mutation DeleteShop(
    $input: DeleteShopInput!
    $condition: ModelShopConditionInput
  ) {
    deleteShop(input: $input, condition: $condition) {
      id
      name
      logo
      about
      emailAddress
      phoneNumber
      province
      address
      userID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      items {
        items {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          payed
          status
          deliveryFee
          delivered
          delieveredDate
          handedToCourier
          handedToCourierDate
          expectedDeliveryDate
          recipientName
          recipientPhoneNumber
          recipientAddress
          createdAt
          orderItems {
            nextToken
            startedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          address {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderAddressId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      addresses {
        items {
          id
          type
          company
          street
          suburb
          city
          province
          country
          lat
          lng
          name
          mobile
          email
          userID
          shopID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createView = /* GraphQL */ `
  mutation CreateView(
    $input: CreateViewInput!
    $condition: ModelViewConditionInput
  ) {
    createView(input: $input, condition: $condition) {
      id
      userID
      statusID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateView = /* GraphQL */ `
  mutation UpdateView(
    $input: UpdateViewInput!
    $condition: ModelViewConditionInput
  ) {
    updateView(input: $input, condition: $condition) {
      id
      userID
      statusID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteView = /* GraphQL */ `
  mutation DeleteView(
    $input: DeleteViewInput!
    $condition: ModelViewConditionInput
  ) {
    deleteView(input: $input, condition: $condition) {
      id
      userID
      statusID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDeletedChatRoom = /* GraphQL */ `
  mutation CreateDeletedChatRoom(
    $input: CreateDeletedChatRoomInput!
    $condition: ModelDeletedChatRoomConditionInput
  ) {
    createDeletedChatRoom(input: $input, condition: $condition) {
      id
      chatRoomID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDeletedChatRoom = /* GraphQL */ `
  mutation UpdateDeletedChatRoom(
    $input: UpdateDeletedChatRoomInput!
    $condition: ModelDeletedChatRoomConditionInput
  ) {
    updateDeletedChatRoom(input: $input, condition: $condition) {
      id
      chatRoomID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDeletedChatRoom = /* GraphQL */ `
  mutation DeleteDeletedChatRoom(
    $input: DeleteDeletedChatRoomInput!
    $condition: ModelDeletedChatRoomConditionInput
  ) {
    deleteDeletedChatRoom(input: $input, condition: $condition) {
      id
      chatRoomID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      type
      message
      image
      seen
      userID
      createdAt
      post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      chatroomID
      status {
        id
        type
        expiryTime
        isDump
        url
        caption
        fontFamily
        alignCenter
        height
        width
        isMuted
        resizeMode
        color
        duration
        createdAt
        statusroomID
        StatusRoom {
          id
          month
          noStatuses
          createdAt
          updatedAt
          Statuses {
            nextToken
            startedAt
            __typename
          }
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        statusUserId
        statusPostId
        statusItemId
        __typename
      }
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      messagePostId
      messageStatusId
      messageItemId
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      type
      message
      image
      seen
      userID
      createdAt
      post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      chatroomID
      status {
        id
        type
        expiryTime
        isDump
        url
        caption
        fontFamily
        alignCenter
        height
        width
        isMuted
        resizeMode
        color
        duration
        createdAt
        statusroomID
        StatusRoom {
          id
          month
          noStatuses
          createdAt
          updatedAt
          Statuses {
            nextToken
            startedAt
            __typename
          }
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        statusUserId
        statusPostId
        statusItemId
        __typename
      }
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      messagePostId
      messageStatusId
      messageItemId
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      type
      message
      image
      seen
      userID
      createdAt
      post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      chatroomID
      status {
        id
        type
        expiryTime
        isDump
        url
        caption
        fontFamily
        alignCenter
        height
        width
        isMuted
        resizeMode
        color
        duration
        createdAt
        statusroomID
        StatusRoom {
          id
          month
          noStatuses
          createdAt
          updatedAt
          Statuses {
            nextToken
            startedAt
            __typename
          }
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        statusUserId
        statusPostId
        statusItemId
        __typename
      }
      item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      messagePostId
      messageStatusId
      messageItemId
      __typename
    }
  }
`;
export const createChatRoom = /* GraphQL */ `
  mutation CreateChatRoom(
    $input: CreateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    createChatRoom(input: $input, condition: $condition) {
      id
      Messages {
        items {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      LastMessage {
        id
        type
        message
        image
        seen
        userID
        createdAt
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        chatroomID
        status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messagePostId
        messageStatusId
        messageItemId
        __typename
      }
      users {
        items {
          id
          chatRoomId
          userId
          chatRoom {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatRoomLastMessageId
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatRoomLastMessageId
      __typename
    }
  }
`;
export const updateChatRoom = /* GraphQL */ `
  mutation UpdateChatRoom(
    $input: UpdateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    updateChatRoom(input: $input, condition: $condition) {
      id
      Messages {
        items {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      LastMessage {
        id
        type
        message
        image
        seen
        userID
        createdAt
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        chatroomID
        status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messagePostId
        messageStatusId
        messageItemId
        __typename
      }
      users {
        items {
          id
          chatRoomId
          userId
          chatRoom {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatRoomLastMessageId
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatRoomLastMessageId
      __typename
    }
  }
`;
export const deleteChatRoom = /* GraphQL */ `
  mutation DeleteChatRoom(
    $input: DeleteChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    deleteChatRoom(input: $input, condition: $condition) {
      id
      Messages {
        items {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      LastMessage {
        id
        type
        message
        image
        seen
        userID
        createdAt
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        chatroomID
        status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messagePostId
        messageStatusId
        messageItemId
        __typename
      }
      users {
        items {
          id
          chatRoomId
          userId
          chatRoom {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatRoomLastMessageId
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatRoomLastMessageId
      __typename
    }
  }
`;
export const createBlockedBy = /* GraphQL */ `
  mutation CreateBlockedBy(
    $input: CreateBlockedByInput!
    $condition: ModelBlockedByConditionInput
  ) {
    createBlockedBy(input: $input, condition: $condition) {
      id
      blockedbyusersID
      userID
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBlockedBy = /* GraphQL */ `
  mutation UpdateBlockedBy(
    $input: UpdateBlockedByInput!
    $condition: ModelBlockedByConditionInput
  ) {
    updateBlockedBy(input: $input, condition: $condition) {
      id
      blockedbyusersID
      userID
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBlockedBy = /* GraphQL */ `
  mutation DeleteBlockedBy(
    $input: DeleteBlockedByInput!
    $condition: ModelBlockedByConditionInput
  ) {
    deleteBlockedBy(input: $input, condition: $condition) {
      id
      blockedbyusersID
      userID
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBlocked = /* GraphQL */ `
  mutation CreateBlocked(
    $input: CreateBlockedInput!
    $condition: ModelBlockedConditionInput
  ) {
    createBlocked(input: $input, condition: $condition) {
      id
      blockedByID
      blockedusersID
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBlocked = /* GraphQL */ `
  mutation UpdateBlocked(
    $input: UpdateBlockedInput!
    $condition: ModelBlockedConditionInput
  ) {
    updateBlocked(input: $input, condition: $condition) {
      id
      blockedByID
      blockedusersID
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBlocked = /* GraphQL */ `
  mutation DeleteBlocked(
    $input: DeleteBlockedInput!
    $condition: ModelBlockedConditionInput
  ) {
    deleteBlocked(input: $input, condition: $condition) {
      id
      blockedByID
      blockedusersID
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBlockedByUsers = /* GraphQL */ `
  mutation CreateBlockedByUsers(
    $input: CreateBlockedByUsersInput!
    $condition: ModelBlockedByUsersConditionInput
  ) {
    createBlockedByUsers(input: $input, condition: $condition) {
      id
      Users {
        items {
          id
          blockedbyusersID
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBlockedByUsers = /* GraphQL */ `
  mutation UpdateBlockedByUsers(
    $input: UpdateBlockedByUsersInput!
    $condition: ModelBlockedByUsersConditionInput
  ) {
    updateBlockedByUsers(input: $input, condition: $condition) {
      id
      Users {
        items {
          id
          blockedbyusersID
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBlockedByUsers = /* GraphQL */ `
  mutation DeleteBlockedByUsers(
    $input: DeleteBlockedByUsersInput!
    $condition: ModelBlockedByUsersConditionInput
  ) {
    deleteBlockedByUsers(input: $input, condition: $condition) {
      id
      Users {
        items {
          id
          blockedbyusersID
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBlockedUsers = /* GraphQL */ `
  mutation CreateBlockedUsers(
    $input: CreateBlockedUsersInput!
    $condition: ModelBlockedUsersConditionInput
  ) {
    createBlockedUsers(input: $input, condition: $condition) {
      id
      Users {
        items {
          id
          blockedByID
          blockedusersID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBlockedUsers = /* GraphQL */ `
  mutation UpdateBlockedUsers(
    $input: UpdateBlockedUsersInput!
    $condition: ModelBlockedUsersConditionInput
  ) {
    updateBlockedUsers(input: $input, condition: $condition) {
      id
      Users {
        items {
          id
          blockedByID
          blockedusersID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBlockedUsers = /* GraphQL */ `
  mutation DeleteBlockedUsers(
    $input: DeleteBlockedUsersInput!
    $condition: ModelBlockedUsersConditionInput
  ) {
    deleteBlockedUsers(input: $input, condition: $condition) {
      id
      Users {
        items {
          id
          blockedByID
          blockedusersID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      message
      seen
      response
      senderID
      Status {
        id
        type
        expiryTime
        isDump
        url
        caption
        fontFamily
        alignCenter
        height
        width
        isMuted
        resizeMode
        color
        duration
        createdAt
        statusroomID
        StatusRoom {
          id
          month
          noStatuses
          createdAt
          updatedAt
          Statuses {
            nextToken
            startedAt
            __typename
          }
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        statusUserId
        statusPostId
        statusItemId
        __typename
      }
      Reply {
        id
        message
        replyingToUserID
        replyingToUsername
        createdAt
        comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        commentID
        userID
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comment {
        id
        message
        createdAt
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      Message {
        id
        type
        message
        image
        seen
        userID
        createdAt
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        chatroomID
        status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messagePostId
        messageStatusId
        messageItemId
        __typename
      }
      Item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      statusID
      userID
      postID
      replyID
      commentID
      messageID
      itemID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      message
      seen
      response
      senderID
      Status {
        id
        type
        expiryTime
        isDump
        url
        caption
        fontFamily
        alignCenter
        height
        width
        isMuted
        resizeMode
        color
        duration
        createdAt
        statusroomID
        StatusRoom {
          id
          month
          noStatuses
          createdAt
          updatedAt
          Statuses {
            nextToken
            startedAt
            __typename
          }
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        statusUserId
        statusPostId
        statusItemId
        __typename
      }
      Reply {
        id
        message
        replyingToUserID
        replyingToUsername
        createdAt
        comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        commentID
        userID
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comment {
        id
        message
        createdAt
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      Message {
        id
        type
        message
        image
        seen
        userID
        createdAt
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        chatroomID
        status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messagePostId
        messageStatusId
        messageItemId
        __typename
      }
      Item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      statusID
      userID
      postID
      replyID
      commentID
      messageID
      itemID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      message
      seen
      response
      senderID
      Status {
        id
        type
        expiryTime
        isDump
        url
        caption
        fontFamily
        alignCenter
        height
        width
        isMuted
        resizeMode
        color
        duration
        createdAt
        statusroomID
        StatusRoom {
          id
          month
          noStatuses
          createdAt
          updatedAt
          Statuses {
            nextToken
            startedAt
            __typename
          }
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        statusUserId
        statusPostId
        statusItemId
        __typename
      }
      Reply {
        id
        message
        replyingToUserID
        replyingToUsername
        createdAt
        comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        commentID
        userID
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comment {
        id
        message
        createdAt
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      Message {
        id
        type
        message
        image
        seen
        userID
        createdAt
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        chatroomID
        status {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        item {
          id
          name
          description
          brand
          category
          gender
          age
          condition
          price
          wasPrice
          colors {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messagePostId
        messageStatusId
        messageItemId
        __typename
      }
      Item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      statusID
      userID
      postID
      replyID
      commentID
      messageID
      itemID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createVerification = /* GraphQL */ `
  mutation CreateVerification(
    $input: CreateVerificationInput!
    $condition: ModelVerificationConditionInput
  ) {
    createVerification(input: $input, condition: $condition) {
      id
      idNumber
      proofOfId
      verified
      reason
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateVerification = /* GraphQL */ `
  mutation UpdateVerification(
    $input: UpdateVerificationInput!
    $condition: ModelVerificationConditionInput
  ) {
    updateVerification(input: $input, condition: $condition) {
      id
      idNumber
      proofOfId
      verified
      reason
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteVerification = /* GraphQL */ `
  mutation DeleteVerification(
    $input: DeleteVerificationInput!
    $condition: ModelVerificationConditionInput
  ) {
    deleteVerification(input: $input, condition: $condition) {
      id
      idNumber
      proofOfId
      verified
      reason
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSettings = /* GraphQL */ `
  mutation CreateSettings(
    $input: CreateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    createSettings(input: $input, condition: $condition) {
      id
      pushNotifications
      likesNotifications
      commentsNotifications
      followsNotifications
      allowMessagesFromAll
      allowCommentsFromAll
      allowMultipleSignIns
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSettings = /* GraphQL */ `
  mutation UpdateSettings(
    $input: UpdateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    updateSettings(input: $input, condition: $condition) {
      id
      pushNotifications
      likesNotifications
      commentsNotifications
      followsNotifications
      allowMessagesFromAll
      allowCommentsFromAll
      allowMultipleSignIns
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSettings = /* GraphQL */ `
  mutation DeleteSettings(
    $input: DeleteSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    deleteSettings(input: $input, condition: $condition) {
      id
      pushNotifications
      likesNotifications
      commentsNotifications
      followsNotifications
      allowMessagesFromAll
      allowCommentsFromAll
      allowMultipleSignIns
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createFollowing = /* GraphQL */ `
  mutation CreateFollowing(
    $input: CreateFollowingInput!
    $condition: ModelFollowingConditionInput
  ) {
    createFollowing(input: $input, condition: $condition) {
      id
      userID
      userfollowingsID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateFollowing = /* GraphQL */ `
  mutation UpdateFollowing(
    $input: UpdateFollowingInput!
    $condition: ModelFollowingConditionInput
  ) {
    updateFollowing(input: $input, condition: $condition) {
      id
      userID
      userfollowingsID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteFollowing = /* GraphQL */ `
  mutation DeleteFollowing(
    $input: DeleteFollowingInput!
    $condition: ModelFollowingConditionInput
  ) {
    deleteFollowing(input: $input, condition: $condition) {
      id
      userID
      userfollowingsID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createFollower = /* GraphQL */ `
  mutation CreateFollower(
    $input: CreateFollowerInput!
    $condition: ModelFollowerConditionInput
  ) {
    createFollower(input: $input, condition: $condition) {
      id
      userID
      userfollowersID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateFollower = /* GraphQL */ `
  mutation UpdateFollower(
    $input: UpdateFollowerInput!
    $condition: ModelFollowerConditionInput
  ) {
    updateFollower(input: $input, condition: $condition) {
      id
      userID
      userfollowersID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteFollower = /* GraphQL */ `
  mutation DeleteFollower(
    $input: DeleteFollowerInput!
    $condition: ModelFollowerConditionInput
  ) {
    deleteFollower(input: $input, condition: $condition) {
      id
      userID
      userfollowersID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUserFollowings = /* GraphQL */ `
  mutation CreateUserFollowings(
    $input: CreateUserFollowingsInput!
    $condition: ModelUserFollowingsConditionInput
  ) {
    createUserFollowings(input: $input, condition: $condition) {
      id
      Followings {
        items {
          id
          userID
          userfollowingsID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUserFollowings = /* GraphQL */ `
  mutation UpdateUserFollowings(
    $input: UpdateUserFollowingsInput!
    $condition: ModelUserFollowingsConditionInput
  ) {
    updateUserFollowings(input: $input, condition: $condition) {
      id
      Followings {
        items {
          id
          userID
          userfollowingsID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUserFollowings = /* GraphQL */ `
  mutation DeleteUserFollowings(
    $input: DeleteUserFollowingsInput!
    $condition: ModelUserFollowingsConditionInput
  ) {
    deleteUserFollowings(input: $input, condition: $condition) {
      id
      Followings {
        items {
          id
          userID
          userfollowingsID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUserFollowers = /* GraphQL */ `
  mutation CreateUserFollowers(
    $input: CreateUserFollowersInput!
    $condition: ModelUserFollowersConditionInput
  ) {
    createUserFollowers(input: $input, condition: $condition) {
      id
      Followers {
        items {
          id
          userID
          userfollowersID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUserFollowers = /* GraphQL */ `
  mutation UpdateUserFollowers(
    $input: UpdateUserFollowersInput!
    $condition: ModelUserFollowersConditionInput
  ) {
    updateUserFollowers(input: $input, condition: $condition) {
      id
      Followers {
        items {
          id
          userID
          userfollowersID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUserFollowers = /* GraphQL */ `
  mutation DeleteUserFollowers(
    $input: DeleteUserFollowersInput!
    $condition: ModelUserFollowersConditionInput
  ) {
    deleteUserFollowers(input: $input, condition: $condition) {
      id
      Followers {
        items {
          id
          userID
          userfollowersID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      type
      message
      recipientID
      seen
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      Like {
        id
        userID
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        statusID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comment {
        id
        message
        createdAt
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Reply {
        id
        message
        replyingToUserID
        replyingToUsername
        createdAt
        comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        commentID
        userID
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      notificationLikeId
      notificationCommentId
      notificationReplyId
      notificationPostId
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      type
      message
      recipientID
      seen
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      Like {
        id
        userID
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        statusID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comment {
        id
        message
        createdAt
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Reply {
        id
        message
        replyingToUserID
        replyingToUsername
        createdAt
        comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        commentID
        userID
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      notificationLikeId
      notificationCommentId
      notificationReplyId
      notificationPostId
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      type
      message
      recipientID
      seen
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      Like {
        id
        userID
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        statusID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Comment {
        id
        message
        createdAt
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Reply {
        id
        message
        replyingToUserID
        replyingToUsername
        createdAt
        comment {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        commentID
        userID
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      notificationLikeId
      notificationCommentId
      notificationReplyId
      notificationPostId
      __typename
    }
  }
`;
export const createStatusRoom = /* GraphQL */ `
  mutation CreateStatusRoom(
    $input: CreateStatusRoomInput!
    $condition: ModelStatusRoomConditionInput
  ) {
    createStatusRoom(input: $input, condition: $condition) {
      id
      month
      noStatuses
      createdAt
      updatedAt
      Statuses {
        items {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateStatusRoom = /* GraphQL */ `
  mutation UpdateStatusRoom(
    $input: UpdateStatusRoomInput!
    $condition: ModelStatusRoomConditionInput
  ) {
    updateStatusRoom(input: $input, condition: $condition) {
      id
      month
      noStatuses
      createdAt
      updatedAt
      Statuses {
        items {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteStatusRoom = /* GraphQL */ `
  mutation DeleteStatusRoom(
    $input: DeleteStatusRoomInput!
    $condition: ModelStatusRoomConditionInput
  ) {
    deleteStatusRoom(input: $input, condition: $condition) {
      id
      month
      noStatuses
      createdAt
      updatedAt
      Statuses {
        items {
          id
          type
          expiryTime
          isDump
          url
          caption
          fontFamily
          alignCenter
          height
          width
          isMuted
          resizeMode
          color
          duration
          createdAt
          statusroomID
          StatusRoom {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          statusUserId
          statusPostId
          statusItemId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createStatus = /* GraphQL */ `
  mutation CreateStatus(
    $input: CreateStatusInput!
    $condition: ModelStatusConditionInput
  ) {
    createStatus(input: $input, condition: $condition) {
      id
      type
      expiryTime
      isDump
      url
      caption
      fontFamily
      alignCenter
      height
      width
      isMuted
      resizeMode
      color
      duration
      createdAt
      statusroomID
      StatusRoom {
        id
        month
        noStatuses
        createdAt
        updatedAt
        Statuses {
          items {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Likes {
        items {
          id
          userID
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Views {
        items {
          id
          userID
          statusID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      Post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      statusUserId
      statusPostId
      statusItemId
      __typename
    }
  }
`;
export const updateStatus = /* GraphQL */ `
  mutation UpdateStatus(
    $input: UpdateStatusInput!
    $condition: ModelStatusConditionInput
  ) {
    updateStatus(input: $input, condition: $condition) {
      id
      type
      expiryTime
      isDump
      url
      caption
      fontFamily
      alignCenter
      height
      width
      isMuted
      resizeMode
      color
      duration
      createdAt
      statusroomID
      StatusRoom {
        id
        month
        noStatuses
        createdAt
        updatedAt
        Statuses {
          items {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Likes {
        items {
          id
          userID
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Views {
        items {
          id
          userID
          statusID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      Post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      statusUserId
      statusPostId
      statusItemId
      __typename
    }
  }
`;
export const deleteStatus = /* GraphQL */ `
  mutation DeleteStatus(
    $input: DeleteStatusInput!
    $condition: ModelStatusConditionInput
  ) {
    deleteStatus(input: $input, condition: $condition) {
      id
      type
      expiryTime
      isDump
      url
      caption
      fontFamily
      alignCenter
      height
      width
      isMuted
      resizeMode
      color
      duration
      createdAt
      statusroomID
      StatusRoom {
        id
        month
        noStatuses
        createdAt
        updatedAt
        Statuses {
          items {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        User {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Likes {
        items {
          id
          userID
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Views {
        items {
          id
          userID
          statusID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      User {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      Post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Item {
        id
        name
        description
        brand
        category
        gender
        age
        condition
        price
        wasPrice
        colors {
          items {
            id
            color
            images
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        shopID
        shop {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      statusUserId
      statusPostId
      statusItemId
      __typename
    }
  }
`;
export const createReply = /* GraphQL */ `
  mutation CreateReply(
    $input: CreateReplyInput!
    $condition: ModelReplyConditionInput
  ) {
    createReply(input: $input, condition: $condition) {
      id
      message
      replyingToUserID
      replyingToUsername
      createdAt
      comment {
        id
        message
        createdAt
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      commentID
      userID
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateReply = /* GraphQL */ `
  mutation UpdateReply(
    $input: UpdateReplyInput!
    $condition: ModelReplyConditionInput
  ) {
    updateReply(input: $input, condition: $condition) {
      id
      message
      replyingToUserID
      replyingToUsername
      createdAt
      comment {
        id
        message
        createdAt
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      commentID
      userID
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteReply = /* GraphQL */ `
  mutation DeleteReply(
    $input: DeleteReplyInput!
    $condition: ModelReplyConditionInput
  ) {
    deleteReply(input: $input, condition: $condition) {
      id
      message
      replyingToUserID
      replyingToUsername
      createdAt
      comment {
        id
        message
        createdAt
        postID
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        post {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      commentID
      userID
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      message
      createdAt
      postID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      Replies {
        items {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      message
      createdAt
      postID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      Replies {
        items {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      message
      createdAt
      postID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      Replies {
        items {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLike = /* GraphQL */ `
  mutation CreateLike(
    $input: CreateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    createLike(input: $input, condition: $condition) {
      id
      userID
      postID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      statusID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLike = /* GraphQL */ `
  mutation UpdateLike(
    $input: UpdateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    updateLike(input: $input, condition: $condition) {
      id
      userID
      postID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      statusID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLike = /* GraphQL */ `
  mutation DeleteLike(
    $input: DeleteLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    deleteLike(input: $input, condition: $condition) {
      id
      userID
      postID
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      post {
        id
        category
        type
        title
        caption
        images
        muted
        isVideo
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        user {
          id
          name
          username
          biography
          profile
          verified
          email
          deviceToken
          preferredContent
          recentSearches
          Posts {
            nextToken
            startedAt
            __typename
          }
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          Replies {
            nextToken
            startedAt
            __typename
          }
          StatusRooms {
            nextToken
            startedAt
            __typename
          }
          Notifications {
            nextToken
            startedAt
            __typename
          }
          UserFollowers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          UserFollowings {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Followers {
            nextToken
            startedAt
            __typename
          }
          Followings {
            nextToken
            startedAt
            __typename
          }
          Settings {
            id
            pushNotifications
            likesNotifications
            commentsNotifications
            followsNotifications
            allowMessagesFromAll
            allowCommentsFromAll
            allowMultipleSignIns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Verification {
            id
            idNumber
            proofOfId
            verified
            reason
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          BlockedUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BlockedByUsers {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Blockeds {
            nextToken
            startedAt
            __typename
          }
          BlockedBies {
            nextToken
            startedAt
            __typename
          }
          Messages {
            nextToken
            startedAt
            __typename
          }
          ChatRooms {
            nextToken
            startedAt
            __typename
          }
          DeletedChatRooms {
            nextToken
            startedAt
            __typename
          }
          Views {
            nextToken
            startedAt
            __typename
          }
          Shops {
            nextToken
            startedAt
            __typename
          }
          cartItems {
            nextToken
            startedAt
            __typename
          }
          wishListItems {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserFollowersId
          userUserFollowingsId
          userSettingsId
          userVerificationId
          userBlockedUsersId
          userBlockedByUsersId
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      statusID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      category
      type
      title
      caption
      images
      muted
      isVideo
      Likes {
        items {
          id
          userID
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Comments {
        items {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      category
      type
      title
      caption
      images
      muted
      isVideo
      Likes {
        items {
          id
          userID
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Comments {
        items {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      category
      type
      title
      caption
      images
      muted
      isVideo
      Likes {
        items {
          id
          userID
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Comments {
        items {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      username
      biography
      profile
      verified
      email
      deviceToken
      preferredContent
      recentSearches
      Posts {
        items {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Likes {
        items {
          id
          userID
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Comments {
        items {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Replies {
        items {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      StatusRooms {
        items {
          id
          month
          noStatuses
          createdAt
          updatedAt
          Statuses {
            nextToken
            startedAt
            __typename
          }
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Notifications {
        items {
          id
          type
          message
          recipientID
          seen
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Like {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          notificationLikeId
          notificationCommentId
          notificationReplyId
          notificationPostId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserFollowers {
        id
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      UserFollowings {
        id
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Followers {
        items {
          id
          userID
          userfollowersID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Followings {
        items {
          id
          userID
          userfollowingsID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Settings {
        id
        pushNotifications
        likesNotifications
        commentsNotifications
        followsNotifications
        allowMessagesFromAll
        allowCommentsFromAll
        allowMultipleSignIns
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Verification {
        id
        idNumber
        proofOfId
        verified
        reason
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BlockedUsers {
        id
        Users {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      BlockedByUsers {
        id
        Users {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Blockeds {
        items {
          id
          blockedByID
          blockedusersID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BlockedBies {
        items {
          id
          blockedbyusersID
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Messages {
        items {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ChatRooms {
        items {
          id
          chatRoomId
          userId
          chatRoom {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatRoomLastMessageId
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DeletedChatRooms {
        items {
          id
          chatRoomID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Views {
        items {
          id
          userID
          statusID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Shops {
        items {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cartItems {
        items {
          id
          quantity
          size
          color
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      wishListItems {
        items {
          id
          color
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          payed
          status
          deliveryFee
          delivered
          delieveredDate
          handedToCourier
          handedToCourierDate
          expectedDeliveryDate
          recipientName
          recipientPhoneNumber
          recipientAddress
          createdAt
          orderItems {
            nextToken
            startedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          address {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderAddressId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      addresses {
        items {
          id
          type
          company
          street
          suburb
          city
          province
          country
          lat
          lng
          name
          mobile
          email
          userID
          shopID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserFollowersId
      userUserFollowingsId
      userSettingsId
      userVerificationId
      userBlockedUsersId
      userBlockedByUsersId
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      username
      biography
      profile
      verified
      email
      deviceToken
      preferredContent
      recentSearches
      Posts {
        items {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Likes {
        items {
          id
          userID
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Comments {
        items {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Replies {
        items {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      StatusRooms {
        items {
          id
          month
          noStatuses
          createdAt
          updatedAt
          Statuses {
            nextToken
            startedAt
            __typename
          }
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Notifications {
        items {
          id
          type
          message
          recipientID
          seen
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Like {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          notificationLikeId
          notificationCommentId
          notificationReplyId
          notificationPostId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserFollowers {
        id
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      UserFollowings {
        id
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Followers {
        items {
          id
          userID
          userfollowersID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Followings {
        items {
          id
          userID
          userfollowingsID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Settings {
        id
        pushNotifications
        likesNotifications
        commentsNotifications
        followsNotifications
        allowMessagesFromAll
        allowCommentsFromAll
        allowMultipleSignIns
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Verification {
        id
        idNumber
        proofOfId
        verified
        reason
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BlockedUsers {
        id
        Users {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      BlockedByUsers {
        id
        Users {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Blockeds {
        items {
          id
          blockedByID
          blockedusersID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BlockedBies {
        items {
          id
          blockedbyusersID
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Messages {
        items {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ChatRooms {
        items {
          id
          chatRoomId
          userId
          chatRoom {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatRoomLastMessageId
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DeletedChatRooms {
        items {
          id
          chatRoomID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Views {
        items {
          id
          userID
          statusID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Shops {
        items {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cartItems {
        items {
          id
          quantity
          size
          color
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      wishListItems {
        items {
          id
          color
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          payed
          status
          deliveryFee
          delivered
          delieveredDate
          handedToCourier
          handedToCourierDate
          expectedDeliveryDate
          recipientName
          recipientPhoneNumber
          recipientAddress
          createdAt
          orderItems {
            nextToken
            startedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          address {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderAddressId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      addresses {
        items {
          id
          type
          company
          street
          suburb
          city
          province
          country
          lat
          lng
          name
          mobile
          email
          userID
          shopID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserFollowersId
      userUserFollowingsId
      userSettingsId
      userVerificationId
      userBlockedUsersId
      userBlockedByUsersId
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      username
      biography
      profile
      verified
      email
      deviceToken
      preferredContent
      recentSearches
      Posts {
        items {
          id
          category
          type
          title
          caption
          images
          muted
          isVideo
          Likes {
            nextToken
            startedAt
            __typename
          }
          Comments {
            nextToken
            startedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Likes {
        items {
          id
          userID
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Comments {
        items {
          id
          message
          createdAt
          postID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          Replies {
            nextToken
            startedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Replies {
        items {
          id
          message
          replyingToUserID
          replyingToUsername
          createdAt
          comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          commentID
          userID
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      StatusRooms {
        items {
          id
          month
          noStatuses
          createdAt
          updatedAt
          Statuses {
            nextToken
            startedAt
            __typename
          }
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Notifications {
        items {
          id
          type
          message
          recipientID
          seen
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Like {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          notificationLikeId
          notificationCommentId
          notificationReplyId
          notificationPostId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserFollowers {
        id
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      UserFollowings {
        id
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Followers {
        items {
          id
          userID
          userfollowersID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Followings {
        items {
          id
          userID
          userfollowingsID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Settings {
        id
        pushNotifications
        likesNotifications
        commentsNotifications
        followsNotifications
        allowMessagesFromAll
        allowCommentsFromAll
        allowMultipleSignIns
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Verification {
        id
        idNumber
        proofOfId
        verified
        reason
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Reports {
        items {
          id
          message
          seen
          response
          senderID
          Status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          Reply {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Comment {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          Message {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          Item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          statusID
          userID
          postID
          replyID
          commentID
          messageID
          itemID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BlockedUsers {
        id
        Users {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      BlockedByUsers {
        id
        Users {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Blockeds {
        items {
          id
          blockedByID
          blockedusersID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BlockedBies {
        items {
          id
          blockedbyusersID
          userID
          User {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Messages {
        items {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ChatRooms {
        items {
          id
          chatRoomId
          userId
          chatRoom {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            chatRoomLastMessageId
            __typename
          }
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DeletedChatRooms {
        items {
          id
          chatRoomID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Views {
        items {
          id
          userID
          statusID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Shops {
        items {
          id
          name
          logo
          about
          emailAddress
          phoneNumber
          province
          address
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          items {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          addresses {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      cartItems {
        items {
          id
          quantity
          size
          color
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      wishListItems {
        items {
          id
          color
          itemID
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          payed
          status
          deliveryFee
          delivered
          delieveredDate
          handedToCourier
          handedToCourierDate
          expectedDeliveryDate
          recipientName
          recipientPhoneNumber
          recipientAddress
          createdAt
          orderItems {
            nextToken
            startedAt
            __typename
          }
          userID
          user {
            id
            name
            username
            biography
            profile
            verified
            email
            deviceToken
            preferredContent
            recentSearches
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserFollowersId
            userUserFollowingsId
            userSettingsId
            userVerificationId
            userBlockedUsersId
            userBlockedByUsersId
            __typename
          }
          shopID
          shop {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          address {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderAddressId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      addresses {
        items {
          id
          type
          company
          street
          suburb
          city
          province
          country
          lat
          lng
          name
          mobile
          email
          userID
          shopID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserFollowersId
      userUserFollowingsId
      userSettingsId
      userVerificationId
      userBlockedUsersId
      userBlockedByUsersId
      __typename
    }
  }
`;
export const createUserChatRoom = /* GraphQL */ `
  mutation CreateUserChatRoom(
    $input: CreateUserChatRoomInput!
    $condition: ModelUserChatRoomConditionInput
  ) {
    createUserChatRoom(input: $input, condition: $condition) {
      id
      chatRoomId
      userId
      chatRoom {
        id
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        LastMessage {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        users {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatRoomLastMessageId
        __typename
      }
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUserChatRoom = /* GraphQL */ `
  mutation UpdateUserChatRoom(
    $input: UpdateUserChatRoomInput!
    $condition: ModelUserChatRoomConditionInput
  ) {
    updateUserChatRoom(input: $input, condition: $condition) {
      id
      chatRoomId
      userId
      chatRoom {
        id
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        LastMessage {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        users {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatRoomLastMessageId
        __typename
      }
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUserChatRoom = /* GraphQL */ `
  mutation DeleteUserChatRoom(
    $input: DeleteUserChatRoomInput!
    $condition: ModelUserChatRoomConditionInput
  ) {
    deleteUserChatRoom(input: $input, condition: $condition) {
      id
      chatRoomId
      userId
      chatRoom {
        id
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        LastMessage {
          id
          type
          message
          image
          seen
          userID
          createdAt
          post {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          chatroomID
          status {
            id
            type
            expiryTime
            isDump
            url
            caption
            fontFamily
            alignCenter
            height
            width
            isMuted
            resizeMode
            color
            duration
            createdAt
            statusroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            statusUserId
            statusPostId
            statusItemId
            __typename
          }
          item {
            id
            name
            description
            brand
            category
            gender
            age
            condition
            price
            wasPrice
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Reports {
            nextToken
            startedAt
            __typename
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messagePostId
          messageStatusId
          messageItemId
          __typename
        }
        users {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatRoomLastMessageId
        __typename
      }
      user {
        id
        name
        username
        biography
        profile
        verified
        email
        deviceToken
        preferredContent
        recentSearches
        Posts {
          items {
            id
            category
            type
            title
            caption
            images
            muted
            isVideo
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Likes {
          items {
            id
            userID
            postID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Comments {
          items {
            id
            message
            createdAt
            postID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Replies {
          items {
            id
            message
            replyingToUserID
            replyingToUsername
            createdAt
            commentID
            userID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        StatusRooms {
          items {
            id
            month
            noStatuses
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Notifications {
          items {
            id
            type
            message
            recipientID
            seen
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            notificationLikeId
            notificationCommentId
            notificationReplyId
            notificationPostId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserFollowers {
          id
          Followers {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        UserFollowings {
          id
          Followings {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Followers {
          items {
            id
            userID
            userfollowersID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Followings {
          items {
            id
            userID
            userfollowingsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Settings {
          id
          pushNotifications
          likesNotifications
          commentsNotifications
          followsNotifications
          allowMessagesFromAll
          allowCommentsFromAll
          allowMultipleSignIns
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Verification {
          id
          idNumber
          proofOfId
          verified
          reason
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Reports {
          items {
            id
            message
            seen
            response
            senderID
            statusID
            userID
            postID
            replyID
            commentID
            messageID
            itemID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BlockedByUsers {
          id
          Users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Blockeds {
          items {
            id
            blockedByID
            blockedusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        BlockedBies {
          items {
            id
            blockedbyusersID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Messages {
          items {
            id
            type
            message
            image
            seen
            userID
            createdAt
            chatroomID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            messagePostId
            messageStatusId
            messageItemId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ChatRooms {
          items {
            id
            chatRoomId
            userId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        DeletedChatRooms {
          items {
            id
            chatRoomID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Views {
          items {
            id
            userID
            statusID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Shops {
          items {
            id
            name
            logo
            about
            emailAddress
            phoneNumber
            province
            address
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        cartItems {
          items {
            id
            quantity
            size
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        wishListItems {
          items {
            id
            color
            itemID
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            payed
            status
            deliveryFee
            delivered
            delieveredDate
            handedToCourier
            handedToCourierDate
            expectedDeliveryDate
            recipientName
            recipientPhoneNumber
            recipientAddress
            createdAt
            userID
            shopID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            orderAddressId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        addresses {
          items {
            id
            type
            company
            street
            suburb
            city
            province
            country
            lat
            lng
            name
            mobile
            email
            userID
            shopID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserFollowersId
        userUserFollowingsId
        userSettingsId
        userVerificationId
        userBlockedUsersId
        userBlockedByUsersId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
